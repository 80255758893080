// components/.js
import { useState, useRef, useEffect } from "react";

import { 
  Flex, View, Autocomplete, Button, Link, 
  Badge, TextField,
} from '@aws-amplify/ui-react';




           
import { 
  BiMinusCircle,
  BiTrash 
} from "react-icons/bi";

// import { select } from "d3";
import { BsFillLockFill, BsFillUnlockFill, BsBookmarkPlusFill } from "react-icons/bs";

// import ListClasses from ".././ListClasses";

import * as FetchDataUtils from '../lib/fetchDataUtils';
import * as LoggingUtils from '../lib/loggingUtils';

import { v4 as uuidv4 } from 'uuid';



function SelectedDocumentsTags({ 
    param_availableTags,
    param_selectionModeFlag,
    param_selectedClassesAndDocuments,
    param_fnOnChangedSelectedClassesAndDocuments,
    // param_fnOnUnselectingClassesAndDocuments,
 }) {


  const [selectionModeFlag, setSelectionModeFlag] = useState(false);
  const[ lockedEditFlag, setLockedEditFlag] = useState(true);

  const [availableTags, setAvailableTags] = useState({});
  const [availableTagsAutocompleteOptions, setAvailableTagsAutocompleteOptions] = useState([]);
  
  const [tagName, setTagName] = useState('');

  const [appliedTags, setAppliedTags] = useState({});


  const selectedClassesAndDocumentsRef = useRef({});
  const [selectedClassesAndDocuments, setSelectedClassesAndDocuments] = useState({});

  const [showNewTagForm, setShowNewTagForm] = useState(false);
  const [newTagName, setNewTagName] = useState('');


  // let init = useRef(true);
  // useEffect(() => {
  //   if (!init.current){
  //     return
  //   }
  //   init.current = false;
  //   // setAvailableTags({
  //   //   '#aaaa':{
  //   //     'SL FullScope API':[],
  //   //     'SL FullScope Portfolio Review':[],
  //   //   },
  //   //   '#bbb':{
  //   //     'SL FullScope':[],
  //   //     'SL Architecture Team':[],
  //   //   }

  //   // });
  // }, []);



  useEffect(() => {
    if (param_selectionModeFlag == null){
      return;
    }

    if (!param_selectionModeFlag){
      setLockedEditFlag(true);
      setTagName('');
      setAppliedTags({});
      setShowNewTagForm(false);
      setNewTagName('');

      // selectedClassesAndDocumentsRef.current = {...param_selectedClassesAndDocuments};
      // setSelectedClassesAndDocuments(selectedClassesAndDocumentsRef.current);
      param_fnOnChangedSelectedClassesAndDocuments({});
    }
    setSelectionModeFlag(param_selectionModeFlag);
  }, [param_selectionModeFlag]);



  useEffect(() => {
    // console.log("SelectedDocumentsTags: useEffect: param_selectedClassesAndDocuments:", param_selectedClassesAndDocuments);
    selectedClassesAndDocumentsRef.current = {...param_selectedClassesAndDocuments};
    setSelectedClassesAndDocuments(selectedClassesAndDocumentsRef.current);

  }, [param_selectedClassesAndDocuments]);



  useEffect(() => {
    if(!param_availableTags) return;
    // console.log("SelectedDocumentsTags: useEffect: param_availableTags:", param_availableTags);
    setAvailableTags(param_availableTags);
  }, [param_availableTags]);

  useEffect(() => {
    if(!availableTags) return;
    // console.log("SelectedDocumentsTags: useEffect: availableTags:", availableTags);
    setTagAutocompleteOptions();
  }, [availableTags]);



  useEffect(() => {
    // console.log("SelectedDocumentsTags: useEffect: appliedTags:", appliedTags);
    setTagAutocompleteOptions();
  }, [appliedTags]);





  // update Drop-Down in Autocomplete
  function setTagAutocompleteOptions(){  
    const availableTagsNames = Object.keys(availableTags);
    const appliedTagsNames = Object.keys(appliedTags);
    let difference = availableTagsNames.filter(x => !appliedTagsNames.includes(x));
    // console.log("difference:", difference);
    function formatAutocompleteOptions(items){
      let options = [];
      try{
        let sortedItems = [...items];
        sortedItems.sort();
        for(let i=0;i<sortedItems.length;i++){
          const item = sortedItems[i];
          options.push({id:item, label:item});
        };
      }catch(ignore){};
      return options;
    }
    const options = formatAutocompleteOptions(difference);
    setAvailableTagsAutocompleteOptions(options);
  }



  // function onTagNameChange(tagName){
  //   console.log("SelectedDocumentsTags: onTagNameChange: tagName:", tagName);
  //   setTagName(tagName);

  //   if (tagName in availableTags){
  //     param_fnOnLoadedSelections(availableTags[tagName]);
  //   }
    
  // }

  function addTaggedDocumentsToSelectedClassesAndDocuments(tagName){
    const _allSelectedClassesAndDocuments = selectedClassesAndDocumentsRef.current;
    const _selectedClassesAndDocuments = availableTags[tagName];
    
    Object.keys(_selectedClassesAndDocuments).map((className, idxClass) => {
      const selectedDocsIds = _selectedClassesAndDocuments[className];

      if (!(className in _allSelectedClassesAndDocuments) ||
          (_allSelectedClassesAndDocuments[className] == null)
      ){
        _allSelectedClassesAndDocuments[className] = [...selectedDocsIds];

      } else {
        let _allSelectedDocsIds = _allSelectedClassesAndDocuments[className];

        var merged = [...selectedDocsIds, ..._allSelectedDocsIds];
        const mergedSet = new Set(merged);
        _allSelectedClassesAndDocuments[className] = Array.from(mergedSet);
      }
    });

    // console.log("SelectedDocumentsTags: addTaggedDocumentsToSelectedClassesAndDocuments: _selectedClassesAndDocuments:", _selectedClassesAndDocuments);
    setSelectedClassesAndDocuments({..._allSelectedClassesAndDocuments});
    return _allSelectedClassesAndDocuments;
  }


  function removeTaggedDocumentsFromSelectedClassesAndDocuments(tagName){
    const _allSelectedClassesAndDocuments = selectedClassesAndDocumentsRef.current;
    const _unselectedClassesAndDocuments = availableTags[tagName];
    
    Object.keys(_unselectedClassesAndDocuments).map((className, idxClass) => {
      const unselectedDocsIds = _unselectedClassesAndDocuments[className];

      if (!unselectedDocsIds?.length){ // [] or null or undefined
        delete _allSelectedClassesAndDocuments[className];
      } else {

        const _selectedClassObj = _allSelectedClassesAndDocuments[className];
        if (!!_selectedClassObj?.length){ // [xxx,yyy,...]

          unselectedDocsIds.map((docId, idx) => {
            while(true){
              const index = _selectedClassObj.indexOf(docId);
              if (index === -1) break;
              _selectedClassObj.splice(index, 1);
            }
          });
          // if removing actually de-selected all documents in Class
          //    then -> remove entire Class
          if (_selectedClassObj.length === 0){
            delete _allSelectedClassesAndDocuments[className];
          }
        }
      }
    });

    // console.log("SelectedDocumentsTags: removeTaggedDocumentsFromSelectedClassesAndDocuments: _selectedClassesAndDocuments:", _selectedClassesAndDocuments);
    setSelectedClassesAndDocuments({..._allSelectedClassesAndDocuments});

    return _allSelectedClassesAndDocuments;
  }


  // async function saveDocumentSelectionsDB(updateData){
  //   // console.log("CaptureLecture: saveCurrentCaptureDB: 'fetch' POST capture update data:", updateData);

  //   let _updateData = {
  //     id: captureIdRef.current,
  //     timestamp: timestampRef.current,
  //     purchasesAndUsagesId: purchasesAndUsagesIdRef.current,
  //     captured_seconds: capturedSecondsRef.current,
  //     class: capture.class === null? "" : capture.class,

  //     __typename: "PrivateLectures",
  //     createdAt: new Date(timestampRef.current).toISOString(),
  //     updatedAt: new Date(Date.now()).toISOString(),
  //   };

  //   if(!!updateData){
  //     _updateData = {..._updateData, ...updateData};
  //   }

  //   if(!!_updateData.sentences_list){
  //     let content = "";
  //     for(let i=0; i<_updateData.sentences_list.length; i++){
  //       content += _updateData.sentences_list[i].content;
  //     }
  //     _updateData.tokens =  Math.ceil(content.length * 0.37);
  //   }

  //   console.log("CaptureLecture: saveCurrentCaptureDB: 'fetch' POST sent _updateData:", _updateData);
  //   const res = await FetchDataUtils.postData('documentSelections', _updateData);
  //   if(("class" in res) && (res.class === null)){ // if record class === NULL, write ""
  //     res.class = "";
  //   } 
  //   console.log("CaptureLecture: saveCurrentCaptureDB: 'fetch' POST capture update data RETURNED:", res);
    
  //   if(!res){ // new way of saving failed by returning 'null'
  //     console.log("ERROR CaptureLecture: saveCurrentCaptureDB: FAILED to update data:", _updateData);
  //   }
  //   return res;
  // }



  return (
    <>
    { selectionModeFlag &&
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-end"
          >
              <Flex
                direction="row"
                marginLeft="auto"
                justifyContent="flex-start"
                alignItems="baseline"
                fontSize="1.3rem"
              >
                Tags:
                
                <Autocomplete
                  marginLeft="auto"
                  maxWidth="15rem"
                  placeholder="Add Tagged"
                  options={availableTagsAutocompleteOptions}
                  value={tagName}
                  onChange={(event) => { // change by typing only
                    // onTagNameChange(event.target.value)
                    let newValue = event.target.value;
                    if(newValue.length === 1 && newValue !== "#"){
                      newValue = "#" + newValue;
                    }
                    setTagName(newValue);
                  }}
                  onClear={() => {setTagName("")}}
                  onSelect={(option) => {
                    let _tagName = option.label;
                    console.log()

                    const _appliedTags = {...appliedTags};
                    _appliedTags[_tagName] = availableTags[_tagName];
                    setAppliedTags(_appliedTags);
                    setTagName('');

                    const _selectedClassesAndDocuments = addTaggedDocumentsToSelectedClassesAndDocuments(_tagName);
                    param_fnOnChangedSelectedClassesAndDocuments(_selectedClassesAndDocuments);
                  
                  }}
                  // onBlur={(event) => onParamLockedEditFlag(event)} 
                />

              </Flex>


              <Flex
                direction="row"
                marginLeft="auto"
                // justifyContent="flex-start"
                alignItems="center"
              >
                
                {!!Object.keys(appliedTags)?.length && Object.keys(appliedTags).map((_tagName, idx) => 
                  <Badge key={idx}
                    variation="success"
                  >
                    <BiMinusCircle
                      color="red"
                      fontSize="1.1rem"
                        onClick={() => {

                          const _appliedTags = {...appliedTags};
                          delete _appliedTags[_tagName];
                          setAppliedTags(_appliedTags);

                          const _selectedClassesAndDocuments = removeTaggedDocumentsFromSelectedClassesAndDocuments(_tagName);
                          param_fnOnChangedSelectedClassesAndDocuments(_selectedClassesAndDocuments);
                        }}
                    />

                    {_tagName}

                    {!lockedEditFlag &&
                        <BiTrash
                          color="red"
                          fontSize="1.1rem"
                            onClick={() => {

                              if (window.confirm('Delete Tag ?')){
                                // console.log("SelectedDocumentsTags: Delete Tag onClick: _availableTags:", tagName, _availableTags);

                                const _appliedTags = {...appliedTags};
                                delete _appliedTags[_tagName];
                                setAppliedTags(_appliedTags);

                                const _availableTags = JSON.parse(JSON.stringify(selectedClassesAndDocumentsRef.current));
                                delete _availableTags[_tagName];
                                setAvailableTags(_availableTags);

                                const _selectedClassesAndDocuments = removeTaggedDocumentsFromSelectedClassesAndDocuments(_tagName);
                                param_fnOnChangedSelectedClassesAndDocuments(_selectedClassesAndDocuments);
                              }
                            }}
                        />
                    }
                  </Badge>
                )}

                {!!Object.keys(appliedTags)?.length && 
                    <View as="div"
                      float="left"
                      color="darkgoldenrod"
                      fontSize="1.3rem"
                      onClick={() => setLockedEditFlag(!lockedEditFlag)}
                    >
                      { lockedEditFlag &&
                        <BsFillLockFill />
                      }
                      { !lockedEditFlag &&
                        <BsFillUnlockFill />
                      }
                    </View>
                }

                <Button 
                  width="10rem"
                  height="50%"
                  variation="primary"
                  disabled={!Object.keys(selectedClassesAndDocuments)?.length}
                  onClick={() => {
                    setShowNewTagForm(!showNewTagForm);
                  }}
                >
                  <View width="1.5rem">
                    <BsBookmarkPlusFill />
                  </View>
                  Save to Tag
                </Button> 
              </Flex>

              
              { !!showNewTagForm &&
                <Flex
                  direction="row"
                  marginLeft="auto"
                  justifyContent="flex-end"
                  height="6rem"
                  width="20rem"
                >
                
                    <View
                      width="100%"
                    >
                      <TextField
                        value={newTagName}
                        onChange={(event) => { // change by typing only
                          // onTagNameChange(event.target.value)
                          let newValue = event.currentTarget.value;
                          if(newValue.length === 1 && newValue !== "#"){
                            newValue = "#" + newValue;
                          }
                          setNewTagName(newValue);
                        }}
                      />


                      <Button 
                        variation="primary"
                        isDisabled={ !newTagName?.length || !!(newTagName in availableTags)}
                        onClick={() => {
                          
                          const _availableTags = JSON.parse(JSON.stringify(availableTags));
                          _availableTags[newTagName] = JSON.parse(JSON.stringify(selectedClassesAndDocumentsRef.current));
                          console.log("SelectedDocumentsTags: Save Tag onClick: _availableTags:", tagName, _availableTags);
                          setAvailableTags(_availableTags);

                          const _appliedTags = {};
                          _appliedTags[newTagName] = _availableTags[newTagName];
                          setAppliedTags({..._appliedTags});

                          setNewTagName('');
                          setShowNewTagForm(false);

                          async function run(){
                            const timestamp = Date.now();
                            const data = {
                              id: uuidv4(),
                              timestamp: timestamp,
                              createdAt: new Date(timestamp).toISOString(),
                              tags: _availableTags,
                            };
                            const savedTagsRecord = await FetchDataUtils.postData('documentSelections', data);
                            if('error' in savedTagsRecord){
                              LoggingUtils.log(savedTagsRecord);
                            }
                            // console.log("SelectedDocumentsTags: Save Tag onClick: FetchDataUtils.postData: savedTagsRecord:", savedTagsRecord);
                          };
                          run();

                        }}
                      >
                        Save Tag
                      </Button> 
                      <Button 
                        variation="warning"
                        onClick={() => {
                          setNewTagName('');
                          setShowNewTagForm(false);
                        }}
                      >
                        Cancel
                      </Button> 
                    </View>
                
                </Flex>
              }
 

          </Flex>   

    }
    </>

 );
}
export default SelectedDocumentsTags;
