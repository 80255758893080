// components/EnterDocument.js
import { v4 as uuidv4 } from 'uuid';
import { Auth } from 'aws-amplify';

import { useState, useEffect, useRef, useReducer } from "react";
import { Flex, View, TextField, Button,
 } from '@aws-amplify/ui-react';

import './transcript.css';
import * as FetchDataUtils from '../lib/fetchDataUtils';
import * as LoggingUtils from '../lib/loggingUtils';

import AutocompleteAvailableClasses from "./AutocompleteAvailableClasses";

import DragAndDropUploader from './DragAndDropUploader';


function EnterDocument({
  param_encryptFlag,
  param_availableClassesList, 
  param_FnOnStartCapturing, 
  param_FnOnEndCapturing,
}) {

  // const [classesAutocompleteOptions, setClassesAutocompleteOptions] = useState([]);
  const [captureClass, setCaptureClass] = useState("");
  const [title, setTitle] = useState("");

  const textAreaRef = useRef();
  const [textAreaContent, setTextAreaContent] = useState("");

  const [stagedFilesForUpload, setStagedFilesForUpload] = useState([]);
  const [uploading, setUploading] = useState(false);

  const [message, setMessage] = useState("");


  let init = useRef(true);
  useEffect(() => {
    if (init.current){

      init.current = false;
    }
  }, []);




  useEffect(() => {
    // resize TextArea element to text, but max to screen height
    textAreaRef.current.style.height = 'auto';

    const maxTextAreaHeight = parseInt(window.innerHeight * 0.8);
    if(textAreaRef.current.scrollHeight > maxTextAreaHeight){
      textAreaRef.current.style.height = maxTextAreaHeight + 'px';
    }else{
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    }
    
  }, [textAreaContent]);


  function OnStagedFilesForUpload(stagedFiles){
    console.log("stagedFiles:", stagedFiles);
    setStagedFilesForUpload(stagedFiles)
  }



  const handleUpload = async () => {
    // [
    //     {originalname: "Presentation.wav", filename: "1718165068304-Presentation.wav"}
    // ]
    let uploadedFileNames = null;

    if(!stagedFilesForUpload.length){
      console.log("DragAndDropUploader: No Files to upload. Exiting.");
      return;
    }
    const formData = new FormData();
    stagedFilesForUpload.forEach(file => {
      formData.append('file', file);
    });

    try {
      setUploading(true);
      let baseURL = "/";
      if (process.env.NODE_ENV === "development"){
        baseURL = "http://localhost:8000/";
        // baseURL = "https://studyslaps.com/";
      }
      const currentSession = await Auth.currentSession();
      const accessToken = currentSession.getAccessToken();
      const jwt = accessToken.getJwtToken();
      const fetchOptions = {
        method: 'POST',
        body: formData,
        headers: {
          'accesstoken': jwt,
          'Accept': 'application/json',
        },
      };

      const response = await fetch(baseURL + 'captures/upload', fetchOptions);

      uploadedFileNames = await response.json();
      console.log("uploadedFileNames:",uploadedFileNames);

      if (response.ok) {
        setMessage('Files uploaded successfully!');
      } else {
        setMessage('Error uploading files!');
      }
    } catch (error) {
      setMessage('Error uploading files!');
    } finally {
      setUploading(false);
    }
    return uploadedFileNames;
  };


  async function onCancel(){
    param_FnOnEndCapturing(null);
  }

  async function onSaveDocument(){

    const uploadedFilesNames = await handleUpload();

    if(!uploadedFilesNames && !textAreaContent){
      param_FnOnEndCapturing(null);
      return;
    } 


    const capture = {
      id: uuidv4(),
      __typename: "PrivateLectures",
      timestamp: Date.now(),
      title: title,
      class: captureClass,
      sentences_list: [],
      notes: [],
      captured_seconds: 0,
      // summaries: [],
      // announcements: [],
      uploaded_files_names: uploadedFilesNames,
    };


    // split with 'newlines' into sentences
    const _sentencesArray = !textAreaContent ? [] : textAreaContent.split(/[\r\n]+/);
    let previousSentence = "";
    let content = "";
    for(let i=0; i<_sentencesArray.length; i++){

      let oneSentence = _sentencesArray[i];

      // oneSentence = oneSentence.replace(/\s+/g,' ').trim();  // remove duplicate whitespaces and starting/ending spaces
      oneSentence = oneSentence.replace(/\s+$/,''); // remove trailing whitespaces

      // // skip empty sentence
      // if(oneSentence === ""){
      //   continue;
      // }
      // skip sentences "user avatar"
      if(oneSentence === "user avatar"){
        continue;
      }
      // skip sentence ending with NN:NN
      if(/([0-9]{2}:[0-9]{2})$/.test(oneSentence)){
        continue;
      }
      // skip repeating sentences to save on space
      if(previousSentence === oneSentence){
        continue;
      }

      capture.sentences_list.push({
          content: oneSentence,
          start_time: i,
          end_time: i + 1,
      });

      previousSentence = oneSentence;
      content += oneSentence;
    }
    
    capture.tokens =  Math.ceil(content.length * 0.37);

    // console.log("EnterDocument: onSaveDocument: capture:", capture);

    // debug - comment out the following 3 lines
    // const savedCaptureRecord = await DataUtils.insertNewLectureDB(capture);
    let savedCaptureRecord = await FetchDataUtils.postData('captures', capture);
    if('error' in savedCaptureRecord){
      LoggingUtils.log(savedCaptureRecord);
      savedCaptureRecord = null;
    }

    // console.log("EnterDocument: onSaveDocument: savedCaptureRecord:", savedCaptureRecord);
    param_FnOnEndCapturing(savedCaptureRecord); // ListLecture will add this capture to the 'items[]'
  }

  function composeAutocompleteOptionsFromAvailableClassesList(items){
    let options = [];
    try{
      let sortedItems = [...items];
      sortedItems.sort();
      for(let i=0;i<sortedItems.length;i++){
        const item = sortedItems[i];
        options.push({id:item, label:item});
      };
    }catch(ignore){};

    return options;
  }


  return (
      <Flex direction="column">
        <Flex direction="row"
          justifyContent="flex-start"
        >
          <Button 
              minWidth="10rem"
              variation="primary"
              onClick={() => { onSaveDocument()} }
          >
            Insert Document
          </Button>
          <Button 
              minWidth="10rem"
              variation="warning"
              onClick={() => { onCancel()} }
          >
            Cancel
          </Button>
        </Flex>


        <Flex
          direction="row"
          justifyContent="flex-start"
          width="100%"
          margin="0rem 1rem 0.5rem 1rem"
          alignItems="center"
          alignContent="space-between"
        >
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              marginLeft="5%"
              width="50%"
            >
              <View
                  fontWeight="bolder"
              >
                Title:
              </View>
              <TextField
                  width="100%"
                  mylabel="lecture-title"
                  // fontWeight="bolder"
                  size="1.4rem"
                  defaultValue={title}
                  onChange={(event) => {setTitle(event.target.value)}}
                  // onBlur={(event) => onInputBlur(event)}
              />
            </Flex>

            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
            >
                <View
                  fontWeight="bolder"
                >
                  Class:
                </View>
                <AutocompleteAvailableClasses
                  param_value={captureClass}
                  param_FnOnChange={(_className) => {setCaptureClass(_className)}}
                  param_FnOnClear={() => {setCaptureClass("")}}
                  param_FnOnSelect={(_className) => {setCaptureClass(_className)}}
                  // param_FnOnBlur={(event) => onParamLockedEditFlag(event)} 
              />
            </Flex>
        </Flex>


        <textarea 
            ref={textAreaRef}
            autoFocus={true}
            className="cls-textarea"
            value={textAreaContent} 
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => setTextAreaContent(event.target.value)}
        />

        <div>
            {stagedFilesForUpload[0]?.originalname}
        </div>

        {uploading ? 'Uploading...' : ''}
        <DragAndDropUploader 
            param_FnOnStagedFilesForUpload={(stagedFiles) => {OnStagedFilesForUpload(stagedFiles)}}
        />
      </Flex>
  );
}

export default EnterDocument;
