/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPrivateLectures = /* GraphQL */ `
  mutation CreatePrivateLectures(
    $input: CreatePrivateLecturesInput!
    $condition: ModelPrivateLecturesConditionInput
  ) {
    createPrivateLectures(input: $input, condition: $condition) {
      id
      class
      timestamp
      recording
      transcript
      comments
      announcements
      outline
      title
      captured_seconds
      captured_words
      sentences_list
      notes
      clean_sentences_list
      summary
      flash_cards
      createdAt
      updatedAt
      owner
      purchasesAndUsagesId
    }
  }
`;
export const updatePrivateLectures = /* GraphQL */ `
  mutation UpdatePrivateLectures(
    $input: UpdatePrivateLecturesInput!
    $condition: ModelPrivateLecturesConditionInput
  ) {
    updatePrivateLectures(input: $input, condition: $condition) {
      id
      class
      timestamp
      recording
      transcript
      comments
      announcements
      outline
      title
      captured_seconds
      captured_words
      sentences_list
      notes
      clean_sentences_list
      summary
      flash_cards
      createdAt
      updatedAt
      owner
      purchasesAndUsagesId
    }
  }
`;
export const deletePrivateLectures = /* GraphQL */ `
  mutation DeletePrivateLectures(
    $input: DeletePrivateLecturesInput!
    $condition: ModelPrivateLecturesConditionInput
  ) {
    deletePrivateLectures(input: $input, condition: $condition) {
      id
      class
      timestamp
      recording
      transcript
      comments
      announcements
      outline
      title
      captured_seconds
      captured_words
      sentences_list
      notes
      clean_sentences_list
      summary
      flash_cards
      createdAt
      updatedAt
      owner
      purchasesAndUsagesId
    }
  }
`;
export const createInvoices = /* GraphQL */ `
  mutation CreateInvoices(
    $input: CreateInvoicesInput!
    $condition: ModelInvoicesConditionInput
  ) {
    createInvoices(input: $input, condition: $condition) {
      id
      timestamp
      customer_id
      status
      invoice_data
      checkout_session
      payment_intent
      charge
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateInvoices = /* GraphQL */ `
  mutation UpdateInvoices(
    $input: UpdateInvoicesInput!
    $condition: ModelInvoicesConditionInput
  ) {
    updateInvoices(input: $input, condition: $condition) {
      id
      timestamp
      customer_id
      status
      invoice_data
      checkout_session
      payment_intent
      charge
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteInvoices = /* GraphQL */ `
  mutation DeleteInvoices(
    $input: DeleteInvoicesInput!
    $condition: ModelInvoicesConditionInput
  ) {
    deleteInvoices(input: $input, condition: $condition) {
      id
      timestamp
      customer_id
      status
      invoice_data
      checkout_session
      payment_intent
      charge
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPurchasesAndUsages = /* GraphQL */ `
  mutation CreatePurchasesAndUsages(
    $input: CreatePurchasesAndUsagesInput!
    $condition: ModelPurchasesAndUsagesConditionInput
  ) {
    createPurchasesAndUsages(input: $input, condition: $condition) {
      id
      createdAt
      timestamp
      newSubscriptionExpiration
      newMinutesLeft
      oldSubscriptionExpiration
      oldMinutesLeft
      invoiceId
      captureId
      updatedAt
      owner
    }
  }
`;
export const updatePurchasesAndUsages = /* GraphQL */ `
  mutation UpdatePurchasesAndUsages(
    $input: UpdatePurchasesAndUsagesInput!
    $condition: ModelPurchasesAndUsagesConditionInput
  ) {
    updatePurchasesAndUsages(input: $input, condition: $condition) {
      id
      createdAt
      timestamp
      newSubscriptionExpiration
      newMinutesLeft
      oldSubscriptionExpiration
      oldMinutesLeft
      invoiceId
      captureId
      updatedAt
      owner
    }
  }
`;
export const deletePurchasesAndUsages = /* GraphQL */ `
  mutation DeletePurchasesAndUsages(
    $input: DeletePurchasesAndUsagesInput!
    $condition: ModelPurchasesAndUsagesConditionInput
  ) {
    deletePurchasesAndUsages(input: $input, condition: $condition) {
      id
      createdAt
      timestamp
      newSubscriptionExpiration
      newMinutesLeft
      oldSubscriptionExpiration
      oldMinutesLeft
      invoiceId
      captureId
      updatedAt
      owner
    }
  }
`;
export const createUserAccounts = /* GraphQL */ `
  mutation CreateUserAccounts(
    $input: CreateUserAccountsInput!
    $condition: ModelUserAccountsConditionInput
  ) {
    createUserAccounts(input: $input, condition: $condition) {
      id
      timestamp
      owner
      email
      phone
      name
      subscriptionExpiration
      minutesLeft
      lastLogin
      lastPurchase
      lastCapture
      createdAt
      updatedAt
    }
  }
`;
export const updateUserAccounts = /* GraphQL */ `
  mutation UpdateUserAccounts(
    $input: UpdateUserAccountsInput!
    $condition: ModelUserAccountsConditionInput
  ) {
    updateUserAccounts(input: $input, condition: $condition) {
      id
      timestamp
      owner
      email
      phone
      name
      subscriptionExpiration
      minutesLeft
      lastLogin
      lastPurchase
      lastCapture
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserAccounts = /* GraphQL */ `
  mutation DeleteUserAccounts(
    $input: DeleteUserAccountsInput!
    $condition: ModelUserAccountsConditionInput
  ) {
    deleteUserAccounts(input: $input, condition: $condition) {
      id
      timestamp
      owner
      email
      phone
      name
      subscriptionExpiration
      minutesLeft
      lastLogin
      lastPurchase
      lastCapture
      createdAt
      updatedAt
    }
  }
`;
export const createEventLogs = /* GraphQL */ `
  mutation CreateEventLogs(
    $input: CreateEventLogsInput!
    $condition: ModelEventLogsConditionInput
  ) {
    createEventLogs(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      level
      source
      message
    }
  }
`;
export const updateEventLogs = /* GraphQL */ `
  mutation UpdateEventLogs(
    $input: UpdateEventLogsInput!
    $condition: ModelEventLogsConditionInput
  ) {
    updateEventLogs(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      level
      source
      message
    }
  }
`;
export const deleteEventLogs = /* GraphQL */ `
  mutation DeleteEventLogs(
    $input: DeleteEventLogsInput!
    $condition: ModelEventLogsConditionInput
  ) {
    deleteEventLogs(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      level
      source
      message
    }
  }
`;
