// admin/Admin.js

import { useRef, useEffect, useState } from "react";

import { View, Button, Flex, TextField
} from '@aws-amplify/ui-react';
import DataTable from 'react-data-table-component';

import * as FetchDataUtils from '../lib/fetchDataUtils';
import * as LoggingUtils from '../lib/loggingUtils';

import * as Utils from '../lib/utils';



function Admin() {

  const [userAccountsData, setUserAccountsData] = useState([]);
  const [errorEventLogsData, setErrorEventLogsData] = useState([]);
  const [loginEventLogsData, setLoginEventLogsData] = useState([]);

  let init = useRef(true);
  useEffect(() => {
    if (init.current){
      // TODO

      async function run(){
        let _data = await FetchDataUtils.getData( 'admin/userAccounts');
        if('error' in _data){
          LoggingUtils.log(_data);
          _data = null;
        }

        setUserAccountsData(_data);
        let usersById = {};
        _data.map(u => { usersById[u.sub] = u});
        // console.log(usersById);


        let _errorEventLogsData = await FetchDataUtils.getData( 'admin/eventLogs?level=error');
        if('error' in _errorEventLogsData){
          LoggingUtils.log(_errorEventLogsData);
          _errorEventLogsData = null;
        }

        const _errorLogs = _errorEventLogsData.Items;
        _errorLogs.map(e => {
          e.userId = e.owner.split("::")[0];
          e.user = usersById[e.userId];
        });
        setErrorEventLogsData(_errorLogs);

        let _loginEventLogsData = await FetchDataUtils.getData( 'admin/eventLogs?level=login');
        if('error' in _loginEventLogsData){
          LoggingUtils.log(_loginEventLogsData);
          _loginEventLogsData = null;
        }

        const _loginLogs = _loginEventLogsData.Items;
        _loginLogs.map(e => {
          e.userId = e.owner.split("::")[0];
          e.user = usersById[e.userId];
        });
        setLoginEventLogsData(_loginLogs);
      }
      run();


    }
  }, []);


  



  

  const userAccountsColumns = [
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'Subscription Expires',
        selector: row => Utils.isoTimeToLocalYearMonthDayTime(row.subscriptionExpiration),
        sortable: true,
    },
    {
        name: 'Minutes Left',
        selector: row => row.minutesLeft,
        sortable: true,
    },
    {
        name: 'Last Payment',
        selector: row => Utils.isoTimeToLocalYearMonthDayTime(row.last_invoice_date),
        sortable: true,
    },
    {
        name: 'Captures',
        selector: row => row.captures_count,
        sortable: true,
    },
    {
        name: 'Last Capture',
        selector: row => Utils.isoTimeToLocalYearMonthDayTime(row.last_capture_date),
        sortable: true,
    }
];

const eventLogsColumns = [
  {
      name: 'Created',
      selector: row => Utils.isoTimeToLocalYearMonthDayTime(row.createdAt),
      sortable: true,
  },
  {
      name: 'User',
      selector: row => row?.user?.email,
      sortable: true,
  },
  {
      name: 'Source',
      selector: row => row.source,
      sortable: false,
  },
  {
      name: 'Message',
      selector: row => (!row.message?.substring) ? "-" : row.message.substring(0, 100),
      sortable: false,
  }
];

const ExpandedUserComponent = ({ data }) => {
  let contentStr = data;
  try{
    contentStr = JSON.stringify(data, null, 2);
  }catch(ignore){
    console.log(ignore);
  }
  return (
    <pre>
      {contentStr}
    </pre>
  );
};

const ExpandedEventLogComponent = ({ data }) => {
  let contentStr = data.message;
  try{
    const contentObj = JSON.parse(data.message)
    delete contentObj['$metadata'];
    delete contentObj['$fault'];
    delete contentObj['__type'];
    contentStr = JSON.stringify(contentObj, null, 2);
    // contentStr = contentStr.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }catch(ignore){
    console.log(ignore);
  }
  return (
    <pre>
      {contentStr}
    </pre>
  );
};


async function sendSystemCacheResetCommand(){
  const retC = await FetchDataUtils.getData("admin/clear-cache", window.isEncrypted);
  // console.log("Admin: sendSystemCacheResetCommand: getData() return:", retC);
}




  return (
    <div>
      <View>
        Admin
      </View>  

      <Flex direction="column"
        justifyContent="flex-start"
      >
          <Button 
              minWidth="10rem"
              maxWidth="20rem"
              variation="primary"
              onClick={() => { sendSystemCacheResetCommand()} }
          >
            System Reset Cache for this User
          </Button>
      </Flex>

      <View>
        <DataTable
          dense
          responsive
          striped
          pagination
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={ExpandedUserComponent}
              columns={userAccountsColumns}
              data={userAccountsData}
          />
      </View> 

      <View>
        Error Event Logs
      </View>  
      <View>
        <DataTable
          dense
          responsive
          striped
          pagination
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={ExpandedEventLogComponent}
              columns={eventLogsColumns}
              data={errorEventLogsData}
          />
      </View>

      <View>
        Login Event Logs
      </View>  
      <View>
        <DataTable
          dense
          responsive
          striped
          pagination
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={ExpandedEventLogComponent}
              columns={eventLogsColumns}
              data={loginEventLogsData}
          />
      </View>
    </div>
  );
}

export default Admin;
