import React, { useState } from 'react';

function FileUpload({ onFileSelect }) {
    
  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file && file.type === "audio/wav") {
      onFileSelect(file);
    } else {
      alert("Please select a WAV audio file.");
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} accept=".wav" />
    </div>
  );
}

export default FileUpload;