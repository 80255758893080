// components/OneCaptureExpanderContainer.js
import { useState, useRef, useEffect } from "react";

import { Flex, View,
  Divider,
  useTheme, 
} from '@aws-amplify/ui-react';
// import { BsArrowDown } from "react-icons/bs";


// import { IconContext } from "react-icons";
import { 
  // BiCaretRight, 
  // BiCaretDown,
  // BiTrash 
  BiCopyAlt,
} from "react-icons/bi";
// import { BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";



// import * as DataUtils from '../lib/graphqlDataUtils';
// import AiSummarizer from './AiSummarizer';

// import * as Utils from '../../lib/utils';
// import './search.css';
import '../components/transcript.css';



function ExecutionItem({ 
  param_oneItem,
 }) {


  // const [expandedFlag, setExpandedFlag] = useState(false);

  const [oneItem, setOneItem] = useState(null);

  const contentViewRef = useRef();
  // const [contentTooLong, setContentTooLong] = useState(false);

  const [maxContentLength, setMaxContentLength] = useState(0);

  const [colorOfCopyIcon, setColorOfCopyIcon] = useState('black');
  

  // let init = useRef(true);
  // useEffect(() => {
  //   if (init.current){
  //     init.current = false;
  //   }
  // }, []);





  // useEffect(() => {
  //   console.log("ExecutionItem: useEffect: none", param_oneItem);

  // });

  useEffect(() => {
    // console.log("ExecutionItem: useEffect: param_oneItem", param_oneItem);
    if(!param_oneItem) return;

    let _oneItem = {...param_oneItem};

    //
    // Timestamp
    //
    let _formattedTimestamp = "-";
    try{
      const d = new Date( _oneItem.timestamp );
      const timeFormatOptions = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: true,
        timeZone: 'America/Chicago'
      };
      _formattedTimestamp = new Intl.DateTimeFormat('en-US', timeFormatOptions).format(d);
    }catch(ignore){};
    _oneItem.formattedTimestamp = _formattedTimestamp;

    // console.log("ExecutionItem: useEffect: _oneItem:", _oneItem);
    setOneItem(_oneItem);

    // init. display Sections up to 250 chars
    const _maxContentLength =250;
    setMaxContentLength(_maxContentLength);

  }, [param_oneItem]);


  
  // useEffect(() => {
  //   if(!oneItem) return;

  //   console.log("ExecutionItem: useEffect: oneItem: ", oneItem);

  //   // // console.log("ExecutionItem: useEffect: contentViewRef.current?.scrollHeight", contentViewRef.current?.scrollHeight);
  //   // if(contentViewRef.current.scrollHeight > 200){
  //   //   setContentTooLong(true);
  //   // }

  // }, [oneItem]);



  function toggleMaxContentLength(){
    // console.log("ExecutionItem: toggleMaxContentLength:");
    let newMaxContentLength = 250;
    if(maxContentLength === 250){
      newMaxContentLength = -1;
    }
    setMaxContentLength(newMaxContentLength);
  }


  function trimIfTooLong(content){
    // console.log(maxContentLength);
    if(!!content && maxContentLength > -1 && content.length > maxContentLength){
      content = content.substring(0,maxContentLength) + " ...";
    }
    return content;
  }

  const handleOutputTextCopyToClipboard = async () => {
    setColorOfCopyIcon('lightgreen');
    await navigator.clipboard.writeText(oneItem?.output);
    setTimeout(() => {
      setColorOfCopyIcon("black");
    }, 1000);
  }


  return (
    <View
      // marginBottom="1rem"
    >
      <hr
        className="cls-border-color-black"
      />
      <View
        // ref={contentViewRef}
        // className="cls-expandable-class-view"
        fontSize="0.9rem"
        margin="0px 5px"
        // onClick={() => toggleMaxContentLength()}
      >

              <Flex 
                direction="row"
                color="dodgerblue"
                fontSize="0.8rem"
                fontWeight="bolder"
                maxWidth="fit-content"
                // className={`cls-${c?.section_type}`}
              > 
                <View
                  as="div"
                >
                  {oneItem?.formattedTimestamp}
                </View>
                <View
                  as="div"
                  marginLeft="1rem"
                >
                  Model: {oneItem?.model}
                </View>
                {/* <View
                  as="div"
                >
                  {oneItem?.includeDocumentsFromClasses[0]}
                </View> */}
                  
                <View
                  as="div"
                >
                  Prompt tokens: {oneItem?.usage?.prompt_tokens.toLocaleString()}
                </View>   
                <View
                  as="div"
                >
                  Completion tokens: {oneItem?.usage?.completion_tokens.toLocaleString()}
                </View>     
              </Flex>


              <View 
                ref={contentViewRef}
                className="cls-expandable-class-view"
                style={{whiteSpace: 'pre-wrap'}}
                fontWeight='bold'
                marginTop="0.5rem"
                onClick={() => toggleMaxContentLength()}
              >
                {trimIfTooLong(oneItem?.input)}
              </View>
              { contentViewRef.current?.scrollHeight > 200 &&
                  <View className="cls-ellipsis-bottom-centered">
                    . . .
                  </View>
              }

              <Flex 
                direction="row"
                marginTop="1rem"
                maxWidth="fit-content"
              > 
                <View>
                  <BiCopyAlt 
                    fontSize="2rem"
                    width="12rem"
                    onClick={handleOutputTextCopyToClipboard}
                    color={colorOfCopyIcon}
                  />
                </View>
                <View 
                  style={{whiteSpace: 'pre-wrap'}}
                  onClick={() => toggleMaxContentLength()}
                >
                  {oneItem?.output}
                </View>
              </Flex>

              <Flex 
                direction="row"
                marginTop="1rem"
                maxWidth="fit-content"
              > 
                <View>
                  <BiCopyAlt 
                    fontSize="2rem"
                    width="12rem"
                    onClick={handleOutputTextCopyToClipboard}
                    color={colorOfCopyIcon}
                  />
                </View>
                <pre 
        
                >
                  {oneItem?.output}
                </pre>
              </Flex>
  
      </View>




    </View>
 );
}
export default ExecutionItem;
