
import React, { useState, useRef, useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';

import {
    View, Badge,
    ToggleButtonGroup, ToggleButton, Flex,
} from '@aws-amplify/ui-react';


import { GiPauseButton, GiPlainSquare } from "react-icons/gi";
import { BsRecordFill } from "react-icons/bs";
import { IconContext } from "react-icons";
import {
    BiTrash,
    BiCaretRight, BiCaretDown
} from "react-icons/bi";

import RTEngineWhisper from "./RTEngineWhisper";

import FileUpload from "./FileUpload";
import AudioPlayer from './AudioPlayer';
import ListSpeakers from './ListSpeakers/ListSpeakers';

import * as FetchDataUtils from '../lib/fetchDataUtils';


function RecorderTranscriptor({
    param_captureTimestamp,
    param_captureClass,
    param_spelling,
    param_FnOnTranscriptData, // calls parent OnTranscriptData(sentences, capSec)
    param_FnOnParticipantsListChange, // calls parent OnParticipantsListChange([])
    param_FnOnSpeakersActivityChange, // calls parent OnSpeakersActivityChange([])
    // param_FnOnAudioData,
    param_FnOnStartCapturing,
    param_FnOnEndCapturing // calls parent OnEndCapturing(capturedSeconds)
}) {

    // State management
    const [isCollapsed, setIsCollapsed] = useState(true); // New State
    const toggleCollapse = () => { setIsCollapsed(!isCollapsed); };


    // INIT once
    let initRef = useRef(true);
    useEffect(() => {
        if (initRef.current === false) return;
        initRef.current = false;

        setInitializingFlag(true);

        // TODO: pull values from the store (personal and/or subject)
        spellingTextArea.current.value = "";



        // RTTranscriptionWorker
        console.log("creating RTTranscriptionWorker worker");
        const scriptUrl_RTWorker = new URL('./RTTranscriptionWorker.js', import.meta.url);
        console.log(scriptUrl_RTWorker.href);
        //
        const _RTWorker = new Worker( scriptUrl_RTWorker );
        console.log("created RTTranscriptionWorker worker", _RTWorker);
        setRTWorker(_RTWorker);



        // RTAnalyticsWorker
        console.log("creating RTAnalyticsWorker worker");
        const scriptUrl_RTAnalyticsWorker = new URL('./RTAnalyticsWorker.js', import.meta.url);
        console.log(scriptUrl_RTAnalyticsWorker.href);
        //
        const _RTAnalyticsWorker = new Worker( scriptUrl_RTAnalyticsWorker );
        console.log("created _RTAnalyticsWorker worker", _RTAnalyticsWorker);
        setRTAnalyticsWorker(_RTAnalyticsWorker);



        //   // Check every 100 milliseconds for 'window?.varAvailableSpeakersArray'
        //   const intervalId = setInterval(() => {
        //     if(window?.varAvailableSpeakersArray != null && RTAnalyticsWorker != null) {
        //         clearInterval(intervalId); // Stop the polling FIRST
        //         console.log("Global variable 'varAvailableSpeakersArray' is set, running function...");

        //         RTAnalyticsWorker.postMessage({action:'loadAvailableSpeakersArray', 
        //                                       availableSpeakersArray: window.varAvailableSpeakersArray});


        //     } else{
        //       console.log("Waiting for Initialization of: window?.varAvailableSpeakersArray and RTAnalyticsWorker");
        //     }
        // }, 100); 

        setInitializingFlag(true);

        // TODO: pull values from the store (personal and/or subject)
        spellingTextArea.current.value = "";

        // draw Spectrum Canvas
        if (showExperimentalFeaturesFlag) {
            const canvas = audioAnalyzerCanvasRef.current;
            audioAnalyzerCanvasCtxRef.current = canvas.getContext('2d');
            drawAudioAnalyzerResults(); // will draw black area
        }
    }, []);



    const [initializingFlag, setInitializingFlag] = useState(true);

    const [showExperimentalFeaturesFlag, setShowExperimentalFeaturesFlag] = useState(false);


    //
    //   const transcriptorEngine = new TranscriptorEngineAWS();
    //
    const transcriptorEngine = new RTEngineWhisper();

    const sessionIdRef = useRef(null);

    // Ref for MediaRecorder
    // const audioBlobsArrayRef = useRef([]);
    const [audioFile, setAudioFile] = useState(null);
    const audioElementLoadedFromFileRef = useState(null);

    // Audio Pipeline
    const audioContextRef = useRef(null);




    const capturingStartTimestampRef = useRef(0);
    const capturedTotalSecondsRef = useRef(0);
    const capturedStartOfSentenceTimestampRef = useRef(0);

    const [capturedSeconds, setCapturedSeconds] = useState(0);

    const sentencesListRef = useRef([]);


    let recordingFlag = useRef(false);
    const [recordingStatusFlag, setRecordingStatusFlag] = useState("Stop");
    const [recordingStatusDescription, setRecordingStatusDescription] = useState("Stopped");




    const audioPeakRef = useRef(0);
    const [audioPeak, setAudioPeak] = useState(0);
    const [audioPeakColor, setAudioPeakColor] = useState("red");



    // const [transcription, setTranscription] = useState("");
    //   const [sampleRate, setSampleRate] = useState(48000);


    const lastSentenceRef = useRef("");

    const spellingTextArea = useRef();
    const spellingContentRef = useRef("");


    const [speakersList, setSpeakersList] = useState([]);
    const activeSpeakerDataRef = useRef(null);
    const [speakersActivity, setSpeakersActivity] = useState([]);



    const [debugMessage, setDebugMessage] = useState("");


    const audioAnalyzerNode_FastRef = useRef(null); // audio node
    const audioAnalyzerNode_SlowRef = useRef(null); // audio node

    const audioAnalyzerCanvasRef = useRef(null); // html 'canvas'
    const audioAnalyzerCanvasCtxRef = useRef(null); // drawing context

    const audioAnalyzerData_FastRef = useRef([]); // audio data
    const audioAnalyzerData_SlowRef = useRef([]); // audio data

    const audioAnalyzerTimerRef = useRef(null);
    const smoothingTimeConstantRef = useRef(0.3);
    const cutoffFrequencyRef = useRef(200);

    const frequencySamplingStartTimestampRef = useRef(0);
    const frequencySamplingDurationRef = useRef(0);

    // const voicesAudioDataRef = useRef([]);
    // const [voiceSamples, setVoiceSamples] = useState([]);
    const voiceSpectrumSamplesRef = useRef([]);

    // for getting data from 'compareAudios'
    // Receiving result example:
    //
    // Sending example:
    //   RTAnalyticsWorker.postMessage({
    //     action:'compareAudios',
    //     unknownVoiceAudioData: [...audioAnalyzerData_FastRef.current.slice(0, 200)],
    //     voicesAudioData: [...voicesAudioDataRef.current],
    //   });
    const [voicesSimilarity, setVoicesSimilarity] = useState([]);




    //
    // DRAW SPECTRUM
    //
    const WIDTH = 400; // Set your canvas width here
    const HEIGHT = 100; // Set your canvas height here

    // draw FFT result
    const myDrawVisualCallbackIdRef = useRef(null);
    const drawAudioAnalyzerResults = () => {
        myDrawVisualCallbackIdRef.current = requestAnimationFrame(drawAudioAnalyzerResultsCallback);
        drawAudioAnalyzerResultsCallback();
        cancelAnimationFrame(myDrawVisualCallbackIdRef.current);
    }

    const drawAudioAnalyzerResultsCallback = () => {
        const canvasCtx = audioAnalyzerCanvasCtxRef.current;
        canvasCtx.fillStyle = "rgb(0, 0, 0)";
        canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

        if (audioAnalyzerNode_SlowRef.current != null &&
            audioAnalyzerData_SlowRef.current.length > 0) {
            const bufferLength = audioAnalyzerNode_FastRef.current.frequencyBinCount;//WIDTH;// 512;//audioAnalyzerNode_FastRef.current.frequencyBinCount;

            const barWidth = (WIDTH / bufferLength) * 1;//2.5;
            let barHeightSlow;
            let barHeightFast;

            let x = 0;
            for (let i = 0; i < bufferLength; i += 2) {
                barHeightSlow = audioAnalyzerData_SlowRef.current[i];
                barHeightFast = audioAnalyzerData_FastRef.current[i];

                // draw grid lines every 50 frequencies
                if (i % 50 === 0) {
                    canvasCtx.fillStyle = `rgb(255, 255, 255)`;
                    canvasCtx.fillRect(x, 0, 1, HEIGHT);
                }

                // if( barHeightFast > barHeightSlow){

                // draw Fast
                canvasCtx.fillStyle = `rgb(255, 50, 50)`;
                canvasCtx.fillRect(x, HEIGHT - barHeightFast / 3, barWidth, barHeightFast / 3);
                // draw Slow
                // canvasCtx.fillStyle = `rgb(50, 255, 255)`;
                // canvasCtx.fillRect(x + barWidth, HEIGHT - barHeightSlow / 3, barWidth, barHeightSlow / 3);

                // } else {
                //   canvasCtx.fillStyle = `rgb(50, 255, 255)`;
                //   canvasCtx.fillRect(i, HEIGHT - barHeightSlow / 3, barWidth, barHeightSlow / 3);
                //   canvasCtx.fillStyle = `rgb(255, 50, 50)`;
                //   canvasCtx.fillRect(i+1, HEIGHT - barHeightFast / 3, barWidth, barHeightFast / 3); 
                // }  

                x += barWidth + 1;
            }
        }
    };



    //--------------- ON INTERVAL - 50mS
    // Started in openMicrophone():514 function
    const getAndDrawAudioAnalyzerResults = () => {
        // get freq data
        const bufferLength = audioAnalyzerNode_FastRef.current.frequencyBinCount;

        // FAST smoothing
        const freqData_Fast = new Uint8Array(bufferLength);
        audioAnalyzerNode_FastRef.current.getByteFrequencyData(freqData_Fast);
        // dump smoothed FFT content by again setting 'smoothingTimeConstant'
        audioAnalyzerNode_FastRef.current.smoothingTimeConstant = smoothingTimeConstantRef.current;
        // console.log("smoothingTimeConstantRef.current:", smoothingTimeConstantRef.current);
        // console.log("freqData_Fast:", freqData_Fast);    

        // SLOW smoothing
        const freqData_Slow = new Uint8Array(bufferLength);
        audioAnalyzerNode_SlowRef.current.getByteFrequencyData(freqData_Slow);
        // dump smoothed FFT content by again setting 'smoothingTimeConstant'
        audioAnalyzerNode_SlowRef.current.smoothingTimeConstant = 0.90;
        audioAnalyzerData_SlowRef.current = freqData_Slow;
        // console.log("freqData_Slow:", freqData_Slow);  

        let _debugMessage = "";

        // max idx
        // const idxMax = freqData_Fast.reduce((m, n, i) => n > (freqData_Fast[m] ?? -Infinity) ? i : m, -1)
        // _debugMessage += `freqData_Fast MAX at ${idxMax} value: ${freqData_Fast[idxMax]}`;

        // const sumAll = freqData_Fast.reduce((partialSum, a) => partialSum + a, 0);
        // _debugMessage += `sumAll: ${sumAll} `;

        const sumHighFreq = freqData_Fast.reduce((partialSum, a, i) => i > cutoffFrequencyRef.current ? partialSum + a : partialSum, 0);
        _debugMessage += ` sumHighFreq: ${sumHighFreq}`;

        const sumLowFreq = freqData_Fast.reduce((partialSum, a, i) => i <= cutoffFrequencyRef.current ? partialSum + a : partialSum, 0);
        _debugMessage += ` sumLowFreq: ${sumLowFreq}`;


        if (sumLowFreq < 1000) {
            // console.log("Skipping SILENCE");
            return;
        }
        if (sumHighFreq > 1000) {
            // console.log("Skipping HIGH FREQ. NOISE");
            return;
        }

        // Done if collected 50 GOOD samples - show how long it took
        //   collecting into 'activeSpeakerDataRef.current.speaker_spectrum[]'
        const GOOD_SAMPLES_LIMIT = 30;
        if (activeSpeakerDataRef.current != null) {

            if (activeSpeakerDataRef.current.speaker_spectrum.length === 0) {
                frequencySamplingStartTimestampRef.current = new Date();
            }

            if (activeSpeakerDataRef.current.speaker_spectrum.length < GOOD_SAMPLES_LIMIT) {
                activeSpeakerDataRef.current.speaker_spectrum.push(freqData_Fast);

                // just finished getting all GOOD_SAMPLES_LIMIT
                if (activeSpeakerDataRef.current.speaker_spectrum.length === GOOD_SAMPLES_LIMIT) {
                    frequencySamplingDurationRef.current = new Date() - frequencySamplingStartTimestampRef.current;

                    RTAnalyticsWorker.postMessage({
                        action: 'includeParticipantSpectrum',
                        speakerData: activeSpeakerDataRef.current
                    });
                }
            }
        }

        const VOICE_SAMPLES_COUNT = 10;
        voiceSpectrumSamplesRef.current.push(freqData_Fast);
        if (voiceSpectrumSamplesRef.current.length >= VOICE_SAMPLES_COUNT) {
            // Send to analyze
            RTAnalyticsWorker.postMessage({
                action: 'matchSpeakerSpectrum',
                voiceSpectrumSamples: [...voiceSpectrumSamplesRef.current],
            });

            voiceSpectrumSamplesRef.current = [];
        }




        // create accumulator spectrum if NULL - for VISUALISATION only
        if (!audioAnalyzerData_FastRef.current?.length) {
            audioAnalyzerData_FastRef.current = new Uint8Array(bufferLength);
        }
        let _updatesCount = 0;
        let _updatesAmount = 0;
        // Merge 'freqData_Fast'  into accumulator spectrum 'audioAnalyzerData_FastRef'
        audioAnalyzerData_FastRef.current = audioAnalyzerData_FastRef.current.map((value1, index) => {
            const value2 = freqData_Fast[index];
            if (value2 > value1) {
                if (index <= cutoffFrequencyRef.current) {
                    _updatesCount++;
                    _updatesAmount += value2 - value1;
                }

                return value2;
            }
            return value1;
        });
        // _debugMessage += ` _updatesCount: ${_updatesCount} _updatesAmount: ${_updatesAmount} `;
        // _debugMessage += ` speaker: ${activeSpeakerDataRef.current?.speaker} `;
        // _debugMessage += ` frequencySamplingDurationRef: ${frequencySamplingDurationRef.current} `;


        setDebugMessage(_debugMessage);

        // console.log("audioAnalyzerNode_FastRef: getByteFrequencyData(): dataArray", audioAnalyzerDataRef.current);
        drawAudioAnalyzerResults();
    }








    useEffect(() => {
        if (!param_spelling) {
            return;
        }

        // console.log("RecorderTranscriptor: useEffect: param_spelling: ", param_spelling);
        if (param_spelling === "") return;

        const newSpellingArray = param_spelling.match(/\w+/g);


        let currentSpellingArray = spellingTextArea.current.value.match(/\w+/g);
        if (currentSpellingArray == null) {
            currentSpellingArray = [];
        }
        console.log("RecorderTranscriptor: useEffect: param_spelling: currentSpellingArray:", currentSpellingArray);

        for (let i = 0; i < newSpellingArray.length; i++) {
            const word = newSpellingArray[i];
            if (!currentSpellingArray.includes(word)) {
                currentSpellingArray.push(word);
            }
        }

        spellingTextArea.current.value = currentSpellingArray.join(' ');

    }, [param_spelling]);




    const [RTWorker, setRTWorker] = useState(null);
    useEffect(() => {
        if (RTWorker) {
            // Processing Worker return
            RTWorker.onmessage = function (e) {
                // console.log("received from RTworker:", e.data);

                if (!e) return;
                if (!!e.data?.error) {
                    console.log("received ERROR from RTworker:", e.data);
                    return;
                }

                const action = e.data.action;

                if (action === "test") {
                    console.log("received test response from RTworker");

                    // using test to detect finished initialization
                    // console.log("setInitializingFlag: false");
                    setInitializingFlag(false);
                }
            };

            // Sending to Worker to process
            RTWorker.postMessage({ action: 'test' });

        }
    }, [RTWorker]);


    const [RTAnalyticsWorker, setRTAnalyticsWorker] = useState(null);
    useEffect(() => {
        if (RTAnalyticsWorker) {



            // Check every 100 milliseconds for 'window?.varAvailableSpeakersArray'
            const intervalId = setInterval(() => {
                if (window?.varAvailableSpeakersArray != null) {
                    clearInterval(intervalId); // Stop the polling FIRST
                    console.log("Global variable 'varAvailableSpeakersArray' is set, running RTAnalyticsWorker: action:'loadAvailableSpeakersArray'");

                    RTAnalyticsWorker.postMessage({
                        action: 'loadAvailableSpeakersArray',
                        availableSpeakersArray: window.varAvailableSpeakersArray
                    });
                } else {
                    console.log("Waiting for Initialization of: window?.varAvailableSpeakersArray and RTAnalyticsWorker");
                }
            }, 100);



            // Define Processing Worker RETURN
            RTAnalyticsWorker.onmessage = function (e) {
                // console.log("received from RTAnalyticsWorker:", e.data);

                // abort on error
                if (!e) return;
                if (!!e.data?.error) {
                    console.log("received ERROR from RTAnalyticsWorker:", e.data);
                    return;
                }
                const action = e.data.action;

                let recognizedActionFlag = false;

                //
                // RETURN FROM ACTION: test
                //   
                if (action === "test") {
                    recognizedActionFlag = true;
                    console.log("received test response from RTAnalyticsWorker");
                }



                //
                // RETURN FROM ACTION: updateSpeakerData
                //    RETURNS UPDATED SPEAKER record with Spectrum calculations
                //   
                if (action === "updateSpeakerData") {
                    recognizedActionFlag = true;
                    console.log("response 'updateSpeakerData' from RTAnalyticsWorker: Updated 'speakerData':", e.data.speakerData);

                    const speakerData = e.data.speakerData;
                    // speaker
                    // speaker_spectrum 
                    // speaker_spectrum_coalesced

                    const speakerRecord = window.varAvailableSpeakersArray.find(obj => obj["speaker_name"] === speakerData.speaker);
                    speakerRecord.speaker_spectrum_coalesced = speakerData.speaker_spectrum_coalesced;

                    const _speakerRecordUpdate = {
                        id: speakerRecord.id,
                        speaker_spectrum: speakerData.speaker_spectrum,
                        speaker_spectrum_coalesced: speakerData.speaker_spectrum_coalesced,
                    }

                    // Save to DynamoDB - Async
                    const fnUpdateSpeakerDB = async (_speakerRecordUpdate) => {
                        console.log("RecorderTranscriptor: On Message 'updateSpeakerData': 'fetch' POST sent _speakerRecordUpdate:", _speakerRecordUpdate);
                        let res = await FetchDataUtils.postData('speakers', _speakerRecordUpdate);
                        if ('error' in res) { // new way of saving failed by returning 'null'
                            console.log("ERROR RecorderTranscriptor: createSpeakerDB: FAILED to update data:", res);
                            res = null;
                        }
                    }
                    fnUpdateSpeakerDB(_speakerRecordUpdate);
                }



                //
                // RETURN FROM ACTION: includeParticipantSpectrum
                //    RETURNS UPDATED SPEAKER record with Spectrum calculations
                //   
                if (action === "includeParticipantSpectrum") {
                    recognizedActionFlag = true;
                    console.log("ERROR DEPRECATED ACTION 'includeParticipantSpectrum' from RTAnalyticsWorker: Updated 'speakerData':", e.data.speakerData);
                    // return;
                    // const speakerData = e.data.speakerData;
                    // // speaker
                    // // speaker_spectrum -> speaker_spectrum
                    // // speaker_spectrum_coalesced

                    // const speakerRecord = window.varAvailableSpeakersArray.find(obj => obj["speaker_name"] === speakerData.speaker);
                    // speakerRecord.speaker_spectrum_coalesced = speakerData.speaker_spectrum_coalesced;

                    // const _speakerRecordUpdate = {
                    //   id: speakerRecord.id,
                    //   speaker_spectrum: speakerData.speaker_spectrum,
                    //   speaker_spectrum_coalesced: speakerData.speaker_spectrum_coalesced,
                    // }

                    // // Save to DynamoDB
                    // console.log("ListSpeakers: createSpeakerDB: 'fetch' POST sent _speakerRecordUpdate:", _speakerRecordUpdate);
                    // let res = FetchDataUtils.postData('speakers', _speakerRecordUpdate);
                    // // if('error' in res){ // new way of saving failed by returning 'null'
                    // //   console.log("ERROR ListSpeakers: createSpeakerDB: FAILED to update data:", res);
                    // //   res = null;
                    // // }

                }


                //
                // RETURN FROM ACTION: matchSpeakerSpectrum
                //    NO RETURN FROM THIS ACTION
                //   
                // returns: matches = [{
                //   speaker: "tj",
                //   confidence: 0.88,
                // }]
                if (action === "matchSpeakerSpectrum") {
                    recognizedActionFlag = true;
                    // console.log("response 'matchSpeakerSpectrum' from RTAnalyticsWorker", e.data.matches);
                    const matches = e.data.matches;

                    if (matches.length === 0) {
                        //   console.log("response 'matchSpeakerSpectrum' from RTAnalyticsWorker: NO MATCHES");
                    } else {

                        const sumAllSimilarities = matches.reduce((curPartialSum, item) => curPartialSum + item.similarity, 0);
                        // console.log("response 'matchSpeakerSpectrum' from RTAnalyticsWorker: average, sumAllSimilarities", sumAllSimilarities/matches.length, sumAllSimilarities);

                        const max = matches.reduce((curMax, item) => item.similarity >= (curMax ?? -Infinity) ? item.similarity : curMax, -1);
                        // console.log("response 'matchSpeakerSpectrum' from RTAnalyticsWorker: MAX similarity", max);

                        const min = matches.reduce((curMin, item) => item.similarity <= (curMin ?? Infinity) ? item.similarity : curMin, -1);
                        // console.log("response 'matchSpeakerSpectrum' from RTAnalyticsWorker: MIN similarity", min);

                        //   console.log("average:", (sumAllSimilarities/matches.length).toFixed(2), "MAX:", max.toFixed(2),"MIN", min.toFixed(2))
                    }
                }



                // //
                // // RETURN FROM ACTION: getLocalPeak
                // //
                // if (action === "getLocalPeak"){
                //  doneServicingFlag = true;
                //   const localPeak = e.data.localPeak;
                //   // console.log("localPeak:", localPeak);
                //   if (localPeak > audioPeakRef.current){
                //     audioPeakRef.current = localPeak;
                //     // console.log("new audioPeakRef.current:", audioPeakRef.current);
                //   }
                //   // console.log("audioPeakRef.current:", audioPeakRef.current);
                // }


                // //
                // // RETURN FROM ACTION: compareAudios
                // //
                // if (action === "compareAudios"){
                // doneServicingFlag = true;
                //   const result = e.data.result;
                //   console.log("RTAnalyticsWorker (action: compareAudios): result:", result);

                //   setVoicesSimilarity(result);
                // }

                if (!recognizedActionFlag) {
                    console.log("ERROR NOT-IMPLEMENTED ACTION in RecorderTranscriptor: RTAnalyticsWorker action::", action);
                }

            };



            // Sending to Worker to process
            RTAnalyticsWorker.postMessage({ action: 'test' });


            // if(audioAnalyzerDataRef.current.length > 0 && 
            //   voicesAudioDataRef.current.length > 0) {

            //   RTAnalyticsWorker.postMessage({
            //     action:'compareAudios',
            //     unknownVoiceAudioData: [...audioAnalyzerDataRef.current],
            //     voicesAudioData: [...voicesAudioDataRef.current],
            //   });
            // }
        }


    }, [RTAnalyticsWorker]);




    async function openMicrophone() {

        sessionIdRef.current = uuidv4();
        //
        // Only Global var: audioContextRef
        //
        // A Female voice frequency range covers fairly upto 350 Hz to 17KHz. Its fundamental frequency is 350Hz to 3KHz and Harmonics is 3KHz to 17KHz. 
        // Male voice covers a Frequency range of 100Hz to 8KHz. The fundamental is 100Hz to 900Hz and Harmonics is 900Hz to 8KHz.
        audioContextRef.current = new AudioContext({
            sampleRate: 44100,
        });

        let _RTAudioWorkletNode = null;
        try{
            console.log("creating RTAudioWorkletProcessor AudioWorkletNode");

            let scriptUrl = null;

            // URL for RTAudioWorkletProcessor:
            if (window.location.href.indexOf("localhost") > -1){
                scriptUrl = new URL('./RTAudioWorkletProcessor.js', import.meta.url);
                console.log("URL for RTAudioWorkletProcessor:", scriptUrl.href);
            } else {
                scriptUrl = new URL(window.location.href + 'RTAudioWorkletProcessor.js');
                console.log("URL for RTAudioWorkletProcessor:", scriptUrl.href);
            }

            await audioContextRef.current.audioWorklet.addModule(scriptUrl);

            _RTAudioWorkletNode = new AudioWorkletNode(audioContextRef.current, 'RTAudioWorkletProcessor');
            _RTAudioWorkletNode.port.onmessage = (event) => {
                microphoneOnData_Float32Array(event.data);
            };

            console.log("created RTAudioWorkletProcessor AudioWorkletNode");

        }catch(err){
            console.log("Error adding Module RTAudioWorkletProcessor.js");
            console.error(err);
            throw err;
        }

        let stream;
        if (!!audioElementLoadedFromFileRef.current) {
            // Create a stream from the Audio element
            stream = audioElementLoadedFromFileRef.current.captureStream();
        } else {
            // Create a stream from the Microphone
            stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        }

        const source = audioContextRef.current.createMediaStreamSource(stream);
        const sampleRate = audioContextRef.current.sampleRate;
        console.log('Sample Rate:', sampleRate); // usually 44100
        transcriptorEngine.sampleRate = sampleRate;

        const audioSplitter = audioContextRef.current.createChannelSplitter(2);

        audioAnalyzerNode_FastRef.current = audioContextRef.current.createAnalyser();
        audioAnalyzerNode_FastRef.current.fftSize = 1024;
        // audioAnalyzerNode_FastRef.current.smoothingTimeConstant = 0.9; // 0.85
        audioAnalyzerNode_FastRef.current.minDecibels = -90; // -100
        audioAnalyzerNode_FastRef.current.maxDecibels = -30; // -30

        audioAnalyzerNode_SlowRef.current = audioContextRef.current.createAnalyser();
        audioAnalyzerNode_SlowRef.current.fftSize = 1024;
        // audioAnalyzerNode_SlowRef.current.smoothingTimeConstant = 0.9; // 0.85
        audioAnalyzerNode_SlowRef.current.minDecibels = -90; // -100
        audioAnalyzerNode_SlowRef.current.maxDecibels = -30; // -30


        //
        //
        // connecting the Audio Pipeline
        source
            .connect(audioSplitter);

        audioSplitter.connect(audioAnalyzerNode_SlowRef.current, 0);

        audioSplitter.connect(audioAnalyzerNode_FastRef.current, 1)
            .connect(_RTAudioWorkletNode)
            .connect(audioContextRef.current.destination);

        capturingStartTimestampRef.current = new Date().getTime() / 1000;
        capturedStartOfSentenceTimestampRef.current = capturingStartTimestampRef.current;


        // INTERVAL Audio Analysis
        if (showExperimentalFeaturesFlag) {
            // kick off audio analysis drawing
            audioAnalyzerTimerRef.current = setInterval(
                getAndDrawAudioAnalyzerResults, 50);
        }
    }



    function closeMicrophone() {
        try {
            if (!!audioContextRef.current) {
                audioContextRef.current.close();
            }
        } catch (e) {
            console.log('ERROR closing Microphone: ', e);
        }

        if (!!audioAnalyzerTimerRef?.current) {
            clearInterval(audioAnalyzerTimerRef.current);
        }
    }




    async function microphoneOnData_Float32Array(data) {

        const now = new Date().getTime() / 1000;
        // console.log("microphoneOnData_Float32Array: data:", data);

        transcriptorEngine.appendEncoded(data);

        //
        // Update Length of Time in Seconds of Capturing audio
        //
        const _newCapturedTotalSeconds = now - capturingStartTimestampRef.current;
        if (capturedSeconds + 1 < _newCapturedTotalSeconds) {
            // console.log(capturingTimeRef.current);
            setCapturedSeconds(Math.floor(_newCapturedTotalSeconds));
        }


        const _newCapturedSentenceTotalSeconds = now - capturedStartOfSentenceTimestampRef.current;
        if (_newCapturedSentenceTotalSeconds < 5) {
            return;
        }

        if (!transcriptorEngine.isReadyToSend()) { // is audio big enough for sending
            return;
        }


        // must NOT be SILENCE
        if (transcriptorEngine._curAudioLength > transcriptorEngine._curSilenceLength) {

            const audioBuffer = Buffer.from(transcriptorEngine._pcmEncodedBuffer);

            const payloadObj = {
                action: "transcribe",
                prompt: spellingContentRef.current,
                lastSentence: lastSentenceRef.current,
                metaData: {
                    userId: window.varUser.username,
                    capturingStartTimestamp: capturingStartTimestampRef.current,
                    capturedStartOfSentenceTimestamp: capturedStartOfSentenceTimestampRef.current,
                    capturedEndOfSentenceTimestamp: now,
                    timestamp_granularities: "word",
                },
            };
            // console.log("payloadObj:", payloadObj);
            // console.log("capturingStartTimestamp value:", StringUtils.timestampToFormattedDateTime_Underscored(payloadObj.metaData.capturingStartTimestamp));
            // console.log("capturingStartTimestamp timestam:", payloadObj.metaData.capturingStartTimestamp);

            // console.log("capturedStartOfSentenceTimestamp value:", StringUtils.timestampToFormattedDateTime_Underscored(payloadObj.metaData.capturedStartOfSentenceTimestamp));

            payloadObj.binaryData = audioBuffer; // binaryData

            const payload = JSON.stringify(payloadObj);
            //
            // SEND TO TRANSCRIBE
            //
            socket.current.send(payload);






        } else {

            // SILENCE
            lastSentenceRef.current = ""; // last sentence is not useful any more
        }

        capturedTotalSecondsRef.current = _newCapturedTotalSeconds;
        // reset sentence timestamp
        capturedStartOfSentenceTimestampRef.current = new Date().getTime() / 1000;

        // clear the Buffer
        transcriptorEngine._rawAudioArray = [];
        transcriptorEngine._pcmEncodedBuffer = new Buffer.from([]);

        // reset counters
        transcriptorEngine._curAudioLength = 0;
        transcriptorEngine._curSilenceLength = 0;



    }






    //
    //  Web Socket 'socket'
    // 
    let socket = useRef(null);

    function socketOnOpen() {
        // console.log("EVENT On open socket triggered.");
    };


    function socketOnMessage(message) {
        // handle inbound messages from Transcribe service
        // console.log("Socket received message: ", message);
        //
        // Format is json string:
        // {
        //   transcript: "texttext",
        //   metaData: { 
        //    startTime: seconds,
        //    endTime: seconds,
        //   }
        // }
        // }

        try {

            const payload = JSON.parse(message.data);
            console.log("received transcript payload:", payload);

            const currentTranscript = payload.transcript;

            const _metaData = payload.metaData;
            const startTime = _metaData.capturedStartOfSentenceTimestamp - _metaData.capturingStartTimestamp;
            const endTime = _metaData.capturedEndOfSentenceTimestamp - _metaData.capturingStartTimestamp;




            // const newParagraph = {
            //     content: currentTranscript,
            //     start_time: startTime,
            //     end_time: endTime,
            //     words: payload.words,
            // };
            // console.log("created newParagraph for commitTranscript():", newParagraph);
            // commitTranscript(newParagraph);

            // // get last sentence after "."
            // lastSentenceRef.current = newParagraph.content.split('.').slice(-1)[0];





            // One sentence
            if (!payload?.sentences?.length ||
                (payload?.sentences != null && payload.sentences.length === 1)
            ) {
                // missing 'payload.sentences' or ONLY ONE => then process all at once 
                console.log("missing 'payload.sentences' or ONLY ONE => processing all at once")
                const newParagraph = {
                    content: currentTranscript,
                    start_time: startTime,
                    end_time: endTime,
                    words: payload.words,
                };
                console.log("ALL created newParagraph for commitTranscript():", newParagraph);
                commitTranscript(newParagraph);

                // get last sentence after "."
                lastSentenceRef.current = newParagraph.content.split('.').slice(-1)[0];

            } else {
                // MULTIPLE sentences

                function SentenceGroupSplitter(sentenceGroup) {
                    // Initialize the array of groups
                    let result = [];
                    let currentGroup = [];

                    // Iterate over each sentence in the sentenceGroup
                    for (let i = 0; i < sentenceGroup.length; i++) {
                        // Add the current sentence to the current group
                        currentGroup.push(sentenceGroup[i]);

                        // Check if this is the last sentence or if the gap to the next sentence is more than 1 second
                        if (i === sentenceGroup.length - 1 || sentenceGroup[i + 1].start - sentenceGroup[i].end > 1) {
                            // If it's the last sentence or the gap is too big, push the current group to the result array
                            result.push(currentGroup);
                            // Start a new group
                            currentGroup = [];
                        }
                    }
                    return result;
                }
                const newSentenceGroupsArray = SentenceGroupSplitter(payload.sentences);
                console.log("newSentenceGroupsArray:", newSentenceGroupsArray);

                // process them one-by-one
                let newParagraph = null;
                for (let i = 0; i < newSentenceGroupsArray.length; i++) {
                    const oneSentenceGroup = newSentenceGroupsArray[i];

                    // merge sentences in this group
                    let content = '';
                    let groupStartTime = Number.MAX_SAFE_INTEGER;
                    let groupEndTime = Number.MIN_SAFE_INTEGER;
                    let words = [];

                    for (let sentenceObj of oneSentenceGroup) {
                        // Concatenate sentence contents, separating with a space if needed
                        if (content.length > 0) {
                            content += ' ';
                        }
                        content += sentenceObj.sentence;

                        // Update the start and end times
                        groupStartTime = Math.min(groupStartTime, sentenceObj.start);
                        groupEndTime = Math.max(groupEndTime, sentenceObj.end);

                        // Merge the words arrays
                        words = words.concat(sentenceObj.words);
                    }

                    newParagraph = {
                        content: content,
                        start_time: startTime + groupStartTime,
                        end_time: startTime + groupEndTime,
                        words: words,
                    };
                    console.log("PARTIAL created newParagraph for commitTranscript():", newParagraph);
                    commitTranscript(newParagraph);
                }
                // get last sentence after "."
                lastSentenceRef.current = newParagraph.content.split('.').slice(-1)[0];
                // console.log("Last sentence: ", lastSentenceRef.current);
            }










            // ADDITIONAL PROCESSING - Voice, etc

            // // TODO: process payload.words if present - for speaker recognition processing
            // //     words: [ { word: 'This', start: 0, end: 0.41999998688697815 }, ... ]
            // if (!!payload?.words){
            //     console.log("received transcribed individul words:", payload.words);
            //     // TODO
            // }

        } catch (err) {
            // setCapturingError("socketOnMessage: ERROR in Transcription", err);
            console.log("ERROR in Transcription: ", err.message);
            // stopRecording();  
        }
    };


    function socketOnError(e) {
        if (recordingFlag.current === true) {
            // setCapturingError('socketOnError: ERROR WebSocket: ');
            console.log('ERROR WebSocket: ', e);
            stopRecording();
        }
    };


    function socketOnClose(closeEvent) {
        if (recordingFlag.current === true) {
            // setCapturingError('socketOnClose: ERROR unscheduled OnClose WebSocket: ');
            console.log('ERROR unscheduled OnClose WebSocket: ', closeEvent);
            stopRecording();
        }
    };




    function openSocket() {

        function calculateTranscriptionWebsocketUrl() {
            let protocol = (window.location.protocol === 'https:') ? "wss:" : "ws:";
            let url = protocol + "//" + window.location.hostname + "/t";
            if (process.env.NODE_ENV === "development") {
                // url = "ws://localhost:8443/";
                url = "ws://localhost:8000/";
            }
            return url;
        }
        let url = calculateTranscriptionWebsocketUrl();

        socket.current = new WebSocket(url);
        //open up our WebSocket connection
        socket.current.binaryType = "arraybuffer";
        // when we get audio data from the mic, send it to the WebSocket if possible

        socket.current.addEventListener("open", socketOnOpen);
        socket.current.addEventListener("message", socketOnMessage);
        socket.current.addEventListener("error", socketOnError);
        socket.current.addEventListener("close", socketOnClose);
    }



    function closeSocket() {
        try {
            if (!socket.current) return;

            socket.current.removeEventListener("open", socketOnOpen);
            socket.current.removeEventListener("message", socketOnMessage);
            socket.current.removeEventListener("error", socketOnError);
            socket.current.removeEventListener("close", socketOnClose);

            if (socket.current.readyState === socket.current.OPEN) {

                // const emptyBinaryBuffer = transcriptorEngine.encode(null);
                // socket.current.send(emptyBinaryBuffer);

                socket.current.close();
            }
        } catch (e) {
            // setCapturingError('closeSocket: ERROR during closing WebSocket: ');
            console.log('ERROR during closing WebSocket: ', e);
        }
    }






    function commitTranscript(newSentence) {

        // console.log("CommitTranscript(): newSentence:", newSentence);

        let prevSentence = null;
        let nextSentence = null;
        let position = -1; // add sentence to the end

        // determine Position
        if (sentencesListRef.current.length > 0) {
            // Find the correct position for the new sentence
            position = sentencesListRef.current.findIndex(sentence => {
                if (newSentence.start_time < sentence.start_time) {
                    return true;
                } else if (newSentence.start_time === sentence.start_time) {
                    return newSentence.end_time < sentence.end_time;
                }
                return false;
            });
        }

        // determine Previous and Next Sentences
        if (position === -1) { // should be inserted at the end
            if (sentencesListRef.current.length > 0) {
                prevSentence = sentencesListRef.current[sentencesListRef.current.length - 1];
            }
        } else {
            nextSentence = sentencesListRef.current[position];
            if (position > 0) {
                prevSentence = sentencesListRef.current[position - 1];
            }
        }


        function appendToPreviousSentenceIfClose(newSentence, prevSentence) {
            let appendedFlag = false;

            if (prevSentence == null) return false;

            // determine if Appending to Previous Sentence
            //      first word has delay
            if (newSentence.words != null && newSentence.words.length > 0) {
                const firstWordDelay = newSentence.words[0].start;
                // console.log("firstWordDelay:", firstWordDelay);
                if (firstWordDelay > 1) return false; // > 1 sec. delay => Don't Append
            }

            // determine if Appending to Previous Sentence
            if (newSentence.start_time - prevSentence.end_time < 0.5) {
                appendedFlag = true;

                // if new sentence is continuation - need to append
                let _continuationFlag = false;
                // lowercase
                if (!/^[A-Z]/.test(newSentence.content)) {
                    _continuationFlag = true;
                }
                // acronym
                if (/^[A-Z][A-Z]/.test(newSentence.content)) {
                    _continuationFlag = true;
                }
                // digit
                if (/^\d/.test(newSentence.content)) {
                    _continuationFlag = true;
                }

                // if ( !_continuationFlag ){
                //     // if target sentence is not concluded with '.'
                //     if (!prevSentence.content.endsWith('.')){
                //         prevSentence.content += "."
                //     }
                // }
                prevSentence.content += " " + newSentence.content;
                prevSentence.end_time = newSentence.end_time;
            }
            return appendedFlag;
        }
        const appendedFlag = appendToPreviousSentenceIfClose(newSentence, prevSentence); // updates prevSentence



        function prependToNextSentenceIfClose(newSentence, nextSentence) {
            let prependedFlag = false;

            if (nextSentence == null) return false;

            if (prevSentence != null && nextSentence.start_time - prevSentence.end_time < 0.5) {
                prependedFlag = true;

                // if new sentence is continuation - need to append
                let _continuationFlag = false;
                // lowercase
                if (!/^[A-Z]/.test(nextSentence.content)) {
                    _continuationFlag = true;
                }
                // acronym
                if (/^[A-Z][A-Z]/.test(nextSentence.content)) {
                    _continuationFlag = true;
                }
                // digit
                if (/^\d/.test(nextSentence.content)) {
                    _continuationFlag = true;
                }

                if (!_continuationFlag) {
                    // if target sentence is not concluded with '.'
                    if (!newSentence.content.endsWith('.')) {
                        newSentence.content += "."
                    }
                }
                nextSentence.content = newSentence.content + " " + nextSentence.content;
                nextSentence.start_time = newSentence.start_time;
            }
            return prependedFlag;
        }

        let prependedFlag = false;
        if (appendedFlag) {

            prependedFlag = prependToNextSentenceIfClose(prevSentence, nextSentence); // updates nextSentence
            if (prependedFlag) {
                // remove prevSentence from array
                sentencesListRef.current.splice(position - 1, 1);
            }
        } else {
            prependedFlag = prependToNextSentenceIfClose(newSentence, nextSentence); // updates nextSentence
        }


        let lastSentence = null;
        if (!appendedFlag && !prependedFlag) {
            // insert into position
            if (position === -1) { // should be inserted at the end

                sentencesListRef.current.push(newSentence);
            } else {

                sentencesListRef.current.splice(position, 0, newSentence);
                lastSentence = null; // no need to send update, overwritten by new
            }
        }


        // const capSec = capturedSeconds;
        // console.log("RecorderTranscriptor: commitTranscript(): capturedSeconds:", capturedSeconds);
        const _capture = param_FnOnTranscriptData(sentencesListRef.current, capturedTotalSecondsRef.current);

        return _capture;
    }




    async function changeRecordingStatus(newRecordingStatus) {
        // console.log("changeRecordingStatus: old: ", recordingStatusFlag, " new: ", newRecordingStatus);

        if (newRecordingStatus === null) {
            return;
        }

        if (recordingStatusFlag === "Stop" && newRecordingStatus === "Pause") {
            return;
        }

        if (recordingStatusFlag === "Stop" && newRecordingStatus === "Record") {
            capturedTotalSecondsRef.current = 0;
            await startRecording();
            setRecordingStatusFlag("Record");
            setRecordingStatusDescription("Recording");
        }

        // old can be any: Record, Pause, Stop
        if (newRecordingStatus === "Stop") {
            await stopRecording();
            setRecordingStatusFlag("Stop");
            setRecordingStatusDescription("Stopped");
        }


        if (recordingStatusFlag === "Pause" && newRecordingStatus === "Record") {
            await restartRecording();
            setRecordingStatusFlag("Record");
            setRecordingStatusDescription("Recording");
        }
    }




    async function startRecording() {
        // setCapturingError("");

        console.log('start recording');
        openMicrophone();
        openSocket();
        recordingFlag.current = true;

        param_FnOnStartCapturing();
    }



    async function stopRecording() {
        // console.log('stopping recording');

        closeMicrophone();
        recordingFlag.current = false;

        // captured seconds minus silent time at the end
        const capSec = capturedSeconds - transcriptorEngine._curSilenceLength;
        param_FnOnEndCapturing(capSec, spellingContentRef.current);


        if (!!socket.current) { // socket was ever opened?
            // send "close" to server with data
            const payloadObj = {
                action: "close",
                metaData: {
                    userId: window.varUser.username,
                    capturingStartTimestamp: capturingStartTimestampRef.current,
                    speakersActivity: speakersActivity,
                    sentencesList: sentencesListRef.current,
                }
            };
            // console.log("payloadObj:", payloadObj);
            // console.log("capturingStartTimestamp value:", StringUtils.timestampToFormattedDateTime_Underscored(payloadObj.metaData.capturingStartTimestamp));
            // console.log("capturingStartTimestamp timestam:", payloadObj.metaData.capturingStartTimestamp);
            const payload = JSON.stringify(payloadObj);
            socket.current.send(payload);

            closeSocket();
        }

        sentencesListRef.current = [];
        // setPartialTranscript("");
        setCapturedSeconds(0);
    }



    // async function pauseRecording() {
    //   // console.log('pausing recording');

    //   closeMicrophone();
    //   closeSocket();
    //   recordingFlag.current = false;
    // }

    async function restartRecording() {
        // console.log('restart recording');
        openMicrophone();
        openSocket();
        recordingFlag.current = true;
    }





    const secondsToMinSecPadded = timeInSeconds => {
        if (isNaN(timeInSeconds)) {
            return "- : -";
        }
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = "0" + (timeInSeconds - minutes * 60);
        return minutes + ":" + seconds.substr(-2);
    };



    function recordingStatusColor(recordingStatusFlag) {
        let color = "black";
        if (recordingStatusFlag === "Record") {
            color = "red";
        }
        if (recordingStatusFlag === "Pause") {
            color = "blue";
        }
        return color;
    }


    const showInfoObject = (infoData1) => Object.keys(infoData1).map((a, o) => {
        let value = infoData1[a];
        if (typeof value === 'object' && value !== null) {
            // value = JSON.stringify(value);
            if (Array.isArray(value)) {
                // value = JSON.stringify(value);

                // let _value = (<div>hi there</div>)
                // for(let i=0; i<value.length; i++){

                // }


                // value = showInfoObject(value[0]);

                value = showInfoArray(value);
            } else {
                value = showInfoObject(value);
            }
        }
        return (
            <div>
                {a}:{value}
            </div>
        )
    });

    const showInfoArray = (d) => d.map((v, i) => {
        let _v = v;
        if (typeof _v === 'object' && _v !== null) {
            _v = showInfoObject(_v);
        }
        return (
            <div key={i}>{_v}</div>
        )
    });


    return (
        <View as="div"
            marginTop="1rem"
            position="fixed"
            right="6%"
            top="6%"
            backgroundColor="white"
            boxShadow="3px 3px 5px 6px var(--amplify-components-radio-button-error-border-color)"
            padding="1rem"
            maxWidth="25rem"
            className="cls-zindex-10"
            // minHeight="22rem"
            style={{ height: isCollapsed ? '6rem' : '22rem' }}
        >

            <Flex
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                marginLeft="auto"
            >
                <View as="div"
                    onClick={toggleCollapse}
                    style={{ cursor: 'pointer' }}
                >
                    <IconContext.Provider value={{ size: "1.5rem" }}>
                        {isCollapsed ? <BiCaretRight /> : <BiCaretDown />}
                    </IconContext.Provider>
                </View>

                <View as="div">
                    <View as="div"
                        textAlign="center"
                        color={recordingStatusColor(recordingStatusFlag)}
                    >
                        {recordingStatusDescription}
                    </View>

                    <Badge
                        variation="info"
                        margin=".4em"
                        width="6rem"
                    >
                        {secondsToMinSecPadded(capturedSeconds)} min
                    </Badge>
                </View>


                <View>
                    {initializingFlag}
                    {initializingFlag &&
                        <View>
                            Initializing...
                        </View>
                    }

                    {!initializingFlag &&
                        <ToggleButtonGroup
                            value={recordingStatusFlag}
                            onChange={(value) => {
                                //console.log(value);
                                changeRecordingStatus(value);
                            }}
                            isExclusive
                            isSelectionRequired
                        >
                            <ToggleButton value="Record" fontSize="2.5rem">
                                <BsRecordFill color="red"
                                />
                            </ToggleButton>
                            {/* <ToggleButton value="Pause" fontSize="2.5rem">
                                <GiPauseButton />
                            </ToggleButton> */}
                            <ToggleButton value="Stop" fontSize="2.5rem">
                                <GiPlainSquare />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    }
                </View>

            </Flex>


            <Flex
                direction="column"
                justifyContent="left"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="wrap"
                gap="0rem"
                overflow="hidden"
                padding="1px"
                //margin="1rem 1rem 3rem 1rem"
                style={{ display: isCollapsed ? 'none' : null }}
            >

                <Flex
                    direction="row"
                >
                    <View as='div'
                        height='2rem'
                        width='6rem'
                        style={{
                            backgroundColor: '#2c3f51',
                        }}
                        onClick={() => { setShowExperimentalFeaturesFlag(!showExperimentalFeaturesFlag) }}
                    >
                        {recordingStatusFlag === 'Record' &&
                            <View as='div'
                                style={{
                                    backgroundImage: 'url(giphy.gif)',
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        }
                    </View>

                    {showExperimentalFeaturesFlag &&
                        <>
                            <View
                                fontSize="0.9rem"
                                fontWeight="bold"
                            >
                                Input Volume
                            </View>
                            <View
                                fontSize="0.9rem"
                                fontWeight="bold"
                                color={audioPeakColor}
                            >
                                {audioPeak.toFixed(2)}
                            </View>
                            <View
                                onClick={() => {
                                    // if(smoothingTimeConstantRef.current < 1){
                                    //   smoothingTimeConstantRef.current += 0.1;
                                    //   if(smoothingTimeConstantRef.current > 1){
                                    //     smoothingTimeConstantRef.current = 1;
                                    //   }
                                    // }    
                                    if (cutoffFrequencyRef.current < 300) {
                                        cutoffFrequencyRef.current += 20;
                                        if (cutoffFrequencyRef.current > 300) {
                                            cutoffFrequencyRef.current = 300;
                                        }
                                    }
                                }}
                            >
                                UP
                            </View>
                            <View
                                onClick={() => {
                                    // if(smoothingTimeConstantRef.current > 0){
                                    //   smoothingTimeConstantRef.current -= 0.1;
                                    //   if(smoothingTimeConstantRef.current < 0){
                                    //     smoothingTimeConstantRef.current = 0;
                                    //   }
                                    // }
                                    if (cutoffFrequencyRef.current > 20) {
                                        cutoffFrequencyRef.current -= 20;
                                        if (cutoffFrequencyRef.current < 20) {
                                            cutoffFrequencyRef.current = 20;
                                        }
                                    }
                                }}
                            >
                                DOWN
                            </View>
                        </>
                    }

                </Flex>

                {showExperimentalFeaturesFlag &&
                    <Flex
                        direction="row"
                        alignItems="end"
                        margin="1px"
                    >

                        <View>
                            {cutoffFrequencyRef.current}
                        </View>
                        <View as='div'
                            onClick={() => {
                                const bufferLength = audioAnalyzerNode_FastRef.current.frequencyBinCount;
                                audioAnalyzerData_FastRef.current = new Uint8Array(bufferLength);

                                //also clear 'speaker_spectrum' array
                                if (!!activeSpeakerDataRef.current?.speaker_spectrum) {
                                    activeSpeakerDataRef.current.speaker_spectrum = [];
                                }
                                frequencySamplingStartTimestampRef.current = 0;
                                frequencySamplingDurationRef.current = 0;
                            }}
                        >
                            CLEAR
                        </View>
                    </Flex>
                }


                {showExperimentalFeaturesFlag &&
                    <canvas
                        ref={audioAnalyzerCanvasRef}
                        width={WIDTH}
                        height={HEIGHT}
                        onClick={() => {
                            // // HOW TO SNAPSHOT TO IMAGE
                            // const canvas = audioAnalyzerCanvasRef.current;
                            // const dataUrl = canvas.toDataURL();
                            // // HOW TO DISPLAY SNAPSHOTTED IMAGE
                            // <img src={dataUrl} />
                        }}
                    ></canvas>
                }

                <Flex
                    direction="row"
                    alignItems="normal"
                    width="100%"
                    marginTop="0.3rem"
                >
                    {debugMessage}
                </Flex>

                <Flex
                    direction="row"
                    alignItems="normal"
                    width="100%"
                    marginTop="0.3rem"
                >
                    <View>
                        Spell:
                    </View>
                    <textarea
                        ref={spellingTextArea}
                        // autoFocus={!!param_autoFocus}
                        className="cls-textarea"
                        onChange={(event) => {
                            // console.log("event.target.value:", event.target.value);
                            spellingContentRef.current = event.target.value;

                            const currentSpellingArray = spellingTextArea.current.value.match(/\w+/g);
                            console.log("RecorderTranscriptor: useEffect: param_spelling: currentSpellingArray:", currentSpellingArray);


                            // resize textarea as needed
                            spellingTextArea.current.style.height = 'auto';
                            spellingTextArea.current.style.height = spellingTextArea.current.scrollHeight + 'px';
                        }}
                    />
                </Flex>


                {showExperimentalFeaturesFlag &&
                    <Flex
                        direction="column"
                        alignItems="normal"
                        width="100%"
                        marginTop="1rem"
                        marginBottom="1rem"
                    >
                        {!audioFile &&
                            <FileUpload onFileSelect={(file) => {
                                setAudioFile(file);
                            }} />
                        }

                        {audioFile &&
                            <AudioPlayer audioFile={audioFile}
                                onAudioLoad={(audioElement) => {
                                    audioElementLoadedFromFileRef.current = audioElement;
                                }}
                            />
                        }
                    </Flex>
                }

                {/* <View>
                Detected: jj
              </View> */}

                <ListSpeakers
                    param_currentParticipants={speakersList}
                    param_suggestedParticipants={window.varClassesWithSpeakersList[param_captureClass]}
                    param_FnOnChange={(speakers) => {
                        // console.log("ListSpeakers: onChange:", speakers);
                        setSpeakersList(speakers);

                        RTAnalyticsWorker.postMessage({
                            action: 'loadParticipantsList',
                            participantsList: speakers
                        });
                        param_FnOnParticipantsListChange(speakers);
                    }
                    }
                    param_FnOnSpeakerActiveChange={(speaker, activeFlag) => {

                        // if already one active speaker -> add to SpeakerActivity
                        if (!!activeSpeakerDataRef.current) {

                            // save speaker_spectrum into windows.varParticipantsList.speaker_spectrum
                            const _speakerSpectrum = activeSpeakerDataRef.current.speaker_spectrum;
                            if (_speakerSpectrum != null) {
                                delete activeSpeakerDataRef.current.speaker_spectrum;

                                const _speakerSpectrumStr = JSON.stringify(_speakerSpectrum);

                                const speakerRecord = window.varAvailableSpeakersArray.find(obj => obj["speaker_name"] === speaker);
                                if (_speakerSpectrumStr !== speakerRecord?.speaker_spectrum) {
                                    // console.log("saving speakerSpectrumStr:", _speakerSpectrumStr);
                                    speakerRecord.speaker_spectrum = _speakerSpectrumStr;

                                    // TODO? Save to DynamoDB
                                    // actually saving happens on return message
                                    //  'includeParticipantSpectrum' from RTAnalyticsWorker

                                }
                            }

                            const newSpeakerActivity = activeSpeakerDataRef.current;
                            newSpeakerActivity.endTime = capturedSeconds;
                            newSpeakerActivity.source = "manual"; // vs. "auto"

                            // setSpeakersActivity([...speakersActivity, newSpeakerActivity]);

                            const _speakersActivity = [...speakersActivity, newSpeakerActivity];
                            setSpeakersActivity(_speakersActivity);
                            param_FnOnSpeakersActivityChange(_speakersActivity);

                            activeSpeakerDataRef.current = null;
                        }

                        if (activeFlag) {

                            // load speaker_spectrum from windows.varAvailableSpeakersArray.speaker_spectrum
                            const speakerRecord = window.varAvailableSpeakersArray.find(obj => obj["speaker_name"] === speaker);
                            const _speakerSpectrumStr = speakerRecord?.speaker_spectrum;
                            // console.log("loading speakerSpectrumStr:", _speakerSpectrumStr);
                            let _speakerSpectrum = [];
                            try {
                                _speakerSpectrum = JSON.parse(_speakerSpectrumStr);
                            } catch (ignore) { }

                            activeSpeakerDataRef.current = {
                                speaker: speaker,
                                startTime: capturedSeconds,
                                speaker_spectrum: _speakerSpectrum,
                            };


                        }

                    }}
                />


                <View
                    className="cls-flex-horizontal-child cls-overflow-Y-scroll"
                    maxHeight="10rem"
                >
                    {[...speakersActivity].reverse().map((speakerData, idx) => (

                        <Flex
                            key={idx}
                            direction="row"
                            justifyContent="flex-start"
                            margin="0.1rem"
                            alignItems="center"
                            alignContent="space-between"
                            padding="1px"
                        >
                            <Flex
                                direction="row"
                                justifyContent="flex-start"
                                // width="100%"
                                margin="0 1rem"
                                alignItems="center"
                                alignContent="space-between"
                            >
                                <View as='div'
                                // marginLeft="auto"
                                >
                                    <IconContext.Provider value={{ color: "red", size: "1rem" }}>
                                        <BiTrash
                                            color="red"
                                            onClick={() => {
                                                // console.log("clicked reversed idx:", idx, speakerData);
                                                // console.log("remove real idx:", speakersActivity.length - 1 - idx, speakersActivity);

                                                const _speakersActivity = [...speakersActivity];
                                                _speakersActivity.splice(_speakersActivity.length - 1 - idx, 1);

                                                setSpeakersActivity(_speakersActivity);
                                                param_FnOnSpeakersActivityChange(_speakersActivity);
                                            }}
                                        />
                                    </IconContext.Provider>

                                </View>

                            </Flex>

                            <View>
                                {speakerData.speaker}
                            </View>
                            <View>
                                {secondsToMinSecPadded(speakerData.startTime)}
                            </View>
                            <View>
                                -
                            </View>
                            <View>
                                {secondsToMinSecPadded(speakerData.endTime)}
                            </View>
                        </Flex>
                    ))}
                </View>

            </Flex>

        </View>

    );

}





export default RecorderTranscriptor;

