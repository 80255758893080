import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { Auth } from 'aws-amplify';


const DragAndDropUploader = ({
    param_FnOnStagedFilesForUpload
}) => {

  const [files, setFiles] = useState([]);


  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    param_FnOnStagedFilesForUpload(acceptedFiles);
  }, []);


  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    { 
        noClick: true, 
        onDrop,
        accept: {
            'audio/wave': ['.wav'],
        }
    });

  return (
    <div {...getRootProps()} style={{ border: '2px dashed #000', padding: '20px', textAlign: 'center' }}>
      
      <input {...getInputProps()} />


      <div>
        {files.map((file) => (
          <p key={file.path}>{file.path}</p>
        ))}
      </div>

      {files.length == 0 &&
        <React.Fragment>
          <p>Drop media file here...</p>

        </React.Fragment>
      }

      
    </div>
  );
};

export default DragAndDropUploader;
