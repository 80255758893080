import { useState, useEffect, useRef } from "react";

import { Button, ButtonGroup, Flex, TextField, View, TextAreaField } from '@aws-amplify/ui-react';

import {
    BiSearchAlt,
} from "react-icons/bi";

import * as FetchDataUtils from '../lib/fetchDataUtils';


function SearchDocuments({
    param_selectedClassesAndDocuments,
    param_fnSelectClassesAndDocuments,
    param_selectedCount,
    // param_fnSetSelectionModeFlag
    param_fnSetSearchText,
}) {


    const inputSearchTextRef = useRef();
    const [inputSearchText, setInputSearchText] = useState("");

    const inputVectorTextRef = useRef();
    const [inputVectorText, setInputVectorText] = useState("");

    const [previousSearchResults, setPreviousSearchResults] = useState([]);
    // oneSearchResult = {
    //   timestamp: Date.now(),
    //   input: {"className": 
    //      ["docId","docId2"]
    //    },
    //   output: {"className": 
    //      {"docId": {"sentences": [3,5,14]}}
    //    },
    // };





    // ----------- EXECUTE SEARCH
    //   copied from  QueryAi.js executeInstructions() line 103
    //
    async function executeSearch(_selectedClassesAndDocuments) {
        console.log("_selectedClassesAndDocuments:", _selectedClassesAndDocuments);
        // setBtnDisabled(true);

        const searchText = inputSearchTextRef.current.value;
        const vectorText = inputVectorTextRef.current.value;

        // setShowSearchingSpinnerFlag(true);

        let responseObj = {};
        try {
            let bodyObj = {
                selectedClassesAndDocuments: _selectedClassesAndDocuments,
                searchText: searchText,
                vectorText: vectorText,
            };
            responseObj = await FetchDataUtils.fetchData("search", "POST", bodyObj);
            // const responseObj = {
            //   foundClassesAndDocuments: selectedClassesAndDocuments 
            //                              "class"."docId"["lineId"],
            // };
            console.log("executeSearch: responseObj:", responseObj);

        } catch (httpError) {
            console.log("executeSearcht: fetchData: http error:", httpError);
            responseObj.error = {
                message: httpError.toString(),
            };
        }

        const _searchResult = {
            timestamp: Date.now(),
            searchText: searchText,
            inputSelection: _selectedClassesAndDocuments,
            outputSelection: responseObj,
        };

        const _updatedPreviousSearchResults = [_searchResult, ...previousSearchResults];
        setPreviousSearchResults(_updatedPreviousSearchResults);

        // setShowSearchingSpinnerFlag(false);
        // setSearchBtnDisabled(false);
        inputSearchTextRef.current.focus();

        param_fnSetSearchText(searchText);
    }





    return (
        <Flex
            direction="column"
            gap="0.5rem"
            margin="1rem"
        >
            <Flex
                direction="row"
                alignItems="center"
                alignContent="flex-start"
                wrap="nowrap"
                gap="0.5rem"
            >
                <BiSearchAlt fontSize="1.5rem" />

                <Flex
                    direction="column"
                    gap="0.5rem"
                    margin="0.5rem"
                >
                    <View>
                        Keywords Search:
                        <TextField
                            ref={inputSearchTextRef}
                            labelHidden
                            width="30rem"
                            value={inputSearchText}
                            onChange={(e) => setInputSearchText(e.currentTarget.value)}
                        />
                    </View>

                    {window.location.href.indexOf("localhost") > -1 &&
                        <View>
                            Vector Search:
                            <TextAreaField
                                ref={inputVectorTextRef}
                                rows="5"
                                value={inputVectorText}
                                resize="vertical"
                                onChange={(e) => setInputVectorText(e.currentTarget.value)}
                            />
                        </View>
                    }

                </Flex>
                <Button
                    isDisabled={param_selectedCount.documents === 0 || (!inputSearchText && !inputVectorText)}
                    onClick={() => { executeSearch(param_selectedClassesAndDocuments) }}
                >
                    Search {param_selectedCount.documents} Selected Documents
                </Button>
                <Button
                    isDisabled={!inputSearchText && !inputVectorText}
                    onClick={() => {
                        // console.log("inputSearchText:", inputSearchText, "inputVectorText:", inputVectorText);
                        executeSearch({});
                    }}
                >
                    Search All Documents
                </Button>
            </Flex>


            <Flex
                direction="column"
            >
                {
                    previousSearchResults.map((oneSearchResult, idx) => {
                        // console.log("oneSearchResult: ", oneSearchResult);
                        // oneSearchResult = {
                        //   timestamp: Date.now(),
                        //    searchText: "sss"
                        //   inputSelection: {"className": 
                        //      ["docId","docId2"]
                        //    },
                        //   outputSelection: {"className": 
                        //      {"docId": {"sentences": [3,5,14]}}
                        //    },
                        // };

                        const foundClassesNumber = Object.keys(oneSearchResult.outputSelection).length;
                        let foundDocumentsNumber = 0;
                        Object.keys(oneSearchResult.outputSelection).map((className, idxClass) => {
                            const selectedDocs = oneSearchResult.outputSelection[className];
                            foundDocumentsNumber += Object.keys(selectedDocs).length;
                        });

                        return (

                            <ButtonGroup
                                key={idx}
                                direction="column"
                                padding="1rem"
                                maxWidth="40rem"
                                border="2px solid lightgrey"
                            >

                                <Flex
                                    direction="row"
                                >
                                    <View>
                                        <View as="span" fontWeight="bold">Searched Text:</View> "{oneSearchResult.searchText}"
                                    </View>
                                    <Button
                                        // variation="primary"
                                        marginLeft="auto"
                                        size="small"
                                        isDisabled={false}
                                        onClick={() => {
                                            // oneSearchResult
                                            // setSelectionModeFlag(true);
                                            console.log("Main.js: Show searched  oneSearchResult.inputSelection:", oneSearchResult.inputSelection);
                                            param_fnSelectClassesAndDocuments(oneSearchResult.inputSelection);
                                        }}
                                    >
                                        Show Searched Documents
                                    </Button>
                                </Flex>

                                <Flex
                                    direction="row"
                                >
                                    {foundDocumentsNumber > 0 &&
                                        <View>
                                            Found <View as="span">{foundDocumentsNumber}</View> Documents in <View as="span">{foundClassesNumber}</View> Classes
                                        </View>
                                    }
                                    {foundDocumentsNumber === 0 &&
                                        <View>
                                            No Search Results
                                        </View>
                                    }
                                    <Button
                                        // variation="primary"
                                        marginLeft="auto"
                                        size="small"
                                        isDisabled={false}
                                        onClick={() => {
                                            // oneSearchResult
                                            // setSelectionModeFlag(true);
                                            console.log("Main.js: Show Found Documents: oneSearchResult.outputSelection:", oneSearchResult.outputSelection);
                                            param_fnSelectClassesAndDocuments(oneSearchResult.outputSelection);
                                        }}
                                    >
                                        Show Found Documents
                                    </Button>
                                </Flex>

                            </ButtonGroup>
                        );
                    })
                }
            </Flex>

        </Flex>
    );

}

export default SearchDocuments;