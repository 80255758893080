
  
  export function isString(variable) {
    return typeof variable === 'string' || variable instanceof String;
  }
  
  
  export function isoTimeToLocalYearMonthDayTime(isoTimeStr) {

    let result = isoTimeStr;
    try{
      let date = new Date(isoTimeStr);
      const localDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
      const dateTimeSplitArray = localDate.toISOString().split("T");
      const yearMonthDay = dateTimeSplitArray[0];
      const hoursMinutesSeconds = dateTimeSplitArray[1].split(".")[0];
      
      result = yearMonthDay + " " + hoursMinutesSeconds;
    }catch(e){
      console.log(e);
    }
    return result;
  }
  
  export function isoTimeToFormattedDateTime(isoTimeStr) {

    let ts = new Date(isoTimeStr).getTime();
    return timestampToFormattedDateTime(ts);
  }


  export function timestampToFormattedDateTime(ts){
    let _formattedTimestamp = "-";
    
    const timeFormatOptions = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: true,
      timeZone: 'America/Chicago'
    };
    try{
      const d = new Date( parseInt(ts) );
      _formattedTimestamp = new Intl.DateTimeFormat('en-US', timeFormatOptions).format(d);
    }catch(ignore){};

    return _formattedTimestamp;
  }


  export function dateTimeStringToFormattedDate(ts){
    let _formatted = "-";
    
    const timeFormatOptions = {
      year: 'numeric', month: 'numeric', day: 'numeric',
    };
    try{
      const d = Date.parse(ts);
      _formatted = new Intl.DateTimeFormat('en-US', timeFormatOptions).format(d);
    }catch(ignore){};

    return _formatted;
  }



  
  
  //usage
  // import * as Utils from '../lib/utils';
  //
  // const targetElement = targetNoteElement.querySelector('textarea');
  //   Utils.blinkTargetElement(targetElement);
  //
  export function blinkTargetElement(targetElement){
    const originalBorderWidth = targetElement.style.borderWidth;
    const originalBorderColor = targetElement.style.borderColor;
    const originalBackgroundColor = targetElement.style.backgroundColor;
    const timer1 = setInterval(() => {
      if(targetElement.style.borderWidth === originalBorderWidth) {
        targetElement.style.borderWidth = '3px';
        targetElement.style.borderColor = 'orange';
        targetElement.style.backgroundColor = 'orange';
      }else{
        targetElement.style.borderWidth = originalBorderWidth;
        targetElement.style.borderColor = originalBorderColor;
        targetElement.style.backgroundColor = originalBackgroundColor;
      }
    }, 50);
    setTimeout(() => {
      clearInterval(timer1);
      targetElement.style.borderWidth = originalBorderWidth;
      targetElement.style.borderColor = originalBorderColor;
      targetElement.style.backgroundColor = originalBackgroundColor;
    }, 1000);
  }





  export function safeJSONParse(jsonString, defaultObject){
    let ret = null;
    try{
      ret = JSON.parse(jsonString);
    }catch(e){
      // reportError(e);
      // console.log("ERROR: ", e)
    }
    if(ret === null){
      ret = defaultObject;
    }
    // console.log("returning: ", ret);
    return ret;
  }


  //
  //  Copy Text to Clipboard
  //
  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }
  
    document.body.removeChild(textArea);
  }
  export function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function() {
        console.log("Async: Copying to clipboard was successful!");
      },
      function(err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }
  