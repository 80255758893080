// QueryAi.js

import { useState, useRef, useEffect } from "react";

import {
    View, Flex,
    TextAreaField, Button,
    SelectField,
} from '@aws-amplify/ui-react';

import * as FetchDataUtils from '../lib/fetchDataUtils';
import * as Utils from '../lib/utils';


import {
    BiCaretRight,
    BiCaretDown,
    BiLinkExternal,
    BiSearchAlt2,
} from "react-icons/bi";

import '../components/transcript.css';
import ExecutionItem from "./../command/ExecutionItem";



function QueryAi({
    param_defaultQuery,
    // param_selectionModeFlag,
    param_selections,
    param_FnExitQueryAi,
}) {




    const inputTextRef = useRef();
    const selectModelRef = useRef();

    const [inputText, setInputText] = useState('');
    const [inputTextTokens, setInputTextTokens] = useState(0);


    const [previousExecutions, setPreviousExecutions] = useState([]);

    const [previousInput, setPreviousInput] = useState('');

    const [btnDisabled, setBtnDisabled] = useState(false);

    const [showLoadingSpinnerFlag, setShowLoadingSpinnerFlag] = useState(false);
    // const [showingEnterDocumentFlag, setShowingEnterDocumentFlag] = useState(false);
    // const [showingIncludeDocumentsFlag, setShowingIncludeDocumentsFlag] = useState(false);


    // used for  <SelectedDocumentsTags />                                                  
    // const [selectionModeFlag, setSelectionModeFlag] = useState(false);
    // const [availableTags, setAvailableTags] = useState({});
    const [selectedClassesAndDocuments, setSelectedClassesAndDocuments] = useState({});

    // const [showOnlySelectedDocumentsFlag, setShowOnlySelectedDocumentsFlag] = useState(true);
    
    const defaultQueryRef = useRef("Extract facts about ...");



    let init = useRef(true);
    useEffect(() => {
        if (!init.current) return;
        init.current = false;

        setInputText(defaultQueryRef.current);
        inputTextRef.current.focus();
    }, []);


    
    useEffect(() => {
        if (param_defaultQuery == null) return;

        if (param_defaultQuery !== defaultQueryRef.current){
            defaultQueryRef.current = param_defaultQuery;

            setInputText(defaultQueryRef.current);
        }
    }, [param_defaultQuery]);


    useEffect(() => {
        if (param_selections == null) return;

        setSelectedClassesAndDocuments(param_selections);

    }, [param_selections]);



    



    function countWords(s) {
        s = s.replace(/(^\s*)|(\s*$)/gi, "");//exclude  start and end white-space
        s = s.replace(/[ ]{2,}/gi, " ");//2 or more space to 1
        s = s.replace(/\n /, "\n"); // exclude newline with a start spacing
        return s.split(' ').filter(function (str) { return str != ""; }).length;
        //return s.split(' ').filter(String).length; - this can also be used
    }

    function onChangeInputText(content) {

        setInputText(content);
        // setInputTextTokens( Math.ceil(content.replace(/\s+/g, '').length * 0.37) );
        setInputTextTokens(Math.ceil(countWords(content) * 1.25));
    }





    // ----------- EXECUTE INSTRUCTIONS
    async function executeInstructions() {
        setBtnDisabled(true);

        const sendingContent = inputTextRef.current.value;

        // inputTextRef.current.value = "";
        setPreviousInput(sendingContent);
        setShowLoadingSpinnerFlag(true);


        let responseObj = {};
        try {
            let bodyObj = {
                selectedClassesAndDocuments: selectedClassesAndDocuments,
                model: selectModelRef?.current?.value,
                content: sendingContent,
            };
            // console.log("QueryAi.js: executeInstructions(): bodyObj:", bodyObj);

            responseObj = await FetchDataUtils.fetchData("processGpt", "POST", bodyObj, window.isEncrypted);
            // console.log("Command.js: executePrompt: fetchData: fetchData:", responseObj);

            // const responseObj = {
            //   model: completion.data.model,
            //   usage: completion.data.usage,
            //              completion_tokens,
            //              prompt_tokens,
            //              total_tokens,
            //   content: returnContent,
            // };
            // console.log("Command.js: executePrompt: retObj.model:", responseObj.model, " retObj.usage:", responseObj.usage);

        } catch (httpError) {
            console.log("Command.js: executePrompt: fetchData: http error:", httpError);
            responseObj.error = {
                message: httpError.toString(),
            };
        }


        let _content = "-";
        const executionData = {
            timestamp: Date.now(),
            input: sendingContent,
            // output: _content,
        };

        if (responseObj == null || !!responseObj?.error) {
            // ERROR
            try {
                executionData.model = responseObj.model;
                executionData.output = JSON.stringify(responseObj.error, null, 4);

            } catch (ignore) { // generic ERROR
                executionData.model = "error";
                executionData.output = "Error";
            }

        } else {
            _content = responseObj.content;
            if (Utils.isString(_content)) {
                _content = JSON.stringify(responseObj.content);
            }
            executionData.model = responseObj.model;
            executionData.usage = responseObj.usage;
            executionData.output = _content;
        }



        previousExecutions.unshift(executionData) // reverses the order by adding at the first position
        setPreviousExecutions(previousExecutions);

        setShowLoadingSpinnerFlag(false);
        setBtnDisabled(false);
        inputTextRef.current.focus();
    }






    return (
        <Flex
            direction="column"
            marginBottom="2rem"
        >
            <View as="div"
                margin="1rem 0 0 1rem"
                fontSize="1.4rem"
                fontWeight={'bold'}
                className={'cls-background-red-dim cls-show-hide-switch cls-text-center cls-align-self-start'}
                minWidth="15rem"
                onClick={() => param_FnExitQueryAi()}
            >
                {/* <tbody><tr>
                  <td> */}
                <BiCaretDown />
                Exit Ask Ai
                {/* </td>
              </tr></tbody> */}
            </View>

            <Flex
                marginTop="2rem"
                direction="row"
                alignItems={"flex-end"}
            >
                <View
                    // style={{whiteSpace: 'pre-wrap'}}
                    fontSize="1.5rem"
                    fontWeight="bold"
                >
                    Ask Ai:
                </View>
                <View
                    marginLeft="5rem"
                >
                    {inputTextTokens} tkns
                </View>
                {/* <SelectField
                    ref={selectModelRef}
                    maxWidth="20rem"
                    // label="Model"
                    placeholder="Model: Automatic Size Fit"
                >
                    <option value='gpt-3.5-turbo'>gpt-3.5-turbo</option>
                    <option value='gpt-4o'>gpt-4o</option>

                </SelectField> */}
            </Flex>

            <TextAreaField
                ref={inputTextRef}
                rows="5"
                value={inputText}
                resize="vertical"
                onChange={(e) => onChangeInputText(e.currentTarget.value)}
            />

            <Flex
                direction="row"
                alignItems={"flex-end"}
            >
                <Button
                    variation="warning"
                    isDisabled={btnDisabled}
                    onClick={() => {
                        inputTextRef.current.value = "";
                        inputTextRef.current.focus();
                    }}
                    maxWidth="10rem"
                >
                    Clear
                </Button>

                <Button
                    variation="primary"
                    isDisabled={btnDisabled}
                    onClick={() => executeInstructions()}
                    width="30rem"
                >
                    Execute Instructions
                </Button>
                {!!showLoadingSpinnerFlag &&
                    <img height="20px" src="load.gif" className="loading" />
                }
            </Flex>




            {  //  TODO: use formatting from SearchResultItem.js
                previousExecutions.map((oneExecution, idx) => {

                    // console.log("oneExecution: ", oneExecution);
                    return (
                        <div key={idx}>
                            <ExecutionItem
                                param_oneItem={oneExecution}
                            />

                        </div>
                    );
                })
            }



        </Flex>
    );
}

export default QueryAi;
