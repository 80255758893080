import React from 'react';
import ReactDOM from 'react-dom/client';
import { Buffer } from "buffer/";

import './index.css';
import Main from './components/Main';
import Command from "./command/Command";

import { Amplify } from 'aws-amplify';
import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';

Amplify.configure(awsExports);
Amplify.addPluggable(new AmazonAIPredictionsProvider());

// import reportWebVitals from './reportWebVitals';

window.Buffer = Buffer;

const root = ReactDOM.createRoot(document.getElementById('root'));


if(window.location.hash.indexOf("idx") > -1){
  root.render(
    <React.StrictMode>
  
      <Command />
  
    </React.StrictMode>
  );
} else {
  root.render(
    <React.StrictMode>

      <Main />

    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

