// command/Command.js

import { useState, useRef, useEffect } from "react";

import { 
  withAuthenticator, View, Flex, 
  TextAreaField, Button,
  SelectField,
} from '@aws-amplify/ui-react';

import * as FetchDataUtils from '../lib/fetchDataUtils';
import * as LoggingUtils from '../lib/loggingUtils';

import * as Utils from '../lib/utils';

import EnterDocument from "../components/EnterDocument";

import { BiCaretRight, 
  BiCaretDown,
  BiLinkExternal,
  BiSearchAlt2,
           } from "react-icons/bi";
import { BsFillCheckSquareFill } from "react-icons/bs";

import '../components/transcript.css';
import ExecutionItem from "./ExecutionItem";
import SelectedDocumentsTags from "../components/SelectedDocumentsTags";
import ListClasses from "../components/ListClasses";



function Command({ signOut, user }) {




  const inputTextRef = useRef();
  const selectModelRef = useRef();

  const [inputText, setInputText] = useState('');
  const [inputTextTokens, setInputTextTokens] = useState(0);

   
  const [previousExecutions, setPreviousExecutions] = useState([]);

  const [previousInput, setPreviousInput] = useState('');

  const [btnDisabled, setBtnDisabled] = useState(false);

  const [showLoadingSpinnerFlag, setShowLoadingSpinnerFlag] = useState(false);
  const [showingEnterDocumentFlag, setShowingEnterDocumentFlag] = useState(false);
  const [showingIncludeDocumentsFlag, setShowingIncludeDocumentsFlag] = useState(false);
  
  const [availableClassesList, setAvailableClassesList] = useState([]);

  const [classesDropdownOptions, setClassesDropdownOptions] = useState([]);
  const [selectedDropdownClass, setSelectedDropdownClass] = useState('');

  // used for  <SelectedDocumentsTags />
  const [selectionModeFlag, setSelectionModeFlag] = useState(false);
  const [availableTags, setAvailableTags] = useState({});
  const [selectedClassesAndDocuments, setSelectedClassesAndDocuments] = useState({});

  const [showOnlySelectedDocumentsFlag, setShowOnlySelectedDocumentsFlag] = useState(true);




  let init = useRef(true);
  useEffect(() => {
    if (!init.current) return;
    init.current = false;

    // getClassesDropdownOptions();

    inputTextRef.current.focus();
    

    // load Tags for selecting Documents
    async function serialExe(){

      let _availableClassesList = await FetchDataUtils.getData("captures/class-names");
      if('error' in _availableClassesList){
        LoggingUtils.log(_availableClassesList);
        _availableClassesList = null;
      }
      
      if(!!_availableClassesList?.length && _availableClassesList.length > 0){
        _availableClassesList.sort();
        setAvailableClassesList(_availableClassesList);
      }
      setClassesDropdownOptions( ["", ..._availableClassesList] );


      const _availableTagsRecords = await FetchDataUtils.getData("documentSelections");
      if('error' in _availableTagsRecords){
        LoggingUtils.log(_availableTagsRecords);
        _availableTagsRecords = null;
      }
      // console.log("Main.js: useEffect: init: FetchDataUtils documentSelections: _availableTagsRecords:", _availableTagsRecords);
      if(!!_availableTagsRecords?.length && _availableTagsRecords.length > 0){
        let tags = _availableTagsRecords[_availableTagsRecords.length - 1]?.tags;
        if (Utils.isString(tags)){
          tags = JSON.parse(tags);
        }
        setAvailableTags(tags);
      }
    }
    serialExe();


  }, []);



  function countWords(s){
      s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
      s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
      s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
      return s.split(' ').filter(function(str){return str!="";}).length;
      //return s.split(' ').filter(String).length; - this can also be used
  }

  function onChangeInputText(content){

    setInputText( content );
    // setInputTextTokens( Math.ceil(content.replace(/\s+/g, '').length * 0.37) );
    setInputTextTokens( Math.ceil(countWords(content)*1.25));
  }





  // ----------- EXECUTE INSTRUCTIONS
  async function executeInstructions(){
    setBtnDisabled(true);

    const sendingContent = inputTextRef.current.value;

    // inputTextRef.current.value = "";
    setPreviousInput(sendingContent);
    setShowLoadingSpinnerFlag(true);
   

    let responseObj = {};
    try{
      let bodyObj = {
        selectedClassesAndDocuments: selectedClassesAndDocuments,
        includeDocumentsFromClasses: [selectedDropdownClass],
        model: selectModelRef.current.value,
        content: sendingContent,
      };
      responseObj = await FetchDataUtils.fetchData( "processGpt", "POST", bodyObj, window.isEncrypted);
      // console.log("Command.js: executePrompt: fetchData: fetchData:", responseObj);

      // const responseObj = {
      //   model: completion.data.model,
      //   usage: completion.data.usage,
      //              completion_tokens,
      //              prompt_tokens,
      //              total_tokens,
      //   content: returnContent,
      // };
      // console.log("Command.js: executePrompt: retObj.model:", responseObj.model, " retObj.usage:", responseObj.usage);

    }catch(httpError){
      console.log("Command.js: executePrompt: fetchData: http error:", httpError);
      responseObj.error = {
        message: httpError.toString(),
      };
    }


    let _content = "-";
    const executionData = {
      timestamp: Date.now(),
      includeDocumentsFromClasses: [selectedDropdownClass],
      input: sendingContent,
      // output: _content,
    };
    
    if(!responseObj?.error){

      _content = responseObj.content;
      if (Utils.isString(_content)){
        _content = JSON.stringify(responseObj.content);
      }
      executionData.model = responseObj.model;
      executionData.usage = responseObj.usage;
      executionData.output = _content;
    } else { 

      // ERROR
      executionData.model = responseObj.model;
      executionData.output = JSON.stringify(responseObj.error, null, 4);
    }

    previousExecutions.unshift(executionData) // reverses the order by adding at the first position
    setPreviousExecutions(previousExecutions);
      
    setShowLoadingSpinnerFlag(false);
    setBtnDisabled(false);
    inputTextRef.current.focus();
  }






  return (
    <Flex
      direction="column"
      // margin="1rem"
    >
    



    {/* ENTER DOCUMENT */}
      <Button 
        maxWidth="20rem"
        variation={ !showingEnterDocumentFlag ? "default" : "primary" }
        onClick={() => {setShowingEnterDocumentFlag(!showingEnterDocumentFlag)}}
        marginLeft="1rem"
        fontSize="1.2rem"
        // isDisabled={ (minutesLeft < 5) }
      >
        {!showingEnterDocumentFlag && 
          <BiCaretRight />
        }
        {!!showingEnterDocumentFlag && 
          <BiCaretDown />
        }
        Insert a Text Document
      </Button> 
      
      {!!showingEnterDocumentFlag && 
        <View
          as="div"
          borderRadius="6px"
          border="1px solid var(--amplify-colors-red)"
          boxShadow="3px 3px 5px 6px var(--amplify-colors-orange-60)"
          maxWidth="100%"
          margin="2% 5% 5% 5%"
          padding="1% 3%"
          width="90%"
        >
            <EnterDocument 
              param_encryptFlag={true}
              // param_availableClassesList={availableClassesList}
              // param_FnOnStartCapturing={onStartCapturing}
              param_FnOnEndCapturing={() => {
                setShowingEnterDocumentFlag(false);
              }}
            />
        </View> 
      }




      <Flex
        direction="column"
        // margin="1rem"
        width="99%"
      >
          
              {/* INCLUDE TAGGED DOCUMENTS in Prompt */}
              <Button 
                maxWidth="20rem"
                variation={ !selectionModeFlag ? "default" : "primary" }
                onClick={() => {
                  setSelectionModeFlag(!selectionModeFlag);
                  // window.varShowQAiButtonsFlag = selectionModeFlag;
                }}
                margin="1rem 0 0 1rem"
                fontSize="1.4rem"
                // isDisabled={ (minutesLeft < 5) }
              >
                {!selectionModeFlag && 
                  <BiCaretRight />
                }
                {!!selectionModeFlag && 
                  <BiCaretDown />
                }
                Ask Ai in Documents
              </Button> 



              <View as="table"
                  margin="1rem 0 0 1rem"
                  fontSize="1.4rem"
                  fontWeight={`${!selectionModeFlag? 'normal':'bold'}`}
                  className={`${!selectionModeFlag? 'cls-background-greenyellow-dim':'cls-background-greenyellow'} cls-show-hide-switch cls-text-center cls-align-self-start`}
                  minWidth="4rem"
                  onClick={() => {
                    setSelectionModeFlag(!selectionModeFlag);
                    // window.varShowQAiButtonsFlag = selectionModeFlag;
                  }}
                >
                  {!selectionModeFlag && 
                    <BiCaretRight />
                  }
                  {!!selectionModeFlag && 
                    <BiCaretDown />
                  }
                  Ask Ai in Documents
                </View>



            
              <SelectedDocumentsTags
                  param_selectionModeFlag={selectionModeFlag}
                  param_availableTags={availableTags}
                  param_selectedClassesAndDocuments={selectedClassesAndDocuments}
                  param_fnOnChangedSelectedClassesAndDocuments={(_selectedClassesAndDocuments) => {
                    // console.log("Main.js: fnOnChangedSelections: (coming from SelectedDocumentsTags.js) changed selectedClassesAndDocuments:", _selectedClassesAndDocuments);
                    setSelectedClassesAndDocuments(_selectedClassesAndDocuments);
                  }}
              />

              {!!selectionModeFlag && 
              <>
                  <Flex
                    direction="row"
                    // marginLeft="auto"
                    marginTop="1rem"
                    // justifyContent="flex-start"
                    // alignItems="flex-end"
                  >
                      <Button 
                        height="2rem"
                        variation={showOnlySelectedDocumentsFlag ? "primary" : "default"}
                        marginLeft="auto"
                        onClick={() => {
                          setShowOnlySelectedDocumentsFlag( true );
                        }}
                      >
                        Filter{showOnlySelectedDocumentsFlag ? "ed" : ""} by
                        <View
                          marginLeft="0.5rem"
                        >
                          <BsFillCheckSquareFill />
                        </View>
                        
                      </Button> 
                      <Button 
                        height="2rem"
                        variation={showOnlySelectedDocumentsFlag ? "default" : "primary"}
                        onClick={() => {
                          setShowOnlySelectedDocumentsFlag( false );
                        }}
                      >
                        Show All
                      </Button> 
                  </Flex>

                  <Flex
                    direction="column"
                    gap="0px"
                    width="99%"
                  >
                      <ListClasses
                          param_selectionModeFlag={true}
                          param_selections={selectedClassesAndDocuments}
                          param_showOnlySelectedDocumentsFlag={showOnlySelectedDocumentsFlag}

                          param_fnOnChangedSelections={(_selections) => {
                            console.log("Command.js: fnOnChangedSelections: (coming from ListClasses.js) changed selectedClassesAndDocuments:", _selections);
                            setSelectedClassesAndDocuments(_selections);
                          }}

                          param_availableClassesList={availableClassesList}
                          // param_DocumentToInsert={documentToInsert}
                          // param_DocumentToUpdate={documentToUpdate}
                          // param_DocumentToDelete={documentToDelete}
                      />
                  </Flex>

              </>
              }



      </Flex>

      




      <Flex
        marginTop="2rem"
        direction="row"
        alignItems={"flex-end"}
      >
        <View 
          // style={{whiteSpace: 'pre-wrap'}}
          fontSize="1.5rem"
          fontWeight="bold"
        >
          Instructions:
        </View>      
        <View 
          marginLeft="5rem"
        >
          {inputTextTokens} tkns
        </View>
        <SelectField
          ref={selectModelRef}
          maxWidth="20rem"
          // label="Model"
          placeholder="Model: Automatic Size Fit"
        >
            <option value='gpt-3.5-turbo'>gpt-3.5-turbo</option>
            {/* <option value='gpt-3.5-turbo-16k'>gpt-3.5-turbo-16k</option> */}
            {/* <option value='gpt-3.5-turbo-1106'>gpt-3.5-turbo-1106</option> */}
            {/* <option value='gpt-4'>gpt-4</option> */}
            <option value='gpt-4-turbo-preview'>gpt-4-turbo-preview</option>
        </SelectField>
      </Flex>

      <TextAreaField 
        ref={inputTextRef}
        rows="5"
        value={inputText}
        resize="vertical"
        onChange={(e) => onChangeInputText( e.currentTarget.value ) }
      />

      <Flex
        direction="row"
        alignItems={"flex-end"}
      >
        <Button
          variation="warning"
          isDisabled={btnDisabled}
          onClick={() => {
            inputTextRef.current.value = "";
            inputTextRef.current.focus();
          }}
          maxWidth="10rem"
        >
          Clear
        </Button>

        <Button
          variation="primary"
          isDisabled={btnDisabled}
          onClick={() => executeInstructions()}
          width="30rem"
        >
          Execute Instructions
        </Button>
        {!!showLoadingSpinnerFlag &&
          <img height="20px" src="load.gif" className="loading" />
        }
      </Flex>



      
      {  //  TODO: use formatting from SearchResultItem.js
        previousExecutions.map((oneExecution, idx) => {
  
          // console.log("oneExecution: ", oneExecution);
          return (
            <div key={idx}>
              <ExecutionItem
                param_oneItem={oneExecution}
              />
              
            </div>
          );
        })
      }



    </Flex>
  );
}

export default withAuthenticator(Command);
