// components/ListLectures.js
import { useState, useEffect, useRef } from "react";

// import { Flex, withAuthenticator } from '@aws-amplify/ui-react';
// import { 
//         Menu, MenuItem, MenuButton,
//         View, Button, 
//         // Theme,
//         Heading, 
//         Link,
//       } from '@aws-amplify/ui-react';
// import { Auth } from 'aws-amplify';

// import { useBeforeunload } from 'react-beforeunload';

// import { deletePrivateLectures } from '../graphql/mutations';
// import * as DataUtils from '../lib/graphqlDataUtils';

// import CaptureLecture from "./CaptureLecture";

// import { BiCaretRight, 
//   BiCaretDown,
//   BiLinkExternal,
//   BiSearchAlt2,
//            } from "react-icons/bi";
// import { MdOutlineClose } from "react-icons/md";

import ClassExpanderContainer from "./ClassExpanderContainer";

import './listLectures.css';
// import OneCaptureExpanderContainer from "./CaptureExpanderContainer";

// import * as Utils from '../lib/utils';

import * as FetchDataUtils from '../lib/fetchDataUtils';
import * as LoggingUtils from '../lib/loggingUtils';

// import ListDocuments from "./ListDocuments";
// import CaptureExpanderContainerList from "./CaptureExpanderContainersList";




function ListClasses({ 
  param_selectionModeFlag,
  param_selections,
  param_showOnlySelectedDocumentsFlag,

  param_searchText,

  param_fnOnChangedSelections,

  param_availableClassesList,
  param_DocumentToInsert,
  param_DocumentToUpdate,
  param_DocumentToDelete,
 }) {

  // const [classes, setClasses] = useState([]);
  const [sortedClassesWithDocumentsInfo, setSortedClassesWithDocumentsInfo] = useState([]);

  const [selectionModeFlag, setSelectionModeFlag] = useState(false);
  const [selections, setSelections] = useState({});
  const [selectionsData, setSelectionsData] = useState({});
  const [showOnlySelectedDocumentsFlag, setShowOnlySelectedDocumentsFlag] = useState(false);



  const init = useRef(true);
  useEffect(() => {
    if (!init.current){
      return
    }
    init.current = false;
    async function serialExe(){
      const classesWithDocumentsInfo = await getClasses();
      const _sortedClassesWithDocumentsInfo = sortClassesWithDocumentsInfo(classesWithDocumentsInfo);
      setSortedClassesWithDocumentsInfo(_sortedClassesWithDocumentsInfo);
    }
    serialExe();
  }, []);



  useEffect(() => {
    if (sortedClassesWithDocumentsInfo == null){
      return;
    }
    window.varSortedClassesWithDocumentsInfo = sortedClassesWithDocumentsInfo;
    // console.log("window.varSortedClassesWithDocumentsInfo:", window.varSortedClassesWithDocumentsInfo);
  }, [sortedClassesWithDocumentsInfo]);


  useEffect(() => {
    if (param_selectionModeFlag == null || param_selectionModeFlag == selectionModeFlag){
      return;
    }

    if(param_selectionModeFlag){ // enabling selection -> reset filter to show NOT only selected
      setShowOnlySelectedDocumentsFlag(false);
    }

    setSelectionModeFlag(param_selectionModeFlag);
    
  }, [param_selectionModeFlag]);



  useEffect(() => {
    if (param_selections == null) return;

    const _selections = {};
    const _selectionsData = {};

    Object.keys(param_selections).map((className, idxClass) => {
      const selectedDocs = param_selections[className];

      if (Array.isArray(selectedDocs)){
        _selections[className] = selectedDocs;
      } else {
        _selections[className] = [];
        Object.keys(selectedDocs).map((docId, idx) => {
          _selections[className].push(docId);
        });

        _selectionsData[className] = selectedDocs;
      }
    });

    // console.log("ListClasses: converting param_selections:", param_selections, _selections);

    setSelections(_selections);
    setSelectionsData(_selectionsData);

  }, [param_selections]);


  useEffect(() => {
    // console.log("ListClasses: selections:", selections);

    // sort refresh on changed selections in any way
    const _sortedClassesWithDocumentsInfo = sortOnlyClasses(sortedClassesWithDocumentsInfo);
    setSortedClassesWithDocumentsInfo([..._sortedClassesWithDocumentsInfo]);
  }, [selections]);


  useEffect(() => {
    if(param_showOnlySelectedDocumentsFlag == null) return;

    setShowOnlySelectedDocumentsFlag(param_showOnlySelectedDocumentsFlag);
  }, [param_showOnlySelectedDocumentsFlag]);

  

  // useEffect(() => {
  //   if (!!param_setSelections){

  //     // add to Selections
  //     const _allSelectedClassesAndDocuments = selections;
  //     const _selectedClassesAndDocuments = param_setSelections;
      
  //     Object.keys(_selectedClassesAndDocuments).map((className, idxClass) => {
  //       const selectedDocsIds = _selectedClassesAndDocuments[className];
  
  //       if (!(className in _allSelectedClassesAndDocuments) ||
  //           (_allSelectedClassesAndDocuments[className] == null)
  //       ){
  //         _allSelectedClassesAndDocuments[className] = [...selectedDocsIds];
  
  //       } else {
  //         let _allSelectedDocsIds = _allSelectedClassesAndDocuments[className];
  
  //         var merged = [...selectedDocsIds, ..._allSelectedDocsIds];
  //         const mergedSet = new Set(merged);
  //         _allSelectedClassesAndDocuments[className] = Array.from(mergedSet);
  //       }
  //     });

  //     setSelections({..._allSelectedClassesAndDocuments});
  //   }
  // }, [param_setSelections]);



  // useEffect(() => {
  //   if (!!param_unsetSelections){
  //     // subtract (remove) from Selections
  //     const _allSelectedClassesAndDocuments = selections;
  //     const _unselectedClassesAndDocuments = param_unsetSelections;
      
  //     Object.keys(_unselectedClassesAndDocuments).map((className, idxClass) => {
  //       const unselectedDocsIds = _unselectedClassesAndDocuments[className];
  
  //       if (!unselectedDocsIds?.length){ // [] or null or undefined
  //         delete _allSelectedClassesAndDocuments[className];
  //       } else {
  
  //         const _selectedClassObj = _allSelectedClassesAndDocuments[className];
  //         if (!!_selectedClassObj?.length){ // [xxx,yyy,...]
  
  //           unselectedDocsIds.map((docId, idx) => {
  //             while(true){
  //               const index = _selectedClassObj.indexOf(docId);
  //               if (index === -1) break;
  //               _selectedClassObj.splice(index, 1);
  //             }
  //           });
  //           // if removing actually de-selected all documents in Class
  //           //    then -> remove entire Class
  //           if (_selectedClassObj.length === 0){
  //             delete _allSelectedClassesAndDocuments[className];
  //           }
  //         }
  //       }
  //     });

  //     setSelections({..._allSelectedClassesAndDocuments});
  //   }
  // }, [param_unsetSelections]);



  function insertDocumentIntoClasses(param_DocumentToInsert){
    // console.log("inserting doc:", param_DocumentToInsert);
    // find matching class
    const docClassName = !param_DocumentToInsert?.class ? '' : param_DocumentToInsert.class;
    let matchingClass = sortedClassesWithDocumentsInfo.find((c) => c.className === docClassName);
    // new class
    if (typeof matchingClass === 'undefined'){
      matchingClass = {
        className: docClassName,
        items: [],
      };
      sortedClassesWithDocumentsInfo.push(matchingClass);
    }
    // insert document
    matchingClass.items.push(param_DocumentToInsert);
    // sort refresh
    const _sortedClassesWithDocumentsInfo = sortClassesWithDocumentsInfo(sortedClassesWithDocumentsInfo);
    setSortedClassesWithDocumentsInfo([..._sortedClassesWithDocumentsInfo]);
  }

  function updateDocumentInClasses(param_DocumentToUpdate){
    // console.log("updating doc:", param_DocumentToUpdate);    
    const retObj = findDocumentById(sortedClassesWithDocumentsInfo, param_DocumentToUpdate.id);
    const originalClass = retObj.originalClass;
    const originalClassName = retObj.originalClassName;
    const originalDocumentIndex = retObj.originalDocumentIndex;
    // merge original and the update
    const originalDocument = originalClass.items[originalDocumentIndex];
    const updatedDocument = Object.assign(originalDocument, param_DocumentToUpdate);
    // changed Class Name - remove from old class items array, and insert into new class items array
    if( !!param_DocumentToUpdate?.class && originalClassName !== param_DocumentToUpdate.class){
      originalClass.items.splice(originalDocumentIndex, 1);
      insertDocumentIntoClasses(updatedDocument);
    }
    // sort refresh
    const _sortedClassesWithDocumentsInfo = sortClassesWithDocumentsInfo(sortedClassesWithDocumentsInfo);
    setSortedClassesWithDocumentsInfo([..._sortedClassesWithDocumentsInfo]);
  }

  function deleteDocumentInClasses(param_DocumentToDelete){
    // console.log("deleting doc:", param_DocumentToDelete);
    const retObj = findDocumentById(sortedClassesWithDocumentsInfo, param_DocumentToDelete.id);
    const originalClass = retObj.originalClass;
    // const originalClassName = retObj.originalClassName;
    const originalDocumentIndex = retObj.originalDocumentIndex;
    // remove Document
    originalClass.items.splice(originalDocumentIndex, 1);
    // sort refresh
    const _sortedClassesWithDocumentsInfo = sortClassesWithDocumentsInfo(sortedClassesWithDocumentsInfo);
    setSortedClassesWithDocumentsInfo([..._sortedClassesWithDocumentsInfo]);
  }



  function findDocumentById(sortedClassesWithDocumentsInfo, documentId){
    // find the original document
    let originalDocument;
    let originalClass;
    let originalClassName;
    let originalDocumentIndex = -1;
    for(let i=0; i<sortedClassesWithDocumentsInfo.length; i++){
      const _class = sortedClassesWithDocumentsInfo[i];
      originalDocumentIndex = _class.items.findIndex((d) => d.id === documentId);
      if (originalDocumentIndex > -1) {
        originalClass = _class;
        originalDocument = _class.items[originalDocumentIndex];
        originalClassName = originalDocument.class;
        break;
      }
    }
    const retObj ={
      originalClass: originalClass,
      originalClassName: originalClassName,
      originalDocumentIndex: originalDocumentIndex,
    }
    return retObj;
  }





  const prevDocumentToInsertId = useRef(null);
  useEffect(() => {
    if (!param_DocumentToInsert){
      return;
    }
    if ( !!param_DocumentToInsert?.id && param_DocumentToInsert.id === prevDocumentToInsertId.current){
      return;
    }
    // console.log("inserting doc:", param_DocumentToInsert);
    insertDocumentIntoClasses(param_DocumentToInsert);
    prevDocumentToInsertId.current = param_DocumentToInsert.id;
  }, [param_DocumentToInsert]);


  const prevDocumentToUpdateId = useRef(null);
  useEffect(() => {
    if (!param_DocumentToUpdate){
      return;
    }
    if ( !!param_DocumentToUpdate?.id && param_DocumentToUpdate.id === prevDocumentToUpdateId.current){
      return;
    }
    // console.log("updating doc:", param_DocumentToUpdate);
    updateDocumentInClasses(param_DocumentToUpdate);
    prevDocumentToUpdateId.current = param_DocumentToUpdate.id;
  }, [param_DocumentToUpdate]);


  const prevDocumentToDeleteId = useRef(null);
  useEffect(() => {
    if (!param_DocumentToDelete){
      return;
    }
    if ( !!param_DocumentToDelete?.id && param_DocumentToDelete.id === prevDocumentToDeleteId.current){
      return;
    }
    // console.log("deleting doc:", param_DocumentToDelete);
    deleteDocumentInClasses(param_DocumentToDelete);
    prevDocumentToDeleteId.current = param_DocumentToDelete.id;
  }, [param_DocumentToDelete]);





  async function getClasses(){
    let classesWithDocumentsInfo = await FetchDataUtils.getData("captures/classes");
    if('error' in classesWithDocumentsInfo){
      LoggingUtils.log(classesWithDocumentsInfo);
      classesWithDocumentsInfo = null;
    }
    
    // console.log("ListClasses: ", classesWithDocumentsInfo);
    return classesWithDocumentsInfo;
  }


  function sortClassesWithDocumentsInfo(classesWithDocumentsInfo){
    // console.log(classesWithDocumentsInfo);
    // sort documents within each class
    classesWithDocumentsInfo.map((c) => {
      const sortedDocuments = c.items.sort((a,b) => b.timestamp - a.timestamp);
      c.items = sortedDocuments;
      if (!!c.items?.length){
        c.lastDocumentTimestamp = c.items[0].timestamp;
      } else {
        // console.log("ListClasses: class:", c);
        c.lastDocumentTimestamp = 0;
      }
    });
    const _sortedClassesWithDocumentsInfo = sortOnlyClasses(classesWithDocumentsInfo);
    // console.log("ListClasses: sortedClassesWithDocumentsInfo: ", _sortedClassesWithDocumentsInfo);
    return _sortedClassesWithDocumentsInfo;
  }

  function sortOnlyClasses(classesWithDocumentsInfo){
    // console.log("ListClasses: sortOnlyClasses: selectionModeFlag:", selectionModeFlag);
    const _sortedClassesWithDocumentsInfo = classesWithDocumentsInfo.sort((a,b) => {
        let itemOrderFlag = b.lastDocumentTimestamp - a.lastDocumentTimestamp;
        // if one of them in 'selections' it has advantage
        if(selectionModeFlag){
          // console.log("ListClasses: sortClassesWithDocumentsInfo: selectionModeFlag:", selectionModeFlag);
          if(b.className in selections && !(a.className in selections)){
            itemOrderFlag = 1;
          }
          if( !(b.className in selections) && a.className in selections){
            itemOrderFlag = -1;
          }
        }
        return itemOrderFlag;
    });
    return _sortedClassesWithDocumentsInfo;
  }
  

  function fnOnChangedSelections(className, selectionsInClass){
    const _selections = {...selections};
    if(selectionsInClass === null){ // null -> no selection
      delete _selections[className];
    }else{

      _selections[className] = selectionsInClass;
    }
    setSelections(_selections);

    param_fnOnChangedSelections(_selections);
    // console.log("ListClasses: fnOnChangedSelections: new selections:", _selections);
  }


  return (
    <>
          {sortedClassesWithDocumentsInfo.map((c, idx) => {
            // console.log(c);
            return (
              <ClassExpanderContainer 
                key={idx}  
                param_class={c}

                param_selectionModeFlag={selectionModeFlag}
                param_selectedDocumentsIds={selections[c.className]}
                param_selectedDocumentsData={selectionsData[c.className]}
                param_showOnlySelectedDocumentsFlag={showOnlySelectedDocumentsFlag}

                param_searchText={param_searchText}
                
                param_fnOnChangedSelections={(selectionsInClass) => {
                  fnOnChangedSelections(c.className, selectionsInClass);
                }}

                param_availableClassesList={param_availableClassesList}

                param_FnInsertDocument={insertDocumentIntoClasses}
                param_FnUpdateDocument={updateDocumentInClasses}
                param_FnDeleteDocument={deleteDocumentInClasses}
              />
            )
          })}
    </>
  );  

  
}

export default ListClasses;
