// components/OneCaptureExpanderContainer.js
import { useState, useRef, useEffect } from "react";

import { Flex, View,
  Divider,
  CheckboxField,
} from '@aws-amplify/ui-react';
import { BsArrowDown } from "react-icons/bs";


import { IconContext } from "react-icons";
import { 
  BiSearchAlt, 
  BiCaretDown,
  BiTrash } from "react-icons/bi";

import { 
  BsFillLockFill, 
  BsFillUnlockFill, 
  BsLightning, 
} from "react-icons/bs";


import IntegratedView from "./IntegratedView";

import AiSummarizer from './AiSummarizer';
import * as FetchDataUtils from '../lib/fetchDataUtils';
import * as LoggingUtils from '../lib/loggingUtils';
import * as Utils from '../lib/utils';
import * as StringUtils from '../lib/stringUtils';

function DocumentExpanderContainer({ 
  param_selectionModeFlag,
  param_isSelectedFlag,
  param_selectedDocumentData,

  param_showOnlySelectedDocumentsFlag,

  param_searchText,

  param_fnOnChildChangeSelectedFlag,

  param_availableClassesList,
  param_oneCapture,

  param_FnInsertDocument,
  param_FnUpdateDocument,
  param_FnDeleteDocument,
 }) {

  const [selectionModeFlag, setSelectionModeFlag] = useState(null);
  const [isSelectedFlag, setIsSelectedFlag] = useState(null);

  const [numberOfFoundSentences, setNumberOfFoundSentences] = useState(0.0);

  const [expandedFlag, setExpandedFlag] = useState(false);
  const [lockedEditFlag, setLockedEditFlag] = useState(true);
  const [showFindKeywords, setShowFindKeywords] = useState(false);


  const [oneCapture, setOneCapture] = useState(null);

  const [formattedTimestamp, setFormattedTimestamp] = useState("");
  const [captureClass, setCaptureClass] = useState("");
  const [title, setTitle] = useState("");
  const [capturedSeconds, setCapturedSeconds] = useState(0);

  const [currentProgressStatus, setCurrentProgressStatus] = useState("");


  const originalCaptureDB = useRef({});



  // let init = useRef(true);
  // useEffect(() => {
  //   if (init.current){
  //     init.current = false;
  //   }
  // }, []);





  // useEffect(() => {
  //   console.log("DocumentExpanderContainer: useEffect: none");

  // });


  useEffect(() => {
    // console.log("DocumentExpanderContainer: useEffect: param_oneCapture", param_oneCapture);
    if(!param_oneCapture) return;

    const _oneCapture = {...param_oneCapture};
    parseReceivedOneCaptureData(_oneCapture);

  }, [param_oneCapture]);


  useEffect(() => {
    if (param_selectionModeFlag != null && param_selectionModeFlag !== selectionModeFlag){
      setSelectionModeFlag(param_selectionModeFlag);
    }
  }, [param_selectionModeFlag]);
  

  useEffect(() => {    
    setIsSelectedFlag(param_isSelectedFlag);
  }, [param_isSelectedFlag]);
  

  useEffect(() => {
    // console.log("DocumentExpanderContainer: useEffect: param_selectedDocumentData", param_selectedDocumentData);
    if(!param_selectedDocumentData){
        setNumberOfFoundSentences(0.0);
    
    } else {
        let n = 0;
        Object.keys(param_selectedDocumentData).map((key) => {
            const subEl = param_selectedDocumentData[key];
            if (Array.isArray(subEl)){
                n += subEl.length;
            } else {
                console.log("WARNING: expecting Array sub-element of param_selectedDocumentData, but got type:", typeof subEl);
            }
        });
        setNumberOfFoundSentences(n);
    }

  }, [param_selectedDocumentData]);
  

  async function getDocument(){
    const documentsList = await FetchDataUtils.getData("captures/documents?id=" + encodeURIComponent(param_oneCapture.id));
    if('error' in documentsList){
      LoggingUtils.log(documentsList);
      return;
    }

    // console.log("DocumentExpanderContainer: getDocument:", documentsList);
    const _oneCapture = {...documentsList[0]};

    if (Utils.isString(_oneCapture.sentences_list)){
      try{
        _oneCapture.sentences_list = JSON.parse(_oneCapture.sentences_list);
      }catch(e){
        console.log("ERROR: DocumentExpanderContainer: JSON.parse(_oneCapture.sentences_list):", _oneCapture.sentences_list);
      }  
    }

    if (Utils.isString(_oneCapture.speakers_list)){
      try{
        _oneCapture.speakers_list = JSON.parse(_oneCapture.speakers_list);
      }catch(e){
        // console.log("ERROR: DocumentExpanderContainer: JSON.parse(_oneCapture.speakers_list):", _oneCapture.speakers_list);
        _oneCapture.speakers_list = _oneCapture.speakers_list.split(",").map(function(item) {
          return item.trim();
        });
      }  
    }

    if (Utils.isString(_oneCapture.speakers_activity)){
      try{
        _oneCapture.speakers_activity = JSON.parse(_oneCapture.speakers_activity);
      }catch(e){
        console.log("ERROR: DocumentExpanderContainer: JSON.parse(_oneCapture.speakers_activity):", _oneCapture.speakers_activity);
      }  
    }
    
    if (Utils.isString(_oneCapture.notes)){
      try{
        _oneCapture.notes = JSON.parse(_oneCapture.notes);
      }catch(e){
        console.log("ERROR: DocumentExpanderContainer: JSON.parse(_oneCapture.notes):", _oneCapture.notes);
      }  
    }

    if (Utils.isString(_oneCapture.summary)){
      try{
        _oneCapture.summary = JSON.parse(_oneCapture.summary);
      }catch(e){
        console.log("ERROR: DocumentExpanderContainer: JSON.parse(_oneCapture.summary):", _oneCapture.summary);
      }  
    }

    parseReceivedOneCaptureData(_oneCapture);
  }


  function parseReceivedOneCaptureData(_oneCapture){
      //
      // Timestamp
      //
      let _formattedTimestamp = "-";
      try{
        const d = new Date( parseInt(_oneCapture.timestamp) );
        _formattedTimestamp = new Intl.DateTimeFormat('en-US', timeFormatOptions).format(d);
      }catch(ignore){};

      let _title = _oneCapture?.title;
      if (!_title && !!_oneCapture?.notes){
        _title = "";

        // notes could be String or Array
        let _notes = [];
        if (Utils.isString(_oneCapture?.notes)){
            try{
                _notes = JSON.parse(_oneCapture?.notes);
            }catch(ignore){}
        }
        if (Array.isArray(_oneCapture?.notes)){
            _notes = _oneCapture?.notes;
        }
        if(_notes.length > 0 && !!_notes[0]?.content){
          _title = _notes[0].content.split('\n').join(' ').substring(0, 50);
          _title = _title.charAt(0).toUpperCase() + _title.slice(1);
        }
      }

      const _sentencesList = _oneCapture.sentences_list;
      if (!_title && !!_sentencesList){
        _title = "";
        for(let i=0; i<_sentencesList.length && i<3; i++){
          _title += " " + _sentencesList[i].content;
        }
        _title = _title.substring(0, 50);
        _title = _title.charAt(0).toUpperCase() + _title.slice(1);
      }
      _oneCapture.title = _title;

      
      if(StringUtils.isString(_oneCapture?.summary)){
        try{
            _oneCapture.summary = JSON.parse(_oneCapture.summary);
        }catch(ignore){}
      }

  
      setTitle(_oneCapture.title);
      setFormattedTimestamp(_formattedTimestamp);
      setCapturedSeconds(_oneCapture.captured_seconds);
      setCaptureClass(_oneCapture.class);

      const progressStatus = _oneCapture?.progress_status;
      if(!!progressStatus && Array.isArray(progressStatus) && progressStatus.length > 0){
        const lastProgressStatus = progressStatus[progressStatus.length - 1];
        setCurrentProgressStatus(lastProgressStatus.status);
      }
      


    //   console.log("OneCaptureExpanderContainer: parseReceivedOneCaptureData: FINAL _oneCapture:", _oneCapture);
      //
      setOneCapture(_oneCapture);

      // make original deep-copy
      originalCaptureDB.current.timestamp = _oneCapture.timestamp;
      originalCaptureDB.current.class = _oneCapture.class;
      originalCaptureDB.current.title = _oneCapture.title;
      originalCaptureDB.current.sentences_list = _oneCapture.sentences_list;
      originalCaptureDB.current.notes = _oneCapture.notes;
      originalCaptureDB.current.captured_seconds = _oneCapture.captured_seconds;

  }



  async function updateCapture(updatedCapture){
    console.log("DocumentExpanderContainer: updatedCapture: data:", updatedCapture);
    parseReceivedOneCaptureData(updatedCapture);
    param_FnUpdateDocument(updatedCapture);
  }



  async function updateCaptureDB(captureUpdate){
    // console.log("DocumentExpanderContainer: updateCaptureDB: captureUpdate data to save:", captureUpdate);

    // console.log("DocumentExpanderContainer: updateCaptureDB: 'fetch' POST sent captureUpdate:", captureUpdate);
    let res = await FetchDataUtils.postData('captures', captureUpdate);
    if('error' in res){ // new way of saving failed by returning 'null'
      console.log("ERROR DocumentExpanderContainer: DocumentExpanderContainer: FAILED to update data:", res);
      res = null;
    }

    if('class' in captureUpdate || 'timestamp' in captureUpdate){
      // param_FnReloadCapturesList();
    }
    else{
      const updatedCapture = {...oneCapture, ...captureUpdate};
      parseReceivedOneCaptureData(updatedCapture);
    }

    param_FnUpdateDocument(captureUpdate);
  }





  const timeFormatOptions = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: true,
    timeZone: 'America/Chicago'
  };

  function secondsToMinSecPadded(timeInSeconds){
    // console.log("secondsToMinSecPadded: ", timeInSeconds)
    if (isNaN(timeInSeconds)){
      return "- : -";
    }
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = "0" + (timeInSeconds - minutes * 60);
    return minutes + ":" + seconds.substr(-2);
  };
  



  const CustomTitle = ({ createdAt, title, minutes, tokens, currentProgressStatus }) => {

    return (
      <Flex direction="row"
        // placeContent="start"
        alignItems="center"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
        margin="0px 5px"
        border={!!expandedFlag ? "3px solid darkblue" : ""}
        backgroundColor={!!expandedFlag ? "darkseagreen" : ""}
      >
        <Flex direction="row"
          width="-webkit-fill-available"
          onClick={() => {
            if (!expandedFlag && !oneCapture?.sentences_list?.length ){
              // console.log("clicked expanded: document:", oneCapture);
              getDocument();
            }
            setExpandedFlag(!expandedFlag);

          }}
        >
            <View 
              width="12rem" color={"teal"}
            >
              {createdAt}
            </View>
            <View as="div"
                maxWidth="5%" color={"teal"}
            >
                {currentProgressStatus}
                </View>
            <View as="div"
                width="10%" color={"teal"}
            >
                { !!numberOfFoundSentences &&
                    <Flex
                        gap="0.3rem"
                        fontWeight="bold"
                        onClick={() => {
                            if (!expandedFlag && !oneCapture?.sentences_list?.length ){
                                // console.log("clicked expanded: document:", oneCapture);
                                getDocument();
                            }
                            if (!expandedFlag){
                                setExpandedFlag(true);
                            }

                        }}
                    >
                        <BiSearchAlt fontSize="1.5rem" />
                        [{numberOfFoundSentences}]
                    </Flex>
                }
            </View>
            <View as="div"
              fontWeight="bolder"
              fontSize={!!expandedFlag ? "1.4rem" : "1.2rem"}
            >
              {title}
            </View>
            { !!expandedFlag &&
              <BiCaretDown />
            }
        </Flex>

        <Flex direction="row"
          marginLeft="auto"
        >

            {!!tokens &&
              <View 
                width="max-content"
                marginRight="2rem"
              >
                {tokens.toLocaleString()} tkns
              </View>  
            }
            <View marginLeft="auto" >
              {secondsToMinSecPadded(minutes)}
            </View>

            { selectionModeFlag === true &&
              <Flex direction="row"
                    marginLeft="auto"
              >
                <CheckboxField
                  value={false}
                  isIndeterminate={false}
                  fontSize="1.6rem"
                  checked={!!isSelectedFlag}
                  onClick={(e) => {
                    const _isSelectedFlag = e.target.checked;
                    setIsSelectedFlag(_isSelectedFlag);
                    param_fnOnChildChangeSelectedFlag(_isSelectedFlag);

                  }}
                />
              </Flex>
            }

            { selectionModeFlag === false && 
                <View as="table"
                  className="cls-show-hide-switch cls-text-center cls-vertical-align-center cls-background-greenyellow-dim"
                  minWidth="3rem"
                  padding="0px"
                  fontWeight="normal"
                  onClick={() => {
                    setIsSelectedFlag(true);
                    param_fnOnChildChangeSelectedFlag(true);
                  }}
                >
                  <tbody><tr><td>
                        <BsLightning />
                        Q.Ai
                  </td></tr></tbody>
                </View>
            }
        </Flex>

      </Flex>
    );
  };



  if ( !!param_showOnlySelectedDocumentsFlag && !param_isSelectedFlag )
    return;

  return (
    <View
      key={oneCapture?.id}
      value={oneCapture?.id}
    >
      <CustomTitle
          createdAt={formattedTimestamp}
          title={title}
          minutes={capturedSeconds}
          tokens={oneCapture?.tokens}
          currentProgressStatus={currentProgressStatus}
      />

      { !!expandedFlag &&
      <View>
        <Flex
          direction="row"
          justifyContent="flex-start"
        >

          <View as="div"
            float="left"
            color="darkgoldenrod"
            fontSize="1.8rem"
            onClick={() => setLockedEditFlag(!lockedEditFlag)}
          >
            { lockedEditFlag &&
              <BsFillLockFill />
            }
            { !lockedEditFlag &&
              <BsFillUnlockFill />
            }
          </View>


          { !lockedEditFlag &&
          <Flex
            direction="row"
            justifyContent="flex-start"
            width="100%"
            margin="0 1rem"
            alignItems="center"
            alignContent="space-between"
          >
              <View as='div'
                marginLeft="auto"
              >
                { !lockedEditFlag &&
                  <IconContext.Provider value={{ color: "red", size: "2rem" }}>
                    <BiTrash 
                      color="red" 
                      onClick={() => param_FnDeleteDocument({id:oneCapture?.id})}
                    />
                  </IconContext.Provider>
                }
              </View>
            
          </Flex>
          }
        </Flex>     



        <IntegratedView
            // param_availableClassesList={param_availableClassesList}
            param_oneCapture={oneCapture}
            param_lockedEditFlag={lockedEditFlag}

            param_searchText={param_searchText}

            param_minimizedSentences={true}
            // param_minimizedAnnouncements={false}
            param_minimizedSummary={false}
            param_minimizedNotes={false}

            param_FnUpdateCapture={updateCapture}
            param_FnUpdateCaptureDB={updateCaptureDB}
        />

        {/* <View as="div"
          textAlign="right"
        >
          <AiSummarizer
              param_oneCapture={oneCapture}
              param_streamingSentencesToAppendFlag={false}
              param_FnUpdateCaptureDB={updateCaptureDB}
          />
        </View> */}

      </View>
      }

      <Divider orientation="horizontal" />
    </View>
 );
}
export default DocumentExpanderContainer;
