import React, { useState, useRef, useEffect } from 'react';

import { View, Flex, Autocomplete, Button
  } from '@aws-amplify/ui-react';
import { IconContext } from "react-icons";

import { BiTrash, BiSolidUserPlus } from "react-icons/bi";
import { HiSpeakerWave } from "react-icons/hi2";


import { v4 as uuidv4 } from 'uuid';
import * as FetchDataUtils from '../../lib/fetchDataUtils';

import OneSpeaker from './OneSpeaker';
import SuggestedSpeaker from './SuggestedSpeaker';

import { 
      BiCaretRight, 
      BiCaretDown,
} from "react-icons/bi";

// GLOBAL window.varParticipantsList

function ListSpeakers({ 
    param_currentParticipants, 
    param_suggestedParticipants, 
    param_FnOnChange, 
    param_FnOnSpeakerActiveChange,
}) {
    
    const [currentParticipants, setCurrentParticipants] = useState([]);
    const currentParticipantsRef = useRef([]);

    const [expandedSuggestedParticipantsFlag, setExpandedSuggestedParticipantsFlag] = useState(false);
    const [suggestedParticipants, setSuggestedParticipants] = useState([]);
    const suggestedParticipantsRef = useRef([]);

    const rejectedSuggestedParticipantsRef = useRef([]);

    const [activeSpeaker, setActiveSpeaker] = useState(null);

    const [participantsAutocompleteOptions, setParticipantsAutocompleteOptions] = useState([]);
    const [valueParticipantAutocomplete, setValueParticipantAutocomplete] = useState("");


    let init = useRef(true);
    useEffect(() => {
        if (!init.current) return;
        init.current = false;

        // Check every 100 milliseconds for 'window?.varParticipantsList'
        const intervalId = setInterval(() => {
            if(window?.varParticipantsList != null) {
                console.log("Global variable is set, running function...");
                const options = composeAutocompleteOptionsFromAvailableParticipantsList(
                    window.varParticipantsList,
                    param_currentParticipants,
                );
                setParticipantsAutocompleteOptions(options);
                clearInterval(intervalId); // Stop the polling
            }
        }, 100); 
    }, []);
  

    function composeAutocompleteOptionsFromAvailableParticipantsList(
        availableParticipantsList,
        currentParticipantsList,
    ){
        let options = [];
        try{
          let sortedItems = availableParticipantsList.filter(item => !currentParticipantsList.includes(item));
          sortedItems.sort();

          for(let i=0;i<sortedItems.length;i++){
            const item = sortedItems[i];
            options.push({id:item, label:item});
          };
        }catch(ignore){
            console.error(ignore);
        };
        return options;
    }


    
    useEffect(() => {
        if(!param_suggestedParticipants || !Array.isArray(param_suggestedParticipants)){
            return;
        }
        if (JSON.stringify(param_suggestedParticipants) === JSON.stringify(suggestedParticipantsRef.current)){
            return;
        }

        // filter param suggestions that were NOT previously rejected
        let _suggestedParticipants = 
            param_suggestedParticipants.filter(speaker => !rejectedSuggestedParticipantsRef.current.includes(speaker));

        // filter suggested Participants
        suggestedParticipantsRef.current = 
            _suggestedParticipants.filter(speaker => !currentParticipantsRef.current.includes(speaker));
        setSuggestedParticipants(suggestedParticipantsRef.current);

    }, [param_suggestedParticipants]);


    useEffect(() => {
        if(!param_currentParticipants || !Array.isArray(param_currentParticipants)){
            return;
        }
        if (JSON.stringify(currentParticipantsRef.current) === JSON.stringify(param_currentParticipants)){
            return;
        }

        currentParticipantsRef.current = [...param_currentParticipants];

        setCurrentParticipants(param_currentParticipants);

        // console.log("ListSpeakers: useEffect: onInit: window.varParticipantsList:", window.varParticipantsList);
        const options = composeAutocompleteOptionsFromAvailableParticipantsList(
            window.varParticipantsList,
            param_currentParticipants,
        );
        
        setParticipantsAutocompleteOptions(options);

        // filter suggested Participants
        suggestedParticipantsRef.current = 
            suggestedParticipantsRef.current.filter(speaker => !currentParticipantsRef.current.includes(speaker));
        setSuggestedParticipants(suggestedParticipantsRef.current);

    }, [param_currentParticipants]);


    async function createSpeakerDB(updateData){

        const _timestamp = Date.now();
        const _createdAt = new Date(_timestamp).toISOString();

        let _updateData = {
            id: uuidv4(),
            timestamp: _timestamp,     
            // __typename: "Speakers",
            createdAt: _createdAt,
            updatedAt: _createdAt,
          };
      
          if(!!updateData){
            _updateData = {..._updateData, ...updateData};
          }

        console.log("ListSpeakers: createSpeakerDB: 'fetch' POST sent _updateData:", _updateData);
        let res = await FetchDataUtils.postData('speakers', _updateData);
        if('error' in res){ // new way of saving failed by returning 'null'
          console.log("ERROR ListSpeakers: createSpeakerDB: FAILED to update data:", res);
          res = null;
        }
        return res;
    }


    const addParticipant = async (_participant) => {
        const _newCurrentParticipants = [...currentParticipants, _participant];
        setCurrentParticipants(_newCurrentParticipants);
        param_FnOnChange(_newCurrentParticipants);

        // remove from list of available participants
        setParticipantsAutocompleteOptions(participantsAutocompleteOptions.filter(participant => participant.id !== _participant));
    
        // add and Save global Speakers array, if Speaker not present
        // window.varAvailableSpeakersArray
        const foundSpeaker = window.varAvailableSpeakersArray.find((item) => item.speaker_name === _participant);
        if (!foundSpeaker){
            // create new speaker record for DB and create

            const newSpeaker = await createSpeakerDB({speaker_name: _participant});
            window.varAvailableSpeakersArray.push(newSpeaker);
        }
    };

    const removeParticipant = (_participant) => {
        if (activeSpeaker === _participant) {
            setActiveSpeaker(null);
            param_FnOnSpeakerActiveChange(activeSpeaker, false);
        }
        // remove from current participants
        const _newCurrentParticipants = currentParticipants.filter(participant => participant !== _participant);
        setCurrentParticipants(_newCurrentParticipants);
        param_FnOnChange(_newCurrentParticipants);

        // add to the selector options and sort
        const _newOptionsArray = [...participantsAutocompleteOptions, {id: _participant, label: _participant}];
        const _newSortedOptionsArray = _newOptionsArray.sort((a, b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
        });
        setParticipantsAutocompleteOptions(_newSortedOptionsArray);
    };


    const acceptParticipantSuggestion = (_speaker) => {
        // remove from suggested participants
        const _newSuggestedParticipants = suggestedParticipants.filter(speaker => speaker !== _speaker);
        setSuggestedParticipants(_newSuggestedParticipants);

        addParticipant(_speaker);
    };

    const rejectParticipantSuggestion = (_speaker) => {
        // remove from suggested participants
        const _newSuggestedParticipants = suggestedParticipants.filter(speaker => speaker !== _speaker);
        setSuggestedParticipants(_newSuggestedParticipants);

        let _newRejects = rejectedSuggestedParticipantsRef.current;
        _newRejects.push(_speaker);
        rejectedSuggestedParticipantsRef.current = _newRejects;
    };

    return (
        <Flex
            direction="column"
            marginTop="1rem"
            width="-webkit-fit-content"
            gap="0px"
            marginBottom="2rem"
        >
            {!!suggestedParticipants?.length &&
                <View as="div"
                    borderRadius="6px"
                    border="1px solid var(--amplify-colors-black)"
                    boxShadow="2px 2px 0px 0px var(--amplify-colors-neutral-60)"
                    fontSize="1rem"
                    fontWeight="bold"
                    marginBottom="2px"
                    backgroundColor="#add8e678"
                    onClick={() => {setExpandedSuggestedParticipantsFlag(!expandedSuggestedParticipantsFlag)}}
                >
                    { !expandedSuggestedParticipantsFlag &&
                        <BiCaretRight />
                    }
                    { !!expandedSuggestedParticipantsFlag &&
                        <BiCaretDown />
                    }
                    Suggested Participants [{suggestedParticipants?.length}]
                </View>
            }
            {!!expandedSuggestedParticipantsFlag &&
                <Flex 
                    direction="column"
                    maxHeight="15rem"
                    gap="0px"
                    overflow="scroll"
                >
                    {!!suggestedParticipants?.length && suggestedParticipants.map(speaker => (
                        <SuggestedSpeaker 
                            key={speaker}
                            param_speaker={speaker}
                            param_FnOnSpeakerAccept={(speaker) => acceptParticipantSuggestion(speaker) }
                            param_FnOnSpeakerReject={(speaker) => rejectParticipantSuggestion(speaker) }
                        />
                    ))}
                </Flex>
            }

            <Autocomplete
                minWidth="20rem"
                placeholder="Enter Speakers/Participants"
                options={participantsAutocompleteOptions}
                value={valueParticipantAutocomplete}
                onChange={(event) => {
                    setValueParticipantAutocomplete(event.target.value);
                }}
                onClear={() => {setValueParticipantAutocomplete("")}}
                onSelect={(option) => {
                    addParticipant(option.label);
                    setValueParticipantAutocomplete("");
                }}
                onKeyUp={(event) => {
                    // console.log("keyUp:", event);
                    if(event.code === "Enter"){
                        if(valueParticipantAutocomplete === "") return;
                        addParticipant(valueParticipantAutocomplete);
                        setValueParticipantAutocomplete("");
                    }
                }}

            />
            

            
            {!!currentParticipants?.length &&
                <View as="div"
                    fontSize="1rem"
                    fontWeight="bold"
                >
                    Participants:
                </View>
            }
            <Flex 
                direction="column"
                maxHeight="15rem"
                gap="0px"
                overflow="scroll"
            >
                {!!currentParticipants?.length && currentParticipants.map(speaker => (
                    <OneSpeaker 
                        key={speaker}
                        param_speaker={speaker}
                        // param_FnOnChange, 
                        param_FnOnSpeakerActiveChange={(speaker,activeFlag) => param_FnOnSpeakerActiveChange(speaker,activeFlag) }
                        param_FnOnRemoveSpeaker={(speaker) => removeParticipant(speaker) }
                    />
                ))}
            </Flex>


        </Flex>
    );
}

export default ListSpeakers;
