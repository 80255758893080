import React, { useState, useRef, useEffect } from 'react';

import { View, Flex, Autocomplete, Badge
  } from '@aws-amplify/ui-react';
import { IconContext } from "react-icons";

import { BiTrash, BiSolidUserPlus } from "react-icons/bi";
import { HiSpeakerWave } from "react-icons/hi2";


import { v4 as uuidv4 } from 'uuid';
import * as FetchDataUtils from '../../lib/fetchDataUtils';

// GLOBAL window.varParticipantsList

function SuggestedSpeaker({ 
    param_speaker, 
    // param_FnOnChange, 
    param_FnOnSpeakerAccept,
    param_FnOnSpeakerReject,

}) {

    const [speaker, setSpeaker] = useState(param_speaker);
    const [activeSpeakerFlag, setActiveSpeakerFlag] = useState(false);



    let init = useRef(true);
    useEffect(() => {
        if (!init.current) return;
        init.current = false;

    }, []);
  


    return (
        <div 
            style={{ 
                // backgroundColor: getRandomColor(), 
                color:  'lightGrey' , 
                padding: '5px', 
                margin: '5px 13px',
                cursor: 'pointer',
                border: '1px black solid'
            }}
            onClick={(e) => {
                    // console.log ("onClick:", e);
            }}
        >
            <Flex
                direction="row"
                justifyContent="flex-start"
                margin="0 1rem"
                alignItems="center"
                alignContent="space-between"
            >
                <View as='div'>
                    {activeSpeakerFlag &&
                        <HiSpeakerWave 
                            fontSize="1.5rem"
                            color='red'
                            alignmentBaseline='auto'
                        />
                    }
                </View>
                <View as='div'>
                    {activeSpeakerFlag ? <b>{speaker}</b> : speaker}
                </View>

                <View as='div'
                    marginLeft="auto"
                >
                    <Badge variation="success" size="small"
                        onClick={(e) => {
                            param_FnOnSpeakerAccept(speaker);
                        }}
                    >
                        Accept
                    </Badge>
                    <Badge variation="error" size="small" marginLeft="0.5rem"
                        onClick={(e) => {
                            param_FnOnSpeakerReject(speaker);
                        }}
                    >
                        Reject
                    </Badge>
                </View>


            </Flex>

        </div>
    );
}

export default SuggestedSpeaker;
