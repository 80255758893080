// components/OneSentenceView.js
import { useRef, useEffect, useState } from "react";

import { View, Flex, Button
  } from '@aws-amplify/ui-react';

import { MdOutlineClose } from "react-icons/md";
import { GiCancel } from "react-icons/gi";

import * as Utils from '../lib/utils';

import './transcript.css';
import { BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";


function OneSentenceView({ 
    param_sentence,
    param_minimized,
    param_lockedEditFlag,
    param_autoFocus,

    param_FnUpdateSentenceDB,
    // param_FnDeleteSentenceDB,
    param_FnCancel,
  }) {


    let init = useRef(true);

    const[ sentenceContent, setSentenceContent] = useState("");
    const[ minimized, setMinimized] = useState(false);
    const[ lockedEditFlag, setLockedEditFlag] = useState(true);

    const originalSentenceContentRef = useRef({});


    const sentenceTextArea = useRef();


    useEffect(() => {
        if (init.current){
            // TODO
            init.current = false;
        }
    }, []);



    useEffect(() => {

        // console.log("param_sentence:", param_sentence);
        let _content = param_sentence;
        if(!_content){
            _content = "";
        }
        setSentenceContent(_content);
        originalSentenceContentRef.current = _content;


    }, [param_sentence]);


    useEffect(() => {
        // console.log("OneSentenceView: useEffect: param_lockedEditFlag:", param_lockedEditFlag);

        if(param_lockedEditFlag === undefined || param_lockedEditFlag === null){
            return;
        }
        setLockedEditFlag(param_lockedEditFlag);

    }, [param_lockedEditFlag]);
    

    useEffect(() => {
        setMinimized(param_minimized);

    }, [param_minimized]);


    useEffect(() => {
        if(minimized) return;
        // resize Sentence element
        sentenceTextArea.current.style.height = 'auto';
        sentenceTextArea.current.style.height = sentenceTextArea.current.scrollHeight + 'px';

    }, [sentenceContent, minimized]);




    function onChangeSentenceContent(event){
        setSentenceContent(event.target.value);
    }


    function updateSentenceDB(sentenceContent){
        if(sentenceContent !== originalSentenceContentRef.current){
            // console.log("OneSentenceView: onBlur: changed Sentence.Content: saving to DB", sentenceContent);
            param_FnUpdateSentenceDB( sentenceContent );
        }
    }


    // function onDeleteSentence(event){
    //     param_FnDeleteSentenceDB(param_sentence);
    // }



    function onUnlockEdit(){
        setLockedEditFlag(false)
        setTimeout(() => {
            setLockedEditFlag(true)
        }, 7000);
    }


    
    return (

            <View
                marginTop="0.5rem"
                height={minimized ? "0" : null}
                border={minimized ? "1px solid bisque" : null}
                overflow="hidden"
                className={minimized ? "cls-unselectable": ''}
                width="100%"
            >
                
                {/* <Flex
                direction="row"
                alignItems="center"
                >
       
                    { (param_lockedEditFlag === undefined || param_lockedEditFlag === null) &&
                        <View as="div"
                            color="darkgoldenrod"
                            fontSize="1.2rem"
                            >
                            { lockedEditFlag &&
                                <BsFillLockFill 
                                onClick={() => onUnlockEdit()}
                                />
                            }
                            { !lockedEditFlag &&
                                <BsFillUnlockFill 
                                onClick={() => setLockedEditFlag(true)}
                                />
                            }
                        </View>
                    }

                    {!lockedEditFlag &&
                        <View as="div"
                            marginLeft="auto"
                            marginRight="1rem"
                        >
                            <GiCancel
                            fontSize=".9rem"
                            color="red"
                            onClick={(event) => window.confirm('Delete Sentence?') && onDeleteSentence(event) }
                            />

                        </View>
                    }
                </Flex> */}

                {!minimized &&
                <>
                    <textarea 
                        width="99%"
                        ref={sentenceTextArea}
                        autoFocus={!!param_autoFocus}
                        className="cls-sentence-textarea"
                        value={sentenceContent} 
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event) => onChangeSentenceContent(event)}
                        // onBlur={() => updateSentenceDB()} 
                    />
                    <Flex
                        direction="row"
                        width="10rem"
                        alignItems="right"
                    >
                       <Button
                            variation="primary"
                            onClick={(event) => {
                                event.stopPropagation();
                                updateSentenceDB(sentenceContent);
                            }}
                       >
                        Save
                        </Button> 
                       <Button
                            variation="warning"
                            onClick={(event) => {
                                event.stopPropagation();
                                param_FnCancel();
                            }}
                        >
                        Cancel
                        </Button> 
                    </Flex>

                </>
                }

            </View>
    );
  }
  export default OneSentenceView;