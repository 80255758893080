import { API, graphqlOperation } from 'aws-amplify';
import { createInvoices } from '../graphql/mutations';
import { updateInvoices } from '../graphql/mutations';
import { listInvoices } from '../graphql/queries';
import { deleteInvoices } from '../graphql/mutations';
import * as Utils from './utils';
import { v4 as uuidv4 } from 'uuid';



// type Invoices @model @auth(rules: [{ allow: owner }]) {
//   id: ID!
//   timestamp: String!
//   customer_id: String!
//   status: String!
//   invoice_data: String!
// }

export function unpackFromDbFormat(oneItemFromDb){
  if(!oneItemFromDb?.id){
    console.warn("WARNING While Unpacking from DB: Record missing ID: ", oneItemFromDb);
  }
  let unpackedData = {...oneItemFromDb};
  unpackedData.invoice_data = Utils.safeJSONParse(oneItemFromDb.invoice_data, {});

  return unpackedData;
}

export function packToDbFormat(oneItem){
  if(!oneItem?.id){
    console.warn("WARNING While Packing for DB: Record missing ID: ", oneItem);
  }

  let packedData = { 
    id: oneItem.id,
    timestamp: (oneItem?.timestamp === undefined)? Date.now() : oneItem.timestamp,
    customer_id: (oneItem?.customer_id === undefined)? "" : oneItem.customer_id,
    status: (oneItem?.status === undefined)? "pre-checkout" : oneItem.status,
    invoice_data: (oneItem?.invoice_data === undefined)? "{}" : JSON.stringify( oneItem.invoice_data, null, 2),
   };

  // if('timestamp' in oneItem)
  //   packedData.timestamp = oneItem.timestamp;
  // if('customer_id' in oneItem)
  //     packedData.customer_id = oneItem.customer_id;
  // if('status' in oneItem)
  //     packedData.status = oneItem.status;

  // if('invoice_data' in oneItem)
  //   packedData.invoice_data = JSON.stringify( oneItem.invoice_data, null, 2);

  return packedData;
}




export async function fetchInvoicesDB(){
  try {
    const response = await API.graphql(graphqlOperation(
      listInvoices
        ));
    // console.log(response.data);
    const _items = response.data.listInvoices.items;

    let items = [];
    for(let i=0; i<_items.length; i++){
      const oneItem = unpackFromDbFormat(_items[i]);
      items.push(oneItem);
    }
    return items;

  } catch (err) { 
    console.log('error: ', err) 
  }
};





export async function insertNewInvoiceDB(itemData){

  let newItemData = {};
  if(!!itemData){
    newItemData = {...itemData};
  }
  if(!newItemData?.id){
    newItemData.id = uuidv4();
  }
  if(!newItemData?.timestamp){
    newItemData.timestamp = Date.now();
  }
  if(newItemData?.customer_id === undefined || newItemData?.customer_id === null){
    newItemData.customer_id = "";
  }
  if(newItemData?.status === undefined || newItemData?.status === null){
    newItemData.status = "";
  }
  if(newItemData?.invoice_data === undefined || newItemData?.invoice_data === null){
    newItemData.invoice_data = {};
  }
  console.log(newItemData);
  let packedNewItemData = packToDbFormat(newItemData);
  console.log(packedNewItemData);

  let newItem = {};
  try{
    const response = await API.graphql(graphqlOperation(createInvoices, 
        { input: packedNewItemData })
      );
    // console.log("New Item inserted into DB response: ", response);
    newItem = unpackFromDbFormat(response.data.createInvoices);
    // console.log("New Item: ", newItem);

  }catch(error){
    console.log("ERROR New Lecture inserting into DB response: ", error, newItem);
    throw error;
  }
  
  return newItem;
}

  // async function fetchItemById(id){
//   const response = await API.graphql(graphqlOperation(getInvoice, {
//       id: id
//   }));
//   console.log("Fetch Item DB response: ", response);
//   return(response.data.getInvoice);
// };






  export async function saveInvoiceDB(itemData){
    let _itemData = {...itemData};

    if(!_itemData?.id){
      console.log("ERROR Updating Record missing ID: ", _itemData);
      throw new Error('Updating Record missing ID');
    }
    let packedData = packToDbFormat(_itemData);

    let updatedItem = {};
    try{
      const response = await API.graphql(graphqlOperation(
        updateInvoices, 
        { input: packedData }
      ));
      // console.log("Updated Item inserted into DB response: ", response.data.updateInvoices);
      updatedItem = unpackFromDbFormat(response.data.updateInvoices);
      // console.log("Updated Item: ", updatedItem);

    }catch(error){
      console.log("ERROR Item updating in DB response: ", error, updatedItem);
      throw error;
    }
    return updatedItem;
  }





  export async function deleteInvoiceDB(id){
    // if (window.confirm('Are you sure you wish to delete this item?')){
      const itemData = {
        id: id
      };

      try{
        const response = await API.graphql(graphqlOperation(
          deleteInvoices, 
          { input: itemData }
        ));
        // console.log('Deleted Item in DB: ', response);

      }catch(error){
        console.log("ERROR Lecture deleting in DB response: ", error);
        throw error;
      }
    // }
  }