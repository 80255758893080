// components/IntegratedView.js
import { useRef, useEffect, useState } from "react";

import { View, Flex, Button,
  Heading,
  TextField,
} from '@aws-amplify/ui-react';

import { 
  BiSearchAlt,
  BiCopyAlt,
 } from "react-icons/bi";

 import { BsEye, BsEyeSlash, BsArrowDown, BsArrowUp, BsPlusSquare } from "react-icons/bs";

import * as Utils from '../lib/utils';

import SentenceItemContainer from "./SentenceItemContainer";
import ListSpeakers from './ListSpeakers/ListSpeakers';

import './transcript.css';

import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

import * as FetchDataUtils from '../lib/fetchDataUtils';
import * as LoggingUtils from '../lib/loggingUtils';
import AutocompleteAvailableClasses from "./AutocompleteAvailableClasses";





function IntegratedView({ 

  // param_availableClassesList,
  param_oneCapture,
  param_lockedEditFlag,

  param_searchText,

  param_hideEnteringSpeakers,
  param_minimizedSentences,
  param_minimizedAnnouncements,
  param_minimizedSummary,
  param_minimizedNotes,

  param_width, 

  param_FnUpdateCaptureDB,
  param_FnUpdateCapture,

  param_scrollToLineNumber,
  param_scrollToLineNumberSilentFlag,
}) {


  // const [classesAutocompleteOptions, setClassesAutocompleteOptions] = useState([]);

  const [captureClass, setCaptureClass] = useState("");
  const [title, setTitle] = useState("");
  // const [timestamp, setTimestamp] = useState(new Date().getTime());
  const timestampRef = useRef(new Date().getTime());

  const [sentences, setSentences] = useState([]);
  const [notes, setNotes] = useState([]);
  // const [announcements, setAnnouncements] = useState([]);
  const [summaries, setSummaries] = useState([]);
  // const [pauses, setPauses] = useState([]);
  const [summarizationStatus, setSummarizationStatus] = useState("");
  const [cleaningTranscriptionStatus, setCleaningTranscriptionStatus] = useState("");

  const [speakersList, setSpeakersList] = useState([]);
  const [speakersActivity, setSpeakersActivity] = useState([]);

  const [showFindKeywords, setShowFindKeywords] = useState(false);
  const [findKeywordsTextFieldVal, setFindKeywordsTextFieldVal] = useState("");
  // const [findKeywordsArray, setFindKeywordsArray] = useState([]);
  const findKeywordsArrayRef = useRef([]);
  // const sentencesHavingKeywordsDivRefArrayRef = useRef([]);
  const linesHavingKeywordsArrayRef = useRef([]);
  const linesIdxHavingKeywordsArrayRef = useRef([]);
  const [numberOfSentencesHavingKeyword, setNumberOfSentencesHavingKeyword] = useState(0);

  const [currentPositionInListOfSentenceHavingKeyword, setCurrentPositionInListOfSentenceHavingKeyword] = useState(0);




  const sentencesListRef = useRef();
  const sentencesListParentRef = useRef();

  const[ displayShowMoreFlag, setDisplayShowMoreFlag] = useState(false);

  
  const[ minimizedSentences, setMinimizedSentences] = useState(true);
  // const[ minimizedAnnouncements, setMinimizedAnnouncements] = useState(false);
  const[ minimizedSummary, setMinimizedSummary] = useState(false);
  const[ minimizedNotes, setMinimizedNotes] = useState(false);
    
  // const[ topPositionForAddSummaryOrNotes, setTopPositionForAddSummaryOrNotesFlag] = useState(0);


  const originalCaptureDB = useRef({});

  const [colorOfCopyIcon, setColorOfCopyIcon] = useState('black');


  useEffect(() => {
      displayHideShowMoreLink();
  });

  let init = useRef(true);
  useEffect(() => {
    if (init.current){
      // TODO
      findKeywordsArrayRef.current = ["exam","test","quiz","important"];
      setFindKeywordsTextFieldVal(findKeywordsArrayRef.current.join(" "));
      init.current = false;
    }
  }, []);



  useEffect(() => {
    // console.log("IntegratedView: useEffect: param_oneCapture", param_oneCapture);

    setTitle(param_oneCapture.title);
    setCaptureClass(param_oneCapture.class);

    if (!!param_oneCapture?.timestamp){
      // setTimestamp(param_oneCapture.timestamp);
      timestampRef.current = param_oneCapture.timestamp;
    }

    loadSentences(param_oneCapture);

    // setAnnouncements(param_oneCapture?.announcements); 
    if(!!param_oneCapture?.summary?.length){
      // console.log("IntegratedView: useEffect: param_oneCapture?.summary", param_oneCapture?.summary);
      setSummaries(param_oneCapture.summary);
    }

    if(!!param_oneCapture?.notes?.length){
      setNotes(param_oneCapture.notes); 
    }

    if(!!param_oneCapture?.speakers_list){
      // if array
      if(Array.isArray(param_oneCapture.speakers_list)){
        setSpeakersList(param_oneCapture.speakers_list);
      }
      // if string
      if (Utils.isString(param_oneCapture.speakers_list)){
        try{
          const _speakersList = JSON.parse(param_oneCapture.speakers_list);
          setSpeakersList(_speakersList);
        }catch(e){
          // console.log("ERROR: DocumentExpanderContainer: JSON.parse(_oneCapture.speakers_list):", _oneCapture.speakers_list);
          const _speakersList = param_oneCapture.speakers_list.split(",").map(function(item) {
            return item.trim();
          });
          setSpeakersList(_speakersList);
        }  
      }
    }

    if(!!param_oneCapture?.speakers_activity){
      setSpeakersActivity(param_oneCapture.speakers_activity); 
    }

    // setPauses(param_oneCapture?.pauses)

    // make original deep-copy
    originalCaptureDB.current.timestamp = param_oneCapture.timestamp;
    originalCaptureDB.current.class = param_oneCapture.class;
    originalCaptureDB.current.title = param_oneCapture.title;
    originalCaptureDB.current.speakers_list = param_oneCapture.speakers_list;
    originalCaptureDB.current.speakers_activity = param_oneCapture.speakers_activity;
    originalCaptureDB.current.sentences_list = param_oneCapture.sentences_list;
    originalCaptureDB.current.notes = param_oneCapture.notes;
    originalCaptureDB.current.captured_seconds = param_oneCapture.captured_seconds;

  }, [param_oneCapture]);



  useEffect(() => {
    setMinimizedSentences(param_minimizedSentences);
    // setMinimizedAnnouncements(param_minimizedAnnouncements);
    setMinimizedSummary(param_minimizedSummary);
    setMinimizedNotes(param_minimizedNotes);
  }, [
    param_minimizedSentences, 
    param_minimizedAnnouncements,
    param_minimizedSummary,
    param_minimizedNotes
  ]);


  // useEffect(() => {
  //   console.log("IntegratedView: useEffect: summaries", summaries);
  // }, [summaries]);

  let lockedEditFlagRef = useRef(param_lockedEditFlag);
  useEffect(() => {
    if(param_lockedEditFlag === true && lockedEditFlagRef.current === false){
      onParamLockedEditFlag();
    }
    lockedEditFlagRef.current = param_lockedEditFlag;
  }, [ param_lockedEditFlag ]);

  
  

  useEffect(() => {
    if(!param_searchText?.length || !sentences?.length){
        // skip if missing either 'searchText' or 'sentences[]'
        return;
    }

    setShowFindKeywords(true);
    onChangeFindKeywords(param_searchText);

    // if(!showFindKeywords){
    //     setShowFindKeywords(true);

    // }else{
    //     onChangeFindKeywords(param_searchText);
    // }

  }, [ param_searchText, sentences ]);



//   useEffect(() => {
    
//     if(!param_searchText?.length){
//         return;
//     }
//     if(!showFindKeywords){
//         return;
//     }

//     onChangeFindKeywords(param_searchText);

//   }, [ showFindKeywords ]);




  useEffect(() => {
    if(!param_scrollToLineNumber){
      return;
    }
    scrollLineIntoView(param_scrollToLineNumber - 1);
  }, [ param_scrollToLineNumber ]);
  



  function loadSentences(param_oneCapture){
    // add associated .notes .announcements  .summaries  .pauses
    let _sentences = [];
    try{
      _sentences = JSON.parse(JSON.stringify(param_oneCapture?.sentences_list));
    }catch(ignore){};
    for(let i=0; i<_sentences.length; i++){
      delete _sentences[i].notes;
      delete _sentences[i].announcements;
      delete _sentences[i].summaries;
      delete _sentences[i].pauses;
    }

    ["notes", "announcements", "summaryX", "pauses"].forEach((itemName) => {

      try{
        const arrayOfItems = param_oneCapture[itemName];
        if(Array.isArray(arrayOfItems)){
          for(let i=0; i<arrayOfItems.length; i++){
            const item = arrayOfItems[i];
            if(item.start_line < _sentences.length){
              _sentences[item.start_line][itemName] = item;
            }else{
              console.log("WARNING: " + itemName + " points to line number ["+item.start_line+"] beyond Transcript length ["+_sentences.length+"].")
            }
          }
        }
      }catch(e){
        console.log("IntegratedView: useEffect: ERROR param_oneCapture", e);
      }
    })


    // append each Summary to its 'end_line' sentence
    try{
      const arrayOfItems = param_oneCapture.summary;
      if(Array.isArray(arrayOfItems)){
        for(let i=0; i<arrayOfItems.length; i++){
          const item = arrayOfItems[i];

            // Summary that is of type "entire-summary" is attached to the first line of the section
            if(item?.section_type === "entire-summary"){
              _sentences[item.start_line].summary = item;
            }else{
                // regular "summary" that must end at the end_line
                if(item.end_line < _sentences.length){
                    _sentences[item.end_line].summary = item;
                }else{
                    _sentences[_sentences.length - 1].summary = item;
                    console.log("WARNING: summary points to line number ["+item.start_line+"] beyond Transcript length ["+_sentences.length+"].")
                }
            }
        }
      }

    }catch(e){
      console.log("IntegratedView: useEffect: ERROR param_oneCapture", e);
    }

    // _sentences = connectSentences(_sentences);

    // console.log("IntegratedView: setSentences: _sentences: ", _sentences);
    setSentences(_sentences);
  }


  
  function connectSentences(_sentences){
    if(!_sentences || !_sentences.length){
      return _sentences;
    }

    let newSentences = [];
    let content = _sentences[0].content;
    let start_time = _sentences[0].start_time;
    let end_time = _sentences[0].end_time;

    for(let i=1; i<_sentences.length; i++){
      const oneSentence = _sentences[i];
      console.log("sentence", oneSentence);

      if (oneSentence.content === "Bye"){
        continue;
      }
      if (oneSentence.content === "Thanks for watching"){
        continue;
      }      


      let appendFlag = false;
      // lowercase
      if (!/^[A-Z]/.test(oneSentence.content)){
        appendFlag = true;
      }
      // acronym
      if (/^[A-Z][A-Z]/.test(oneSentence.content)) {
        appendFlag = true;
      }
      // digit
      if (/^\d/.test(oneSentence.content)) {
        appendFlag = true;
      }


      if (appendFlag){ // append
        content += " " + oneSentence.content;
        end_time = oneSentence.end_time;

      } else { // done with previous sentence - save and reset
        if (!content.endsWith('.')) {
          content += '.';
        }
        newSentences.push({
          content: content,
          start_time: start_time,
          end_time: end_time,
        });

        content = oneSentence.content;
        start_time = oneSentence.start_time;
        end_time = oneSentence.end_time;
      }
    }
    if (!content.endsWith('.')) {
      content += '.';
    }
    newSentences.push({
      content: content,
      start_time: start_time,
      end_time: end_time,
    });

    console.log(newSentences);
    return newSentences;
  }


  function toggleShowFindKeywords(){
    if(!!showFindKeywords){
      // on hiding, clear the array of sentences having keywords
      linesHavingKeywordsArrayRef.current = [];
      setNumberOfSentencesHavingKeyword(0);
      linesIdxHavingKeywordsArrayRef.current = [];
    }else{
      findSentencesHavingKeywords(sentences, findKeywordsArrayRef.current);
    }
    setShowFindKeywords(!showFindKeywords);
  }



  function onChangeFindKeywords(val){
    // console.log("onChangeFindKeywords()", val)
    setFindKeywordsTextFieldVal(val);

    const cleanedVal = val.replace(/^\s+|\s+$/g, "");
    findKeywordsArrayRef.current = cleanedVal.split(" ");

    findSentencesHavingKeywords(sentences, findKeywordsArrayRef.current);
  }



  function findSentencesHavingKeywords(_sentences, _keywordsArray){

    linesHavingKeywordsArrayRef.current = [];
    let _numberOfSentencesHavingKeyword = 0;
    linesIdxHavingKeywordsArrayRef.current = [];

    for(let i=0; i<_sentences.length; i++){
      const oneSentence = _sentences[i];

      const containingKeywordFlag = isContainingKeyword(oneSentence, _keywordsArray);
      linesHavingKeywordsArrayRef.current.push( containingKeywordFlag );
      if ( containingKeywordFlag ){
        linesIdxHavingKeywordsArrayRef.current.push( i );
        _numberOfSentencesHavingKeyword++;
      }
    }
    setNumberOfSentencesHavingKeyword(_numberOfSentencesHavingKeyword);

    if(_numberOfSentencesHavingKeyword > 0 && minimizedSentences === true){
      setMinimizedSentences(false);
    }

    return linesIdxHavingKeywordsArrayRef.current;
  }


  // function onFoundKeywordInSentence(foundKeywordInSentenceFlag, idx){
  //   // if keyword found, show Transcript lines
  //   if(foundKeywordInSentenceFlag){
  //     // add to array of Divs
  //     // sentencesHavingKeywordsDivRefArrayRef.current.push( sentenceHavingKeywordDivRef );
  //     // linesHavingKeywordsArrayRef.current.push( idx );
  //     // setNumberOfSentencesHavingKeyword( linesHavingKeywordsArrayRef.current.length );
  //     setNumberOfSentencesHavingKeyword( linesHavingKeywordsArrayRef.current.length );


  //     // show Sentences if not already
  //     setMinimizedSentences(false);
  //   }
  // }




  function isContainingKeyword(sentence, keywordsArray){
      let _foundKeyword = false;
      // if(showFindKeywords === true){
          if(keywordsArray.length > 0){
              const haystack = sentence.content.toLowerCase();//.replaceAll(/\.|\,/g, "");
              let haystackWordsArray = haystack.split(" ");

              for(let i=0;i<keywordsArray.length;i++){
                  const oneKeyword = keywordsArray[i];
                  if(oneKeyword.length > 0){
                      const needle = oneKeyword.toLowerCase();
                          
                      if( haystack.indexOf(needle) > -1 ) {
                          if (haystackWordsArray.find( element => 
                                                  element.indexOf(needle) === 0 
                                                  && needle.length >= element.length - 3
                                              ))
                          {
                              _foundKeyword = true;
                              // console.log("containsKeyword ", idx, oneKeyword)
                              break;
                          }
                      }
                  }
              }    
          }

      // }
      return _foundKeyword;
  }



  function moveToNextSentenceHavingKeyword(){
    // console.log("IntegratedView: moveToNextSentenceHavingKeyword: linesIdxHavingKeywordsArrayRef.current:", linesIdxHavingKeywordsArrayRef.current);
    if(numberOfSentencesHavingKeyword > 0){

      let _newPosInList = currentPositionInListOfSentenceHavingKeyword + 1;

      if (_newPosInList > numberOfSentencesHavingKeyword) {
        _newPosInList = 1;
      }

      // console.log("IntegratedView: moveToNextSentenceHavingKeyword: _newPosInList:", _newPosInList);
      setCurrentPositionInListOfSentenceHavingKeyword( _newPosInList );

      const lineIdx = linesIdxHavingKeywordsArrayRef.current[_newPosInList - 1];
      scrollLineIntoView( lineIdx );
    }
  }

  function moveToPreviousSentenceHavingKeyword(){
    // console.log("IntegratedView: moveToPreviousSentenceHavingKeyword: linesIdxHavingKeywordsArrayRef.current:", linesIdxHavingKeywordsArrayRef.current);
    if(numberOfSentencesHavingKeyword > 1){

      let _newPosInList = currentPositionInListOfSentenceHavingKeyword - 1;

      if (_newPosInList < 1) {
        _newPosInList = numberOfSentencesHavingKeyword;
      }

      // console.log("IntegratedView: moveToNextSentenceHavingKeyword: _newPosInList:", _newPosInList);
      setCurrentPositionInListOfSentenceHavingKeyword( _newPosInList );

      const lineIdx = linesIdxHavingKeywordsArrayRef.current[_newPosInList - 1];
      scrollLineIntoView( lineIdx );
    }
  }




  function scrollLineIntoView(i){
    const containerElement = sentencesListRef.current;
    if(containerElement){
      const targetSentenceElement = containerElement.children[i];
      if(targetSentenceElement){
        const pos = targetSentenceElement.offsetTop - containerElement.offsetTop;
        // console.log("position: ", pos)

        if(!!param_scrollToLineNumberSilentFlag){
          containerElement.scrollTo({
            top: pos,
            left: 0,
            behavior: 'instant'
          });

        }else{
          containerElement.scrollTo({
            top: pos,
            left: 0,
            behavior: 'smooth'
          });
          Utils.blinkTargetElement(targetSentenceElement);
        }
      }
    }
  }







  function onParamLockedEditFlag(){
    // console.log("IntegratedView: onLockedEditFlag: param_lockedEditFlag:", param_lockedEditFlag);

    let captureUpdate ={ id: param_oneCapture.id };

    let haveUpdateFlag = false;

    if(originalCaptureDB.current.class !== captureClass){
      captureUpdate.class = captureClass;
      haveUpdateFlag = true;
    }
    if(originalCaptureDB.current.title !== title){
      captureUpdate.title = title;
      haveUpdateFlag = true;
    }
    if(JSON.stringify(originalCaptureDB.current.speakers_list) !== JSON.stringify(speakersList)){
      captureUpdate.speakers_list = speakersList;
      haveUpdateFlag = true;
    }
    // if(originalCaptureDB.current.timestamp !== timestamp){
    //   captureUpdate.timestamp = timestamp;
    //   haveUpdateFlag = true;
    // }
    if(originalCaptureDB.current.timestamp !== timestampRef.current){
      captureUpdate.timestamp = timestampRef.current;
      haveUpdateFlag = true;
    }

    if( haveUpdateFlag ){
      param_FnUpdateCaptureDB( captureUpdate );
    }
  }


  
  function updateSentenceDB(idx, sentenceContent){
    // console.log("IntegratedView: updateSentenceDB: received parameter:", idx, sentenceContent);

    const _sentence = sentences[idx];
    _sentence.content = sentenceContent;
    
    const updatedSentences = [...sentences];
    updatedSentences[idx].content = sentenceContent;
    setSentences(updatedSentences);

    param_FnUpdateCaptureDB( {
          id: param_oneCapture.id,
          sentences_list: updatedSentences,
        } );
  }



  function updateNotesDB(updateNoteObj){

    // console.log("IntegratedView: updateNotesDB: received parameter:", updateNoteObj);

    let _notes = [...notes];
    let found = false;
    for(let i=0; i<_notes.length; i++){
      let oneNote = _notes[i];
      if(oneNote.start_line === updateNoteObj.start_line){
        oneNote.content = updateNoteObj.content;
        found = true;
        break;
      }
    }
    if(!found){
      _notes.push(updateNoteObj);
    }
    setNotes(_notes);

    param_FnUpdateCaptureDB( {
          id: param_oneCapture.id,
          notes: _notes
        } );
  }


  function deleteNoteDB(deleteNoteObj){
    // console.log("IntegratedView: deleteNoteDB: ", deleteNoteObj);

    const lineNo = deleteNoteObj.start_line;
    const _notes = notes.filter(item => item.start_line !== lineNo);
    setNotes(_notes);
    param_FnUpdateCaptureDB( {
      id: param_oneCapture.id,
      notes: _notes,
    } );

    delete sentences[deleteNoteObj.start_line].notes;
    setSentences([...sentences]);
  }



  function updateSummaryDB(updateObj){
    // console.log("IntegratedView: updateSummaryDB: received parameter:", updateObj);

    let _summaries = [...summaries];
    let found = false;
    for(let i=0; i<_summaries.length; i++){
      let oneItem = _summaries[i];
      if(oneItem.start_line === updateObj.start_line){
        oneItem.content = updateObj.content;
        found = true;
        break;
      }
    }
    if(!found){
      _summaries.push(updateObj);
    }
    setSummaries(_summaries);

    param_FnUpdateCaptureDB( {
          id: param_oneCapture.id,
          summary: _summaries
        } );
  }


  function deleteSummaryDB(deleteObj){
    // console.log("IntegratedView: deleteSummaryDB: ", deleteObj);

    const _summaries = summaries.filter(item => !( (item.start_line === deleteObj.start_line) 
                                                    && (item.end_line === deleteObj.end_line) ) );
    setSummaries(_summaries);
    param_FnUpdateCaptureDB( {
      id: param_oneCapture.id,
      summary: _summaries
    } );

    delete sentences[deleteObj.start_line].summary;
    setSentences([...sentences]);
  }








  function increaseHeightOnShowMore(){
    const containerElement = sentencesListRef.current;
    const totalHeight = containerElement.scrollHeight;
    let visibleHeight = containerElement.clientHeight;

    if (totalHeight > visibleHeight){
      visibleHeight += 500;
      containerElement.style.height = visibleHeight + "px";
      containerElement.style.maxHeight = visibleHeight + "px";
      // param_fn_onChangeHeight(visibleHeight);
    }
    if(totalHeight > visibleHeight){
      setDisplayShowMoreFlag(true);
    }else{
      visibleHeight = totalHeight;
      setDisplayShowMoreFlag(false);
    }
  }

  

  function displayHideShowMoreLink(){
    const containerElement = sentencesListRef.current;
    const totalHeight = containerElement.scrollHeight;
    let visibleHeight = containerElement.clientHeight;
    let _showMoreFlag = false;
    if(totalHeight > visibleHeight){
      _showMoreFlag = true;
    }else{
      _showMoreFlag = false;
    }
    setDisplayShowMoreFlag(_showMoreFlag);
    // console.log("displayHideShowMoreLink()", totalHeight, visibleHeight, _showMoreFlag);
  }


  const handleTranscriptTextCopyToClipboard = async () => {
    console.log("handleTranscriptTextCopyToClipboard");
    setColorOfCopyIcon('lightgreen');

    function formatDateTime(dateTime) {
        const date = new Date(dateTime);
      
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth returns 0-indexed month
        const day = String(date.getDate()).padStart(2, '0');
        const hour = String(date.getHours()).padStart(2, '0');
        const minute = String(date.getMinutes()).padStart(2, '0');
      
        return `${year}-${month}-${day}_${hour}-${minute}`;
      }

    const dateTime = new Date( parseInt(timestampRef.current) );
    const formattedTimestamp = formatDateTime(dateTime);
    const transcriptText = sentences.map((s,i) => s.content).join("\n");
    const output = formattedTimestamp + "\nTranscript:\n" + transcriptText
    await navigator.clipboard.writeText(output);

    setTimeout(() => {
      setColorOfCopyIcon("black");
    }, 1000);
  }

  return (
    <View
      as="div"
      width={param_width}
    >
              

      { !param_lockedEditFlag &&
        <div>
     
        <Flex
          direction="row"
          justifyContent="flex-start"
          width="100%"
          margin="0rem 1rem 0.5rem 1rem"
          alignItems="center"
          alignContent="space-between"
        >
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              marginLeft="5%"
              width="50%"
            >
              <View
                  fontWeight="bolder"
              >
                Date:
              </View>

              <Datetime 
                // https://github.com/arqex/react-datetime
                value={new Date( parseInt(timestampRef.current) )}
                // onChange={(newDateTimeMoment) => { setTimestamp(1000*newDateTimeMoment.unix()); }}
                onChange={(newDateTimeMoment) => { 
                  console.log("newDateTimeMoment:", newDateTimeMoment);
                  const newTimestamp = 1000*newDateTimeMoment.unix();
                  timestampRef.current = newTimestamp;
                }}

              />
            </Flex>
        </Flex>

        <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          marginLeft="1rem"
        >
            <View
              fontWeight="bolder"
            >
              Class:
            </View>
            <AutocompleteAvailableClasses
                param_value={captureClass}
                param_FnOnChange={(_className) => {setCaptureClass(_className)}}
                param_FnOnClear={() => {setCaptureClass("")}}
                param_FnOnSelect={(_className) => {setCaptureClass(_className)}}
                param_FnOnBlur={(event) => onParamLockedEditFlag(event)} 
            />
        </Flex>   


          <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            marginLeft="1rem"
            width="60%"
          >
            <View
                fontWeight="bolder"
            >
              Title:
            </View>
            <TextField
                width="100%"
                mylabel="lecture-title"
                // fontWeight="bolder"
                size="1.4rem"
                defaultValue={title}
                onChange={(event) => {setTitle(event.target.value)}}
                onBlur={(event) => onParamLockedEditFlag(event)}
            />
          </Flex>


        {!param_hideEnteringSpeakers &&
          <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              marginLeft="5%"
              width="50%"
            >
              <ListSpeakers
                  param_currentParticipants={speakersList}
                  param_suggestedParticipants={window.varClassesWithSpeakersList[captureClass]}
                  param_FnOnChange={(speakers) => {
                    // console.log("ListSpeakers: onChange:", speakers);
                    setSpeakersList(speakers);
                    // param_FnOnParticipantsListChange(speakers);
                  } }
                  param_FnOnSpeakerActiveChange = {(speaker, activeFlag) => {
                  }}
                />
            </Flex>
          }
        </div>
      }






      <Flex 
        direction="row"
      >
          <View as="span"
            fontSize="1.2rem"
            fontWeight="bold"
            margin="0rem 1rem"
          >
            Participants: 
          </View> 
          <View as="span"
            fontSize="1.2rem"
          >
            {speakersList.join(", ")}
          </View>
      </Flex>


      <Flex
        alignContent="flex-start"
      >
        <Heading level={5} alignSelf="flex-end" >
          Transcript:
        </Heading>

        <Flex
            alignItems="flex-end"
        >
            { !param_oneCapture?.sentences_list_preclean &&
                <Button
                    position="relative"
                    height="2rem"
                    isDisabled={cleaningTranscriptionStatus == "processing"}
                    onClick={() => {

                        if (!param_oneCapture?.sentences_list_preclean){
                          
                          console.log("IntegratedView: Clean transcript for this document ID: ", param_oneCapture?.id);
                          
                          async function cleanTranscription(){
                            setCleaningTranscriptionStatus("processing");
                            const resUpdatedDocument = await FetchDataUtils.postData('processGpt/cleanTranscription', {documentId:  param_oneCapture?.id});
                            if( 'error' in resUpdatedDocument ){
                              LoggingUtils.log(resUpdatedDocument);
                              return;
                            }
                            console.log("IntegratedView: Cleaned Transcription for this document ID: ", param_oneCapture?.id, resUpdatedDocument);
                            setCleaningTranscriptionStatus("done");

                            param_FnUpdateCapture(resUpdatedDocument);
                          }
                          cleanTranscription();

                        }
                    }}
                >
                    Clean Up
                    { cleaningTranscriptionStatus != null && cleaningTranscriptionStatus.toLowerCase() === "processing" &&
                        <View
                            position="absolute"
                            bottom="1rem"
                            left="1rem"
                        >
                            <img alt="" src="load.gif" className="loading" />
                        </View>
                    }
                </Button>
            }

            <BiCopyAlt 
                fontSize="2rem"
                width="12rem"
                onClick={handleTranscriptTextCopyToClipboard}
                color={colorOfCopyIcon}
            />

        </Flex>

      {/* FIND field */}
      <Flex
              direction="row"
              alignItems="flex-end"
              // alignContent="flex-start"
              wrap="nowrap"
              gap="0.5rem"
              width="85%%"
              marginLeft="2rem"
            >
          <View
            as="div"
            // margin="1rem"
            className="cls-cursor-pointer"
            onClick={() => toggleShowFindKeywords()}
          >
            <Flex
              direction="row"
              alignItems="center"
              alignContent="flex-start"
              wrap="nowrap"
              gap="0.5rem"
            >
                <BiSearchAlt fontSize="1.5rem"  />
                Find
            </Flex>
          </View>

          { !!showFindKeywords &&
            <>
              <TextField
                labelHidden
                width="70%"
                // padding="3px"
                // display={showFindKeywords ? "block" : "none"}
                value={findKeywordsTextFieldVal}
                onChange={(e) => onChangeFindKeywords(e.currentTarget.value)}
              />
              <Flex
                direction="row"
                alignItems="center"
                wrap="nowrap"
                gap="0.5rem"
              >
                  <View width="3rem">
                    { (numberOfSentencesHavingKeyword === 0) && 
                      <>No results</>
                    }
                    { (numberOfSentencesHavingKeyword > 0) && 
                      <>{currentPositionInListOfSentenceHavingKeyword} of {numberOfSentencesHavingKeyword}</>
                    }
                    
                  </View>
                  <View 
                    fontSize="1.5rem"
                    onClick={() => moveToNextSentenceHavingKeyword()}
                  >
                    <BsArrowDown />
                  </View>
                  <View 
                    fontSize="1.5rem"
                    onClick={() => moveToPreviousSentenceHavingKeyword()}
                  >
                    <BsArrowUp />
                  </View>
              </Flex>
     
            </>
          }
      </Flex>


        <Flex
            direction="row"
            justifyContent="right"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="wrap"
            gap="1.5rem"
            marginBottom="0.3rem"
            margin="0px 1rem 0.3rem auto"
          >
            <View as="div"
              className="cls-show-hide-switch cls-sentence"
              onClick={() => {
                setMinimizedSentences(!minimizedSentences);
              }}
            >
              <View fontSize="1.4rem">
                {!minimizedSentences && <BsEye />}
                {!!minimizedSentences && <BsEyeSlash /> }
              </View>
              <span>Transcript</span>
            </View>



            <View as="div"
              position="relative"
              className="cls-show-hide-switch cls-summary"
              onClick={() => {

                if (!summaries?.length){
                  // TODO
                  console.log("IntegratedView: create entire-summary summary for this document ID: ", param_oneCapture?.id);
                  
                  async function createAiEntireSummary(){
                    setSummarizationStatus("processing");

                    const res = await FetchDataUtils.postData('processGpt/summarize', {documentId:  param_oneCapture?.id});
                    if( 'error' in res ){
                      LoggingUtils.log(res);
                      return;
                    }
                    
                    console.log("IntegratedView: created entire-summary summary for this document ID: ", param_oneCapture?.id, res);
                    const newSummaryObj = {
                      start_line: res.start_line,
                      end_line: res.end_line,
                      content: res.content,
                      section_type: "entire-summary",
                    }
                    updateSummaryDB(newSummaryObj);

                    setMinimizedSummary(false); // show summary
                    setSummarizationStatus("done");
                  }
                  createAiEntireSummary();

                }
                setMinimizedSummary(!minimizedSummary);
              }}    
            >
              <View fontSize="1.4rem">
              
                {!summaries?.length && <BsPlusSquare />}
                {!!summaries?.length && !minimizedSummary && <BsEye />}
                {!!summaries?.length && !!minimizedSummary && <BsEyeSlash /> }

                <View as="span"
                  fontSize="1rem"
                  positionfont-size="1rem"
                  position="relative"
                  top="-0.3rem"
                  right="-1.2rem"
                >
                  {!summaries?.length?0:summaries.length}
                </View>
              </View>
              <span>Ai Summary</span>
              { summarizationStatus != null && summarizationStatus.toLowerCase() === "processing" &&
                <View
                  position="absolute"
                  bottom="1rem"
                  left="1rem"
                >
                  <img alt=""
                  src="load.gif" className="loading" />
                </View>
              }
            </View>


          </Flex>
      </Flex>


      <div className="cls-position-relative"
        ref={sentencesListParentRef}
      >
        <View
          ref={sentencesListRef}
          
          className={"cls-transcript-view"}
          as="div"
          backgroundColor="var(--amplify-colors-white)"
          borderRadius="3px"
          border="1px solid var(--amplify-colors-black)"
          boxShadow="1px 1px 3px 1px var(--amplify-colors-neutral-60)"
          padding="0.2rem"
        >
          {  
            sentences.map((sentence, idx) => {
        
              // console.log("sentence: ", sentence);
              // const containsKeyword = linesHavingKeywordsArrayRef.current.includes(idx);
              return (
                <div key={idx}>
                  <SentenceItemContainer
                      param_idx={idx}
                      param_oneSentence={sentence}

                      // param_showFindKeywords={showFindKeywords}
                      // param_findKeywordsArray={findKeywordsArrayRef.current}
                      // param_FnFoundKeyword={(_foundKeywordInSentenceFlag, _sentenceDivRef) => 
                      //         onFoundKeywordInSentence(_foundKeywordInSentenceFlag, _sentenceDivRef, idx)}
                      param_containsKeyword={linesHavingKeywordsArrayRef.current[idx]}

                      param_minimizedSentence={minimizedSentences}
                      // param_minimizedAnnouncements={minimizedAnnouncements}
                      param_minimizedSummary={minimizedSummary}
                      param_minimizedNotes={minimizedNotes}

                      param_lockedEditFlag={param_lockedEditFlag}

                      // param_FnUpdateAnnouncementsDB={updateAnnouncementsDB}
                      param_FnUpdateSentenceDB={(sentenceContent) => {
                        updateSentenceDB(idx, sentenceContent);
                      }}

                      param_FnUpdateSummaryDB={updateSummaryDB}
                      param_FnDeleteSummaryDB={deleteSummaryDB}

                      param_FnUpdateNoteDB={updateNotesDB}
                      param_FnDeleteNoteDB={deleteNoteDB}
                  />
                </div>
              );


            })
          }
          <View
            as="div"
            margin="5px"  
          >
          </View>
        </View>

      </div>



      { displayShowMoreFlag &&
          <View as="div"
                color="dodgerblue"
                textAlign="center"
                marginBottom="1rem"
                onClick={(e) => increaseHeightOnShowMore()}
          >
            show more
          </View>
      }
      





  </View>
  );
}
export default IntegratedView;
