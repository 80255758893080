

export function isString(variable) {
    return typeof variable === 'string' || variable instanceof String;
  }

//  01/24/2024 1:08 PM
export function timestampToFormattedDateTime(_ts){
    let _formattedTimestamp = "-";

    const timeFormatOptions = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: true,
        timeZone: 'America/Chicago'
    };
    try{
        const ts = parseInt(_ts);
        const threshold = 10000000000;
        let d;
        if(ts < threshold){
            d = new Date(ts*1000);
        }else{
            d = new Date(ts);
        }
        _formattedTimestamp = new Intl.DateTimeFormat('en-US', timeFormatOptions).format(d);
    }catch(ignore){};

    return _formattedTimestamp;
}

//
// ${year}-${month}-${day}_${hour}-${minute}-${second}
//
export function timestampToFormattedDateTime_Underscored(ts) {
    // Create a Date object using the timestamp
    let date;
    if (!isNaN(ts)){
        const threshold = 10000000000;
        if(ts < threshold){
            date = new Date(ts*1000);
        }else{
            date = new Date(ts);
        }
        
    }else{
        // Get the current timestamp in milliseconds using Date.now()
        const timestamp = Date.now();
        date = new Date(timestamp);
    }
    // Extract the individual date and time components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    const second = String(date.getSeconds()).padStart(2, '0');

    // Create the formatted date-time string
    const formattedDateTime = `${year}-${month}-${day}_${hour}-${minute}-${second}`;
    // console.log(formattedDateTime);

    return formattedDateTime;
}


