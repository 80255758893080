// components/OneSummaryView.js
import { useRef, useEffect, useState } from "react";

import { View, Flex,
  } from '@aws-amplify/ui-react';

import { GiCancel } from "react-icons/gi";

// import * as Utils from '../lib/utils';

import './transcript.css';
import { BsFillLockFill, BsFillUnlockFill, BsClipboardPlus } from "react-icons/bs";


function OneSummaryView({ 
    param_item,
    param_minimized,
    // param_lockedEditFlag,
    param_autoFocus,

    param_FnUpdateItemDB,
    param_FnDeleteItemDB,
  }) {


    

    const[ startLine, setStartLine] = useState(0);
    const[ endLine, setEndLine] = useState(0);
    const[ content, setContent] = useState("");
    const[ minimized, setMinimized] = useState(false);
    const[ lockedEditFlag, setLockedEditFlag] = useState(true);

    const originalContentRef = useRef({});

    const textArea = useRef();

    // let init = useRef(true);
    // useEffect(() => {
    //     if (init.current){
    //         // TODO
    //         init.current = false;
    //     }
    // }, []);



    useEffect(() => {

        let _content = param_item?.content;
        if(!_content){
            _content = "";
        }
        // Use a regular expression to find "**" preceded by exactly one newline character
        // and add additional \n
        _content = _content.replace(/(?<=\n)\*\*/g, '\n**');
        
        setContent(_content);
        originalContentRef.current = _content;


        let _start_line = param_item?.start_line;
        if(!_start_line){
            _start_line = 0;
        }
        setStartLine(_start_line);

        let _end_line = param_item?.end_line;
        if(!_end_line){
            _end_line = 0;
        }
        setEndLine(_end_line);

    }, [param_item]);



    useEffect(() => {
        setMinimized(param_minimized);

    }, [param_minimized]);


    useEffect(() => {
        if(minimized) return;
        // resize Note element
        textArea.current.style.height = 'auto';
        textArea.current.style.height = textArea.current.scrollHeight + 'px';

    }, [content, minimized]);


    function onChangeContent(event){
        setContent(event.target.value);
    }


    function updateItemDB(){
        if(content !== originalContentRef.current){
        // console.log("OneSummaryView: onBlur: changed Summary.Content: saving to DB", content);
        param_FnUpdateItemDB( { 
            start_line: startLine,
            end_line: endLine,
            content: content 
        } );
        }
    }


    function onDeleteItem(event){
        param_FnDeleteItemDB(param_item);
    }



    function onUnlockEdit(){
        setLockedEditFlag(false)
        setTimeout(() => {
            setLockedEditFlag(true)
        }, 7000);
    }

    
    return (

            <View
                marginTop="0.5rem"
                height={minimized ? "0" : null}
                border={minimized ? "1px solid greenyellow" : null}
                overflow="hidden"
                className={minimized ? "cls-unselectable": ''}
                onClick={(event) => event.stopPropagation()}
            >
                <Flex
                direction="row"
                width="100%"
                alignItems="center"
                >
                    <View as="div"
                    color="dodgerblue"
                    fontSize="0.8rem"
                    // onClick={(e) => callParent_fn_setLineNumberToShowInTranscript(note.start_line)}
                    >
                    [Summary: lines {startLine} to {endLine}]
                    </View>
                    <BsClipboardPlus
                    />

                    <View as="div"
                        color="darkgoldenrod"
                        fontSize="1.2rem"
                        >
                        { lockedEditFlag &&
                            <BsFillLockFill 
                            onClick={() => onUnlockEdit()}
                            />
                        }
                        { !lockedEditFlag &&
                            <BsFillUnlockFill 
                            onClick={() => setLockedEditFlag(true)}
                            />
                        }
                    </View>

                    {!lockedEditFlag &&
                        <View as="div"
                            marginLeft="auto"
                            marginRight="1rem"
                        >
                            <GiCancel
                            fontSize=".9rem"
                            color="red"
                            onClick={(event) => window.confirm('Delete Summary?') && onDeleteItem(event) }
                            />

                        </View>
                    }
                </Flex>

                {!minimized &&
                    <textarea 
                        ref={textArea}
                        autoFocus={!!param_autoFocus}
                        // className="cls-textarea cls-summary cls-entire-summary" .section_type 
                        className={`cls-textarea ${param_item?.section_type === "entire-summary" ? "cls-entire-summary" : "cls-summary"}`}
                        value={content} 
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event) => onChangeContent(event)}
                        onBlur={() => updateItemDB()} 
                    />
                }
       
            </View>
    );
  }
  export default OneSummaryView;