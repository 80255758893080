
import * as Utils from '../lib/utils';

import { Auth } from 'aws-amplify';


let baseURL = "/api/";
if (process.env.NODE_ENV === "development"){
  baseURL = "http://localhost:8000/api/";
  // baseURL = "https://studyslaps.com/api/";
}






export async function getProducts(){

  const currentSession = await Auth.currentSession();
  const accessToken = currentSession.getAccessToken();
  const jwt = accessToken.getJwtToken();
  const fetchOptions = {
    method: 'GET',
    headers: {
      'accesstoken': jwt,
      'Content-Type': 'application/json',
    },
  }
  console.log("billingUtils: getProducts(): fetch fetchOptions: ", fetchOptions);
  const response = await fetch( baseURL + 'products', fetchOptions);
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    console.log("billingUtils: getProducts(): ", message);
    return;
  }
  const responseJson = await response.json();
  console.log("billingUtils: getProducts(): services: ", responseJson);
  return responseJson;
}







export async function getPayments(){

  const currentSession = await Auth.currentSession();
  const accessToken = currentSession.getAccessToken();
  const jwt = accessToken.getJwtToken();
  const fetchOptions = {
    method: 'GET',
    headers: {
      'accesstoken': jwt,
      'Content-Type': 'application/json',
    },
  }
  console.log("billingUtils: getPayments(): fetch fetchOptions: ", fetchOptions);
  const response = await fetch( baseURL + 'payments', fetchOptions);
  if (!response.ok) {
    console.log("billingUtils: getPayments(): ", response.message);
    return;
  }
  const responseJson = await response.json();
  console.log("billingUtils: getPayments(): services: ", responseJson);
  return responseJson;
}
  






  export async function startStripeCheckoutSession( myMetadata ){ // POST '/api/create-checkout-session'
    
    const currentSession = await Auth.currentSession();
    const accessToken = currentSession.getAccessToken();
    const jwt = accessToken.getJwtToken();
  
    const fetchOptions = {
      method: 'POST',
      headers: {
        'accesstoken': jwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(myMetadata),
    }

    console.log("billingUtils: startStripeCheckoutSession(): fetch fetchOptions: ", fetchOptions);
    const response = await fetch( baseURL + 'create-checkout-session', fetchOptions);

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      console.log("billingUtils: startStripeCheckoutSession(): ", message);
      return false;
    }
    const responseJson = await response.json();
    console.log("billingUtils: startStripeCheckoutSession(): services: ", responseJson); // {url, session_id}
    
    return responseJson;
    // window.location.href = responseJson.url;
  }


