// components/OneNoteView.js
import { useRef, useEffect, useState } from "react";

import { View, Flex,
  } from '@aws-amplify/ui-react';

import { MdOutlineClose } from "react-icons/md";
import { GiCancel } from "react-icons/gi";

import * as Utils from '../lib/utils';

import './transcript.css';
import { BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";


function OneNoteView({ 
    param_note,
    param_minimized,
    param_lockedEditFlag,
    param_autoFocus,

    param_FnUpdateNoteDB,
    param_FnDeleteNoteDB,
  }) {


    let init = useRef(true);

    const[ startLine, setStartLine] = useState(0);
    const[ noteContent, setNoteContent] = useState("");
    const[ minimized, setMinimized] = useState(false);
    const[ lockedEditFlag, setLockedEditFlag] = useState(true);

    const originalNoteContentRef = useRef({});


    const noteTextArea = useRef();


    useEffect(() => {
        if (init.current){
            // TODO
            init.current = false;
        }
    }, []);



    useEffect(() => {

        let _content = param_note?.content;
        if(!_content){
            _content = "";
        }
        setNoteContent(_content);
        originalNoteContentRef.current = _content;


        let _start_line = param_note?.start_line;
        if(!_start_line){
            _start_line = 0;
        }
        setStartLine(_start_line);

    }, [param_note]);


    useEffect(() => {
        // console.log("OneNoteView: useEffect: param_lockedEditFlag:", param_lockedEditFlag);

        if(param_lockedEditFlag === undefined || param_lockedEditFlag === null){
            return;
        }
        setLockedEditFlag(param_lockedEditFlag);

    }, [param_lockedEditFlag]);
    

    useEffect(() => {
        setMinimized(param_minimized);

    }, [param_minimized]);


    useEffect(() => {
        if(minimized) return;
        // resize Note element
        noteTextArea.current.style.height = 'auto';
        noteTextArea.current.style.height = noteTextArea.current.scrollHeight + 'px';

    }, [noteContent, minimized]);




    function onChangeNoteContent(event){
        setNoteContent(event.target.value);
    }


    function updateNoteDB(){
        if(noteContent !== originalNoteContentRef.current){
        // console.log("OneNoteView: onBlur: changed Note.Content: saving to DB", noteContent);
        param_FnUpdateNoteDB( { 
            start_line: startLine,
            content: noteContent 
        } );
        }
    }


    function onDeleteNote(event){
        param_FnDeleteNoteDB(param_note);
    }



    function onUnlockEdit(){
        setLockedEditFlag(false)
        setTimeout(() => {
            setLockedEditFlag(true)
        }, 7000);
    }


    
    return (

            <View
                marginTop="0.5rem"
                height={minimized ? "0" : null}
                border={minimized ? "1px solid bisque" : null}
                overflow="hidden"
                className={minimized ? "cls-unselectable": ''}
            >
                
                <Flex
                direction="row"
                width="100%"
                alignItems="center"
                >
                    <View as="div"
                    color="dodgerblue"
                    fontSize="0.8rem"
                    // onClick={(e) => callParent_fn_setLineNumberToShowInTranscript(note.start_line)}
                    >
                    [Note: line {startLine}]
                    </View>

                    { (param_lockedEditFlag === undefined || param_lockedEditFlag === null) &&
                        <View as="div"
                            color="darkgoldenrod"
                            fontSize="1.2rem"
                            >
                            { lockedEditFlag &&
                                <BsFillLockFill 
                                onClick={() => onUnlockEdit()}
                                />
                            }
                            { !lockedEditFlag &&
                                <BsFillUnlockFill 
                                onClick={() => setLockedEditFlag(true)}
                                />
                            }
                        </View>
                    }

                    {!lockedEditFlag &&
                        <View as="div"
                            marginLeft="auto"
                            marginRight="1rem"
                        >
                            <GiCancel
                            fontSize=".9rem"
                            color="red"
                            onClick={(event) => window.confirm('Delete Note?') && onDeleteNote(event) }
                            />

                        </View>
                    }
                </Flex>


                {!minimized &&
                    <textarea 
                        ref={noteTextArea}
                        autoFocus={!!param_autoFocus}
                        className="cls-note-textarea"
                        value={noteContent} 
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event) => onChangeNoteContent(event)}
                        onBlur={() => updateNoteDB()} 
                    />
                }

            </View>
    );
  }
  export default OneNoteView;