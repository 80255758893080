// components/BillingView.js

import { useState, useEffect, useRef } from "react";
import { Flex, View, Heading,
  Tabs, TabItem, TextField, Button, CheckboxField
 } from '@aws-amplify/ui-react';

import * as Utils from '../lib/utils';
import * as InvoicesUtils from '../lib/invoicesUtils';
import * as BillingUtils from '../lib/billingUtils';

import { AiOutlineLoading } from "react-icons/ai";

import './transcript.css';
// import { getInvoices } from "../graphql/queries";
import { v4 as uuidv4 } from 'uuid';



function BillingView({
  param_userAttributes,
  param_userEmail,
  param_minutesLeft,
  param_subscriptionExpiration,
  param_FnOnPurchase, // param_FnOnPurchase(purschasedSubscriptions, purchasedMinutes)
}) {

  const successfulPaymentMessage = "Payment processing... The page will automatically refresh in 5 seconds.";
  
  const [tabIndex, setTabIndex] = useState(null);   
  const [paymentHistoryTabMessage, setPaymentHistoryTabMessage] = useState("");
  // const [invoices, setInvoices] = useState([]);    
  const [payments, setPayments] = useState([]);   
  const [products, setProducts] = useState([]); 

  const [disabledCheckoutFlag, setDisabledCheckoutFlag] = useState(true);     
  const [showCheckoutSpinnerFlag, setShowCheckoutSpinnerFlag] = useState(false);
  

  let init = useRef(true);
  useEffect(() => {
    if (init.current){

      // console.log(window.location);
      // console.log("BillingView: useEffect: userAttributes:", param_userAttributes);

      // if "#BillingSuccess" wait 10sec then fetch Payments 
      if(window.location.hash.indexOf("Success") > -1){
        setPaymentHistoryTabMessage(successfulPaymentMessage);
        setTabIndex(2); // back to 'Payment History' tab
        setTimeout(function(){
          async function fetchPayments(){
            const _payments = await BillingUtils.getPayments();
            setPayments(_payments);
            setPaymentHistoryTabMessage("");
          }
          fetchPayments();
        }, 5000);
      }else{
        setPaymentHistoryTabMessage("");
      }

      // if "#BillingCancel" go back to the Checkout tab
      if(window.location.hash.indexOf("Cancel") > -1){
        setTabIndex(1); // back to Checkout tab
      }


      async function getProducts(){
        const products = await BillingUtils.getProducts();
        // sort by 'price_object.unit_amount'
        products.sort((a, b) => (a.price_object.unit_amount > b.price_object.unit_amount) ? 1 : -1);
        setProducts(products);
      }
      getProducts();

      // async function fetchPayments(){
      //   const _payments = await BillingUtils.getPayments();
      //   setPayments(_payments);
      // }
      // fetchPayments();

      init.current = false;
    }
  }, []);



  async function createNewInvoice(){

  }





  async function doCheckout(){

    setDisabledCheckoutFlag(true);
    setShowCheckoutSpinnerFlag(true);

    // const lineItems = lineItems;
    let lineItems = [];
    let productsData = [];

    for(let i=0; i<products.length; i++){
      const oneProduct = products[i];
      if( oneProduct.quantity > 0){

        const oneLineItem = {
          price: oneProduct.default_price,
          quantity: oneProduct.quantity,
        };
        lineItems.push(oneLineItem);

        const oneProductData = {
          id: oneProduct.id,
          description: oneProduct.description,
          name: oneProduct.name,
          // price: oneProduct.default_price,
          quantity: oneProduct.quantity, // already there
          // currency: oneProduct.price_object.currency,
          unit_amount: oneProduct.price_object.unit_amount,
          amount: oneProduct.price_object.unit_amount * oneProduct.quantity,
          minutes: oneProduct?.metadata?.minutes,
          months: oneProduct?.metadata?.months,
        };
        productsData.push(oneProductData);
      }
    }

    if( lineItems.length > 0){


      const newInvoiceTableId = uuidv4();

      // start Stripe checkout session
      const stripeCheckoutSessionMetadata = {
        username: param_userAttributes.username, // api will overwrite with Cognito logged-in user value
        invoiceTableId: newInvoiceTableId,
        email: param_userEmail,
        lineItems: lineItems,
        productsData: productsData,
        url: window.location.origin,
      }
      let invoiceTableItemStatus = "pre_checkout";
      let newCheckoutSessionData = null; // {url, session_id}
      try{
        newCheckoutSessionData = await BillingUtils.startStripeCheckoutSession( stripeCheckoutSessionMetadata ); // myMetadata
      }catch(err){
        invoiceTableItemStatus = "pre_checkout_failed";
      }

      // create an Invoice Table item
      const invoiceTableItem = {
        id: newInvoiceTableId,
        timestamp: Date.now(),
        customer_id: param_userAttributes.sub,
        status: invoiceTableItemStatus,
        invoice_data: {
          ...stripeCheckoutSessionMetadata,
          checkout_session_id: newCheckoutSessionData.session_id,
        }
      };
      const newInvoiceTableItem = await InvoicesUtils.insertNewInvoiceDB(invoiceTableItem);
      // console.log("BillingView: insertNewInvoiceDB: created:", newInvoiceTableItem);

      if (!!newCheckoutSessionData?.url){
        window.location.href = newCheckoutSessionData.url;
      }
    }   
  }






  function toggleProductSelection(checked, idx){
    let _products = [...products];
    _products[idx].quantity = checked? 1 : 0;
    setProducts(_products);

    let isShoppingCartEmpty = true;
    for(let i=0; i<_products.length; i++){
      if( _products[i].quantity > 0){
        isShoppingCartEmpty = false;
        break;
      }
    }
    if(isShoppingCartEmpty){
      setDisabledCheckoutFlag(true);
    }else{
      setDisabledCheckoutFlag(false);
    }
  }


  async function onChangeTab(idx){

    if(idx === "2"){
      const _payments = await BillingUtils.getPayments();
      setPayments(_payments);
    }
  }




  return (
    <Flex
        direction="column"
        justifyContent="left"
        alignItems="stretch"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
        overflow="hidden"
        margin="1rem 1rem 3rem 1rem"
    >
          <Heading
            level={3} 
          >
            Billing
          </Heading>
          <Flex direction="column" gap="2rem">
            <Tabs currentIndex={tabIndex} onChange={(i) => {setTabIndex(i); onChangeTab(i);} }>


              <TabItem title="My Subscription">
                <Flex
                  direction="column" 
                  gap="2rem"
                  margin="2rem"
                >
                  <Flex
                    direction="row" 
                    gap="5rem"
                    margin="1rem"
                    alignItems="center"
                  >
                    <View fontWeight="bold">
                      Subscribed until:
                    </View>
                    <View>
                      {Utils.dateTimeStringToFormattedDate(param_subscriptionExpiration)}
                    </View>
                    <Button
                      variation="primary"
                      onClick={() => {toggleProductSelection(true, 0); setTabIndex(1);}}
                    >
                      Add Subscription
                    </Button>
                  </Flex>

                  <Flex
                    direction="row" 
                    gap="5rem"
                    margin="1rem"
                    alignItems="center"
                  >
                    <View fontWeight="bold">
                      Capture Minutes:
                    </View>
                    <View>
                      {param_minutesLeft}
                    </View>
                    <Button
                      variation="primary"
                      onClick={() => {toggleProductSelection(true, 1); setTabIndex(1);}}
                    >
                      Add Capture Minutes
                    </Button>
                  </Flex>
                </Flex>
              </TabItem>


              <TabItem title="Purchase Subscription & Minutes">
                {products.map((oneProduct, idx) => {
                  
                    return (
                      <Flex key={idx}
                        direction="row" gap="1.5rem"
                        margin="3rem"
                        alignItems="flex-start"
                      >
                        <CheckboxField
                          name="subscribe-controlled"
                          value="yes"
                          size="large"
                          checked={(oneProduct.quantity > 0)}
                          onChange={(e) => toggleProductSelection(e.target.checked, idx)}
                        />
                        <View>
                          <View>
                            {oneProduct.name}
                          </View>
                          <View color="darkgrey">
                            ${oneProduct.price_object.unit_amount/100}
                          </View>
                        </View>
                      
                      </Flex>
                    )
                  })
                }
                  <Button 
                    isDisabled={disabledCheckoutFlag}
                    onClick={() => {doCheckout()}}
                    variation="primary"
                    margin="0 4rem"
                    width="15rem"
                  >
                    Checkout
                    {!!showCheckoutSpinnerFlag &&
                      <img height="20px" src="load.gif" className="loading" />
                    }
                  </Button>
              </TabItem>
              
              <TabItem title="Payment History">
              
              <Flex
                direction="column" 
                gap="1rem"
                overflow="scroll"
                height="50rem"
              >
                  <View as="div"
                  >
                    {paymentHistoryTabMessage}
                  </View>

                  <View margin="0rem">
                  { payments.map((onePayment, idx) => {
                    const productsData = Utils.safeJSONParse(onePayment.metadata.products_data);
                    // console.log("BillingView: payments.map: productsData: ", productsData);
                    return (
                      <View key={idx} margin="0 0 3rem 0">

                        <Flex 
                          direction="row" gap="1.5rem"
                          // margin="1rem 0 0.5rem 0"
                          alignItems="flex-start"
                        >
                          {/* <View>
                            {onePayment.status}
                          </View> */}
                          <View fontWeight="bold">
                            { Utils.timestampToFormattedDateTime(onePayment.created * 1000) }
                          </View>
                          <View
                            margin="0 0 0 auto"
                          >
                            <View as="span"
                              fontWeight="bolder"
                            >
                             Total:
                            </View>
                              ${onePayment.amount/100}.00
                          </View>
                          <View>
                            {onePayment.description}
                          </View>
                        </Flex>


                        <View width="30rem">
                            
                          { !!productsData && productsData.map((oneProduct, product_idx) => {
                              return (
                                <Flex key={product_idx}
                                  margin="0.5rem 0 0 2rem"
                                  direction="column"
                                  gap="0rem"
                                  style={{
                                    borderBottom: '1px solid lightgrey'
                                  }}
                                >
                                  {/* <View>
                                    {oneProduct?.quantity}
                                  </View> */}
                                  <View>
                                    {oneProduct?.name}
                                  </View>
                                  <View>
                                    ${oneProduct.amount/100}.00
                                  </View>
                                </Flex>
                              )
                            })
                          }
                        </View>
                        
                      </View>
                    )
                    })
                  }  
                  </View>
              </Flex>            
              </TabItem>


            </Tabs>
          </Flex>


    </Flex>
  );
}

export default BillingView;
