// components/CaptureLecture.js
import { v4 as uuidv4 } from 'uuid';

import { useState, useEffect, useRef } from "react";
import RecorderTranscriptor from './RecorderTranscriptor';
import {
    View, Heading,
} from '@aws-amplify/ui-react';
import {
    BiCaretRight, BiCaretDown
} from "react-icons/bi";

// import OneNoteView from './OneNoteView';
import IntegratedView from "./IntegratedView";
// import CreateOneNoteContainer from "./CreateOneNoteContainer";


import * as FetchDataUtils from '../lib/fetchDataUtils';
// import * as LoggingUtils from '../lib/loggingUtils';



import './transcript.css';

import QueryAi from "./QueryAi";



function CaptureLecture({
    param_availableClassesList,
    param_FnOnStartCapturing,
    param_FnOnEndCapturing,
}) {



    // const blankCaptureRecord = {
    //   // id: uuidv4(),
    //   timestamp: Date.now(),
    //   // title: "",
    //   // class: "",
    //   // sentences_list: [],
    //   // notes: [],
    //   // summaries: [],
    //   // announcements: [],
    //   // purchasesAndUsagesId: uuidv4(),
    // };



    const captureIdRef = useRef(uuidv4());
    const captureTimestampRef = useRef(Date.now());
    const purchasesAndUsagesIdRef = useRef(uuidv4());
    const sentencesListRef = useRef([]);
    const capturedSecondsRef = useRef(0);
    const speakersListRef = useRef([]);
    const speakersActivityRef = useRef([]);
    const classNameRef = useRef();


    const summarytRef = useRef([]);
    const notesRef = useRef([]);

    const [capture, setCapture] = useState({
        title: "",
        class: "",
        sentences_list: [],
        speakers_list: [],
    });


    const [scrollToLineNumber, setScrollToLineNumber] = useState(null);

    const [showingQueryAiFlag, setShowingQueryAiFlag] = useState(false);


    // let init = useRef(true);
    // useEffect(() => {
    //   if (init.current){
    //     //TODO
    //     init.current = false;
    //   }
    // }, []);


    // useEffect(() => {
    //     console.log("CaptureLecture: useEffect(): capture", capture);

    // }, [capture]);



    async function onStartCapturing() {

        captureIdRef.current = uuidv4();
        captureTimestampRef.current = Date.now();
        purchasesAndUsagesIdRef.current = uuidv4();
        sentencesListRef.current = [];
        capturedSecondsRef.current = 0;

        // speakersListRef.current = [];
        speakersActivityRef.current = [];

        summarytRef.current = [];
        notesRef.current = [];

        const newCapture = {
            id: captureIdRef.current,
            timestamp: captureTimestampRef.current,
            title: capture?.title,
            class: capture?.class,
            purchasesAndUsagesId: purchasesAndUsagesIdRef.current,
            sentences_list: [],
            speakers_list: speakersListRef.current, // user may pre-populate
            speakers_activity: [],
            summary: [],
            notes: [],
        };

        const retCapture = await saveCurrentCaptureDB(newCapture);
        const _updatedCapture = { ...capture, ...retCapture };
        // console.log("CaptureLecture: onStartCapturing: _updatedCapture", _updatedCapture);
        setCapture(_updatedCapture);

        await param_FnOnStartCapturing();
    }




    async function onEndCapturing(capturedSeconds, spellingContent) {
        // console.log("CaptureLecture: onEndCapturing: capture", capture);
        capturedSecondsRef.current = capturedSeconds;

        let savedCaptureRecord = null; // nothing has been captured - exited without clicking on Rec. button
        if (capturedSeconds > 0) {
            savedCaptureRecord = await saveCurrentCaptureDB({
                sentences_list: sentencesListRef.current,
                speakers_list: speakersListRef.current,
                speakers_activity: speakersActivityRef.current,
                summary: summarytRef.current,
                notes: notesRef.current,
                spelling: spellingContent,
            });
        }

        param_FnOnEndCapturing(savedCaptureRecord); // ListLecture will add this capture to the 'items[]'
    }







    async function saveCurrentCaptureDB(updateData) {
        // console.log("CaptureLecture: saveCurrentCaptureDB: 'fetch' POST capture update data:", updateData);
        // console.log("CaptureLecture: saveCurrentCaptureDB: Stack", (new Error).stack);

        let _updateData = {
            id: captureIdRef.current,
            timestamp: captureTimestampRef.current,
            purchasesAndUsagesId: purchasesAndUsagesIdRef.current,
            captured_seconds: capturedSecondsRef.current,
            class: capture.class === null ? "" : capture.class,
            speakers_list: speakersListRef.current,
            speakers_activity: speakersActivityRef.current,

            __typename: "PrivateLectures",
            createdAt: new Date(captureTimestampRef.current).toISOString(),
            updatedAt: new Date(Date.now()).toISOString(),
        };

        if (!!updateData) {
            _updateData = { ..._updateData, ...updateData };
        }

        if (!!_updateData.sentences_list) {
            let content = "";
            for (let i = 0; i < _updateData.sentences_list.length; i++) {
                content += _updateData.sentences_list[i].content;
            }
            _updateData.tokens = Math.ceil(content.length * 0.37);
        }

        // console.log("CaptureLecture: saveCurrentCaptureDB: 'fetch' POST sent _updateData:", _updateData);
        let res = await FetchDataUtils.postData('captures', _updateData, window.isEncrypted);
        if (("class" in res) && (res.class === null)) { // if record class === NULL, write ""
            res.class = "";
        }
        // console.log("CaptureLecture: saveCurrentCaptureDB: 'fetch' POST capture update data RETURNED:", res);

        if ('error' in res) { // new way of saving failed by returning 'null'
            console.log("ERROR CaptureLecture: saveCurrentCaptureDB: FAILED to update data:", res);
            res = null;
        }
        return res;
    }



    async function updateCaptureDB(updateData) {
        // console.log("CaptureLecture: updateCaptureDB(): updateData:", updateData);

        if (!!updateData?.class) {
            classNameRef.current = updateData.class;
        }

        if (!!updateData?.notes) {
            notesRef.current = updateData.notes;
        }
        if (!!updateData?.summary) {
            summarytRef.current = updateData.summary;
        }
        if (!!updateData?.speakers_list) {
            speakersListRef.current = updateData.speakers_list;
        }

        // if ID is Undefined =>  NOT Ready for Saving, 
        //    start recording to generate ID
        if (updateData.id != null) { // Ready to Save - has ID

            let savedCaptureRecord = null;
            savedCaptureRecord = await saveCurrentCaptureDB(updateData);
            if (!savedCaptureRecord) { // new way of saving failed by returning 'null'
                console.log("CaptureLecture: updateCaptureDB(): saveCurrentCaptureDB failed... ");
                return;
            }
        }

        // update local 'capture' State - which will again re-render IntegratedView
        const _updatedCapture = {
            ...capture,
            ...updateData,
        };
        // console.log("CaptureLecture: setCapture(): _updatedCapture:", _updatedCapture);
        setCapture(_updatedCapture);
        return _updatedCapture;
    }






    const onTranscriptData = (sentences, capSec) => {

        // const newSentence = {
        //     content: data,
        //     start_time: startTime,
        //     end_time: endTime
        //   };
        // console.log("CaptureLecture: onTranscriptData(): newSentence:", newSentence);


        // //
        // //
        // // Insert/replace using start_time, end_time
        // // Step 1: Remove any overlapping sentences
        // const sentences = sentencesListRef.current.filter(_sentence => {
        //   // Keep sentences that don't overlap with newSentence
        //   return _sentence.end_time <= newSentence.start_time || _sentence.start_time >= newSentence.end_time;
        // });
        // // Step 2: Determine the correct position to insert the new sentence
        // let insertPos = sentences.findIndex(_sentence => _sentence.start_time > newSentence.start_time);
        // if (insertPos === -1) insertPos = sentences.length; // Append if no larger start_time is found
        // // Step 3: Insert the new sentence
        // sentences.splice(insertPos, 0, newSentence);



        sentencesListRef.current = sentences;
        capturedSecondsRef.current = capSec;

        const _update = {
            captured_seconds: capturedSecondsRef.current,
            sentences_list: [...sentencesListRef.current],
        };

        // save to DB
        const _capture = saveCurrentCaptureDB(_update);

        // save to local State variable
        let _updatedCapture = { ...capture, ..._update };
        // console.log("CaptureLecture: setCapture(): _updatedCapture:", _updatedCapture);
        setCapture(_updatedCapture);

        setTimeout(function () {
            setScrollToLineNumber(sentencesListRef.current.length);
        }, 200);

        return _capture;
    }




    //   function formatTimestampAsDateTime(_timestamp){

    //     let _formattedTimestamp = "-";
    //     try{
    //       const d = new Date( parseInt(_timestamp) );
    //       const timeFormatOptions = {
    //         year: 'numeric', month: 'numeric', day: 'numeric',
    //         hour: 'numeric', minute: 'numeric', second: 'numeric',
    //         hour12: true,
    //         timeZone: 'America/Chicago'
    //       };
    //       _formattedTimestamp = new Intl.DateTimeFormat('en-US', timeFormatOptions).format(d);
    //     }catch(ignore){};

    //     return _formattedTimestamp;
    //   }


    return (
        <>
            <Heading
                level={2}
                margin="3rem 0 3rem 3rem"
            >
                Capturing
            </Heading>

            <RecorderTranscriptor
                // param_captureTimestamp={captureTimestampRef.current}
                param_captureClass={classNameRef.current}
                param_spelling={""} // TODO list of words from documents in the same class
                param_FnOnTranscriptData={onTranscriptData} // (sentences, capSec)
                param_FnOnParticipantsListChange={(participantsList) => {
                    // console.log("CaptureLecture: param_FnOnParticipantsListChange: speakersList", speakersList);
                    speakersListRef.current = participantsList;

                    const _updatedCapture = { ...capture, ...{ speakers_list: participantsList } };
                    // console.log("CaptureLecture: setCapture(): _updatedCapture:", _updatedCapture);
                    setCapture(_updatedCapture);
                }}
                param_FnOnSpeakersActivityChange={(speakersActivity) => {
                    // console.log("debug")
                    speakersActivityRef.current = speakersActivity;
                    saveCurrentCaptureDB({ speakers_activity: speakersActivity });
                }}
                param_FnOnStartCapturing={onStartCapturing}
                param_FnOnEndCapturing={onEndCapturing}
            />


            {/* <View marginBottom="1rem" 
            color="var(--amplify-colors-teal-80)"
          >
            {formatTimestampAsDateTime(capture.timestamp)}
          </View> */}

            <View
                className="cls-flex-horizontal"
                maxHeight="60rem"
            >
                <View
                    className="cls-flex-horizontal-child cls-overflow-Y-scroll"
                    maxHeight="-webkit-fill-available"
                >
                    <IntegratedView

                        // param_availableClassesList={param_availableClassesList}
                        param_oneCapture={capture}
                        param_lockedEditFlag={false}

                        param_hideEnteringSpeakers={true}
                        param_minimizedSentences={false}
                        // param_minimizedAnnouncements={false}
                        param_minimizedSummary={false}
                        param_minimizedNotes={false}

                        param_FnUpdateCaptureDB={updateCaptureDB}
                        param_scrollToLineNumber={scrollToLineNumber}
                        param_scrollToLineNumberSilentFlag={true}
                    />
                </View>

                {/* {!showingQueryAiFlag && */}
                    <View as="div"
                        margin="1rem 0 0 1rem"
                        fontSize="1.4rem"
                        fontWeight={`${!showingQueryAiFlag ? 'normal' : 'bold'}`}
                        className={`${!showingQueryAiFlag ? 'cls-background-greenyellow-dim' : 'cls-background-red-dim'} cls-show-hide-switch cls-text-center `}
                        width="15rem"
                        onClick={() => {
                            setShowingQueryAiFlag(!showingQueryAiFlag);
                        }}
                    >
                        {!showingQueryAiFlag &&
                            <div>
                                <BiCaretRight />
                                Ask Ai
                            </div>
                        }
                        {!!showingQueryAiFlag &&
                            <div>
                                <BiCaretDown />
                                Exit Ask Ai
                            </div>
                        }
                    </View>
                {/* } */}

                <View
                    className="cls-flex-horizontal-child"
                    style={{ display: showingQueryAiFlag ? 'block' : 'none' }}
                >
                    <QueryAi
                        param_defaultQuery={"Summarize as bullet points."}
                        param_selections={{ [classNameRef.current ?? ""] : [captureIdRef.current] }}
                        param_FnExitQueryAi={() => {
                            setShowingQueryAiFlag(false);
                        }}
                    />
                </View>

            </View>
        </>
    );
}

export default CaptureLecture;
