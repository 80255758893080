import { API, graphqlOperation } from 'aws-amplify';

import { createPrivateLectures, createPurchasesAndUsages, createEventLogs } from '../graphql/mutations';
import { updatePrivateLectures } from '../graphql/mutations';
import { listPrivateLectures, listPurchasesAndUsages } from '../graphql/queries';
import { deletePrivateLectures } from '../graphql/mutations';
import * as Utils from './utils';
import { v4 as uuidv4 } from 'uuid';


import { Auth } from 'aws-amplify';
let baseURL = "/api/";
if (process.env.NODE_ENV === "development"){
  baseURL = "http://localhost:8000/api/";
  // baseURL = "https://studyslaps.com/api/";
}





export async function insertEventLogDB(eventLogObj){
// id
// owner
// level
// source
// message

  let newEventLogData = {};
  if(!!eventLogObj){
    newEventLogData = {...eventLogObj};
  }

  if(!eventLogObj?.id){
    eventLogObj.id = uuidv4();
  }

  try{
    eventLogObj.owner = window.varUser.attributes.sub + "::" + window.varUser.attributes.sub;
  }catch(error){
    console.log("ERROR getting global userwindow.variable.window.variable.:", error);
  }

  eventLogObj.createdAt = new Date().toISOString();
  eventLogObj.source = "browser " + navigator.userAgent;

  if(!!eventLogObj?.message){
    if (typeof eventLogObj.message !== "string"){
      eventLogObj.message = JSON.stringify(eventLogObj.message);
    }
  }
  
  let newEventLog = {};
  try{
    const response = await API.graphql(graphqlOperation(createEventLogs, 
        { input: eventLogObj })
      );
    // console.log("New EventLog inserted into DB response: ", response);
    newEventLog = response.data.createEventLogs;
    // console.log("New EventLog: ", newEventLog);

  }catch(error){
    console.log("ERROR New EventLog inserting into DB response: ", error, newEventLog);
    throw error;
  }
  return newEventLog;
}








//
//
//
export function unpackCaptureRecordFromDbFormat(oneCaptureFromDb){
  if(!oneCaptureFromDb?.id){
    console.warn("WARNING While Unpacking from DB: Record missing ID: ", oneCaptureFromDb);
  }

  let unpackedData = {...oneCaptureFromDb};

  unpackedData.transcript = Utils.safeJSONParse(oneCaptureFromDb.transcript, {});
  unpackedData.comments = Utils.safeJSONParse(oneCaptureFromDb.comments, []);
  unpackedData.announcements = Utils.safeJSONParse(oneCaptureFromDb.announcements, []);
  unpackedData.outline = Utils.safeJSONParse(oneCaptureFromDb.outline, []);
  unpackedData.sentences_list = Utils.safeJSONParse(oneCaptureFromDb.sentences_list, []);
  unpackedData.notes = Utils.safeJSONParse(oneCaptureFromDb.notes, []);
  unpackedData.clean_sentences_list = Utils.safeJSONParse(oneCaptureFromDb.clean_sentences_list, []);
  unpackedData.summary = Utils.safeJSONParse(oneCaptureFromDb.summary, []);
  unpackedData.flash_cards = Utils.safeJSONParse(oneCaptureFromDb.flash_cards, []);

  return unpackedData;
}

export function packCaptureRecordToDbFormat(oneCapture){
  if(!oneCapture?.id){
    console.warn("WARNING While Packing for DB: Record missing ID: ", oneCapture);
  }

  let packedData = { id: oneCapture.id };

  if('title' in oneCapture)
    packedData.title = oneCapture.title;
  if('class' in oneCapture)
    packedData.class = oneCapture.class;
  if('timestamp' in oneCapture)
    packedData.timestamp = oneCapture.timestamp;
  if('captured_seconds' in oneCapture)
    packedData.captured_seconds = oneCapture.captured_seconds;

  if('transcript' in oneCapture)
    packedData.transcript = JSON.stringify( oneCapture.transcript, null, 2);
  if('comments' in oneCapture)
    packedData.comments = JSON.stringify( oneCapture.comments, null, 2);
  if('announcements' in oneCapture)
    packedData.announcements = JSON.stringify( oneCapture.announcements, null, 2);
  if('outline' in oneCapture)
    packedData.outline = JSON.stringify( oneCapture.outline, null, 2);
  if('sentences_list' in oneCapture)
    packedData.sentences_list = JSON.stringify( oneCapture.sentences_list, null, 2);
  if('notes' in oneCapture)
    packedData.notes = JSON.stringify( oneCapture.notes, null, 2);
  if('clean_sentences_list' in oneCapture)
    packedData.clean_sentences_list = JSON.stringify( oneCapture.clean_sentences_list, null, 2);
  if('summary' in oneCapture)
    packedData.summary = JSON.stringify( oneCapture.summary, null, 2);
  if('flash_cards' in oneCapture)
    packedData.flash_cards = JSON.stringify( oneCapture.flash_cards, null, 2);

  return packedData;
}



//
//  PurchasesAndUsages 
//


export async function fetchPurchasesAndUsagesDB(){
  try {
    const response = await API.graphql(graphqlOperation(
      listPurchasesAndUsages
        ));
    // console.log(response.data);
    let _purchasesAndUsages = response.data.listPurchasesAndUsages.items;
    // console.log("DataUtils: fetchPurchasesAndUsagesDB: _purchasesAndUsages:", _purchasesAndUsages);


    let _lastPurchaseAndUsage = null;

    // sort by timestamp
    if(_purchasesAndUsages.length > 0){
      if(_purchasesAndUsages.length > 1){
        _purchasesAndUsages.sort((a,b) => b.timestamp.localeCompare(a.timestamp));
      }
      _lastPurchaseAndUsage = _purchasesAndUsages[0];
    }
    // console.log("DataUtils: fetchPurchasesAndUsagesDB: _purchasesAndUsages", _purchasesAndUsages);
    // console.log("DataUtils: fetchPurchasesAndUsagesDB: _lastPurchaseAndUsage", _lastPurchaseAndUsage);


    return _lastPurchaseAndUsage;

  } catch (err) { 
    console.log('error: ', err) 
  }
};





export async function insertPurchaseAndUsageDB(purchaseAndUsage){

  let newPurchaseAndUsageData = {};
  if(!!purchaseAndUsage){
    newPurchaseAndUsageData = {...purchaseAndUsage};
  }

  if(!newPurchaseAndUsageData?.id){
    newPurchaseAndUsageData.id = uuidv4();
  }
  if(!newPurchaseAndUsageData?.timestamp){
    newPurchaseAndUsageData.timestamp = Date.now();
  }
  if(!newPurchaseAndUsageData?.newSubscriptionExpiration){
    let date = new Date.now();
    date.setDate(date.getDate() + 3);
    newPurchaseAndUsageData.newSubscriptionExpiration = date;
  }
  if(!newPurchaseAndUsageData?.newMinutesLeft){
    newPurchaseAndUsageData.newMinutesLeft = 0;
  }
  
  let newPurchaseAndUsage = {};
  try{
    const response = await API.graphql(graphqlOperation(createPurchasesAndUsages, 
        { input: newPurchaseAndUsageData })
      );
    // console.log("New PurchaseAndUsage inserted into DB response: ", response);
    newPurchaseAndUsage = response.data.createPurchasesAndUsages;
    // console.log("New PurchaseAndUsage: ", newPurchaseAndUsage);

  }catch(error){
    console.log("ERROR New PurchaseAndUsage inserting into DB response: ", error, newPurchaseAndUsage);
    throw error;
  }
  
  return newPurchaseAndUsage;
}






//
//  Captures a.k.a. Lectures
//


// export async function fetchLecturesDB(){
//   try {
//     let lectures = [];
//     let _nextToken = null;

//     while(true){
//       const response = await API.graphql(graphqlOperation(
//           listPrivateLectures,
//           {
//             nextToken: _nextToken
//           }
//           ));
//       // console.log(response.data);
//       const _lectures = response.data.listPrivateLectures.items;
      
//       for(let i=0; i<_lectures.length; i++){
//         const oneLecture = unpackCaptureRecordFromDbFormat(_lectures[i]);
//         // console.log("fetched Lecture raw: ", oneLecture);
//         if (!oneLecture?.class) { // correct non-existing 'class' to class=""
//           oneLecture["class"] = '';
//         }
//         // console.log("fetched Lecture processed: ", oneLecture);
//         lectures.push(oneLecture);
//       }

//       _nextToken = response.data.listPrivateLectures?.nextToken;
//       if (!_nextToken){
//         break;
//       }
//     }

//     return lectures;

//   } catch (err) { 
//     console.log('error: ', err) 
//   }
// };





export async function insertNewLectureDB(lecture){

  let newLectureData = {};
  if(!!lecture){
    newLectureData = {...lecture};
  }
  if(!newLectureData?.id){
    newLectureData.id = uuidv4();
  }
  if(!newLectureData?.timestamp){
    newLectureData.timestamp = Date.now();
  }
  if(newLectureData?.class === undefined || newLectureData?.class === null){
    newLectureData.class = "";
  }
  if(newLectureData?.captured_seconds === undefined || newLectureData?.captured_seconds === null){
    newLectureData.captured_seconds = 0;
  }
  let packedNewLectureData = packCaptureRecordToDbFormat(newLectureData);

  let newLecture = {};
  try{
    const response = await API.graphql(graphqlOperation(createPrivateLectures, 
        { input: packedNewLectureData })
      );
    // console.log("New Lecture inserted into DB response: ", response);
    newLecture = unpackCaptureRecordFromDbFormat(response.data.createPrivateLectures);
    // console.log("New Lecture: ", newLecture);

  }catch(error){
    console.log("ERROR New Lecture inserting into DB response: ", error, newLectureData);
    throw error;
  }
  
  return newLecture;
}

  // async function fetchLectureById(id){
//   const response = await API.graphql(graphqlOperation(getPrivateLectures, {
//       id: id
//   }));
//   console.log("Fetch Lecture DB response: ", response);
//   return(response.data.getPrivateLectures);
// };






  // export async function saveLectureDB(lectureData){
  //   let _lectureData = {...lectureData};

  //   if(!_lectureData?.id){
  //     console.log("ERROR Updating Record missing ID: ", _lectureData);
  //     throw new Error('Updating Record missing ID');
  //   }
  //   let packedLectureData = packCaptureRecordToDbFormat(_lectureData);

  //   let updatedLecture = {};
  //   try{
  //     const response = await API.graphql(graphqlOperation(
  //       updatePrivateLectures, 
  //       { input: packedLectureData }
  //     ));
  //     // console.log("Updated Lecture inserted into DB response: ", response.data.updatePrivateLectures);
  //     updatedLecture = unpackCaptureRecordFromDbFormat(response.data.updatePrivateLectures);
  //     // console.log("Updated Lecture: ", updatedLecture);

  //   }catch(error){
  //     console.log("ERROR Lecture updating in DB response: ", error, updatedLecture);
  //     throw error;
  //   }
  //   return updatedLecture;
  // }





  // export async function deleteLectureByDB(id){
  //   // if (window.confirm('Are you sure you wish to delete this item?')){
  //     const lectureData = {
  //       id: id
  //     };

  //     try{
  //       const response = await API.graphql(graphqlOperation(
  //         deletePrivateLectures, 
  //         { input: lectureData }
  //       ));
  //       // console.log('Deleted lecture in DB: ', response);

  //     }catch(error){
  //       console.log("ERROR Lecture deleting in DB response: ", error);
  //       throw error;
  //     }
  //   // }
  // }