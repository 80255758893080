import React, { useState, useRef, useEffect } from 'react';

import { View, Flex, Autocomplete, Badge
  } from '@aws-amplify/ui-react';
import { IconContext } from "react-icons";

import { BiTrash, BiSolidUserPlus } from "react-icons/bi";
import { HiSpeakerWave } from "react-icons/hi2";


import { v4 as uuidv4 } from 'uuid';
import * as FetchDataUtils from '../../lib/fetchDataUtils';

// GLOBAL window.varParticipantsList

function OneSpeaker({ 
    param_speaker, 
    param_suggestedSpeakerFlag,
    // param_FnOnChange, 
    param_FnOnSpeakerActiveChange,
    param_FnOnRemoveSpeaker,

}) {

    const [speaker, setSpeaker] = useState(param_speaker);
    const [activeSpeakerFlag, setActiveSpeakerFlag] = useState(false);


    let init = useRef(true);
    useEffect(() => {
        if (!init.current) return;
        init.current = false;

    }, []);
  


    return (
        <div 
            style={{ 
                // backgroundColor: getRandomColor(), 
                color:  !param_suggestedSpeakerFlag ? 'black' : 'lightGrey' , 
                padding: '1px', 
                margin: '1px 13px',
                cursor: 'pointer',
                border: '1px black solid'
            }}
            onClick={(e) => {
                    // console.log ("onClick:", e);
                    if(!param_suggestedSpeakerFlag){ // disable if this is a Suggested Speaker
                        
                        if (activeSpeakerFlag){
                            setActiveSpeakerFlag(false);
                            param_FnOnSpeakerActiveChange(speaker, false);
                        } else {
                            setActiveSpeakerFlag(speaker);
                            param_FnOnSpeakerActiveChange(speaker, true);
                        } 
                    }  
                }
            }
        >
            <Flex
                direction="row"
                justifyContent="flex-start"
                margin="0 1rem"
                alignItems="center"
                alignContent="space-between"
            >
                <View as='div'>
                    {activeSpeakerFlag &&
                        <HiSpeakerWave 
                            fontSize="1.5rem"
                            color='red'
                            alignmentBaseline='auto'
                        />
                    }
                </View>
                <View as='div'>
                    {activeSpeakerFlag ? <b>{speaker}</b> : speaker}
                </View>
                <View as='div'
                    color="red"
                >
                    {activeSpeakerFlag ? "Speaking" : ""}
                </View>
                {!!param_suggestedSpeakerFlag &&
                    <View as='div'
                        marginLeft="auto"
                    >
                        <Badge variation="success" size="small">
                            Accept
                        </Badge>
                        <Badge variation="error" size="small" marginLeft="0.5rem">
                            Reject
                        </Badge>
                    </View>
                }

                {!param_suggestedSpeakerFlag &&
                    <View as='div'
                        marginLeft="auto"
                    >
                        <IconContext.Provider value={{ color: "red", size: "1rem" }}>
                            <BiTrash 
                            color="red" 
                            onClick={(e) => {
                                e.stopPropagation();
                                param_FnOnRemoveSpeaker(speaker);
                            }}
                            />
                        </IconContext.Provider> 
                    </View>
                }
            </Flex>

        </div>
    );
}

export default OneSpeaker;
