import React, { useState, useRef, useEffect } from 'react';

import { Autocomplete,
  } from '@aws-amplify/ui-react';


// GLOBAL window.varAvailableClassesList

function AutocompleteAvailableClasses({ 
    param_value,
    param_FnOnChange,
    param_FnOnClear,
    param_FnOnSelect,
    param_FnOnBlur, 
}) {
    
    const [classesAutocompleteOptions, setClassesAutocompleteOptions] = useState([]);


    let init = useRef(true);
    useEffect(() => {
        if (!init.current) return;
        init.current = false;

        // console.log("AutocompleteAvailableClasses: useEffect: window.varAvailableClassesList:", window.varAvailableClassesList);
        const options = composeAutocompleteOptionsFromAvailableClassesList(window.varAvailableClassesList);
        // console.log("AutocompleteAvailableClasses: useEffect: options", options);
        setClassesAutocompleteOptions(options);

    }, []);
  

    function composeAutocompleteOptionsFromAvailableClassesList(items){
        let options = [];
        try{
            let sortedItems = [...items];
            sortedItems.sort();
            for(let i=0;i<sortedItems.length;i++){
            const item = sortedItems[i];
            options.push({id:item, label:item});
            };
        }catch(ignore){};
        
        return options;
    }


    function onSelectedClass(_className){
        if( !window.varAvailableClassesList.includes(_className) ){
            window.varAvailableClassesList.push(_className);

            // console.log("AutocompleteAvailableClasses: useEffect: window.varAvailableClassesList:", window.varAvailableClassesList);
            const options = composeAutocompleteOptionsFromAvailableClassesList(window.varAvailableClassesList);
            // console.log("AutocompleteAvailableClasses: useEffect: options", options);
            setClassesAutocompleteOptions(options);
        }
    }


    return (
        <Autocomplete
            maxWidth="300px"
            placeholder="Pick Class"
            options={classesAutocompleteOptions}
            value={param_value}
            onChange={(event) => {
                const _className = event.target.value;
                onSelectedClass(_className);
                param_FnOnChange(_className)
            }}
            onClear={param_FnOnClear}
            onSelect={(option) => {
                const _className = option.label;
                onSelectedClass(_className);
                param_FnOnSelect(_className)
            }}
            onBlur={param_FnOnBlur} 
        />
    );
}

export default AutocompleteAvailableClasses;
