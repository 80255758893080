// components/SentenceItemContainer.js
import { useRef, useEffect, useState } from "react";

import { View, Flex,
  } from '@aws-amplify/ui-react';

import { MdOutlineClose } from "react-icons/md";

// import * as Utils from '../lib/utils';


import OneSentenceView from './OneSentenceView';

import OneNoteView from './OneNoteView';
import OneSummaryView from './OneSummaryView';

import './transcript.css';


function SentenceItemContainer({ 
    key,
    param_idx,
    param_oneSentence,

    // param_showFindKeywords,
    // param_findKeywordsArray,
    // param_FnFoundKeyword,

    param_containsKeyword,

    param_minimizedSentence,
    param_minimizedAnnouncements,
    param_minimizedSummary,
    param_minimizedNotes,

    param_lockedEditFlag,

    param_FnUpdateSentenceDB,
    // param_FnDeleteSentenceDB,

    param_FnUpdateAnnouncementsDB,
    param_FnUpdateSummaryDB,
    param_FnDeleteSummaryDB,
    param_FnUpdateNoteDB,
    param_FnDeleteNoteDB,
  }) {


    let init = useRef(true);
    const sentenceDivRef = useRef();

    const[ sentence, setSentence] = useState(false);

    // const[ containsKeyword, setContainsKeyword] = useState(false);

    const[ minimizedSentence, setMinimizedSentence] = useState(false);
    // const[ minimizedAnnouncements, setMinimizedAnnouncements] = useState(false);
    const[ minimizedSummary, setMinimizedSummary] = useState(false);
    const[ minimizedNotes, setMinimizedNotes] = useState(false);

    const[ displayAddSummaryOrNotesFlag, setDisplayAddSummaryOrNotesFlag] = useState(false);
    const[ noteAutoFocusFlag, setNoteAutoFocusFlag] = useState(false);

    const[ editingSentenceFlag, setEditingSentenceFlag] = useState(false);



    useEffect(() => {
        if (init.current){
            // TODO
            init.current = false;
        }
    }, []);



    useEffect(() => {
        // console.log("SentenceItemContainer: useEffect(param_oneSentence)", param_oneSentence);
        
        const _sentence = {...param_oneSentence};
        setSentence(_sentence);
    }, [param_oneSentence]);



    useEffect(() => {
        setMinimizedSentence(param_minimizedSentence);
        // setMinimizedAnnouncements(param_minimizedAnnouncements);
        setMinimizedSummary(param_minimizedSummary);
        setMinimizedNotes(param_minimizedNotes);
        // console.log("SentenceItemContainer: useEffect: min max clicked");
    }, [
        param_minimizedSentence, 
        param_minimizedAnnouncements,
        param_minimizedSummary,
        param_minimizedNotes
    ]);



    // useEffect(() => {
    //     let _foundKeyword = false;
    //     if(param_showFindKeywords === true){
    //         if(!!param_findKeywordsArray){
    //             const haystack = sentence.content.toLowerCase();//.replaceAll(/\.|\,/g, "");
    //             let haystackWordsArray = null;
    //             for(let i=0;i<param_findKeywordsArray.length;i++){
    //                 const oneKeyword = param_findKeywordsArray[i];
    //                 if(oneKeyword.length > 0){
    //                     const needle = oneKeyword.toLowerCase();
                            
    //                     if( haystack.indexOf(needle) > -1 ) {
    //                         if(haystackWordsArray === null){
    //                             haystackWordsArray = haystack.split(" ");
    //                         }
    //                         if (haystackWordsArray.find( element => 
    //                                                 element.indexOf(needle) === 0 
    //                                                 && needle.length >= element.length - 3
    //                                             ))
    //                         {
    //                             _foundKeyword = true;
    //                             // console.log("containsKeyword ", idx, oneKeyword)
    //                             break;
    //                         }
    //                     }
    //                 }
    //             }    
    //         }

    //     }
    //     setContainsKeyword(_foundKeyword);
    //     param_FnFoundKeyword(_foundKeyword);

    // }, [param_showFindKeywords, param_findKeywordsArray]);





    // function onSentenceClick(){
    //     // console.log("SentenceItemContainer: onSentenceClick: ");
    //     setDisplayAddSummaryOrNotesFlag(!displayAddSummaryOrNotesFlag);

    //     setTimeout(() => {
    //         setDisplayAddSummaryOrNotesFlag(false);
    //     }, 5000);
    // }

    
    // function onGenerateSummaryClick(){
    //     // event.

    //     sentence.summary = {};
    //     setDisplayAddSummaryOrNotesFlag(false);
    // }

    function onAddNoteClick(){
        // event.

        sentence.notes = { 
            start_line: param_idx,
            content: "",
        };
        setNoteAutoFocusFlag(true);
        setDisplayAddSummaryOrNotesFlag(false);
    }

    return (
    <div key={param_idx}
        ref={sentenceDivRef}
        onDoubleClick={() => {
            //onSentenceClick();
            setEditingSentenceFlag(true);
        }}
    >
        {!param_minimizedSentence &&
        <Flex
            direction="row"
            justifyContent="left"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            // onClick={() => onSentenceClick()}

            overflow="hidden"
            height={minimizedSentence ? "0" : null}
            border={minimizedSentence ? "1px solid lightgrey" : null}
        >
            <View
                className="cls-unselectable"
                color="var(--amplify-colors-blue-60)"
            >
                {param_idx}
            </View>

            { !editingSentenceFlag &&
                <View
                    color="var(--amplify-colors-black-100)"
                    fontWeight="bolder"
                    className={param_containsKeyword ? "highlight-contains-keyword" : ""}
                >
                    {sentence.content}
                </View>
            }
            { !!editingSentenceFlag &&
                <OneSentenceView
                    param_sentence={sentence.content}
                    // param_minimized={minimizedSummary}
                    // param_lockedEditFlag={param_lockedEditFlag} // keep it udefined
                    // param_autoFocus={noteAutoFocusFlag}

                    param_FnUpdateSentenceDB={(sentenceContent) => {
                        param_FnUpdateSentenceDB(sentenceContent);
                        setEditingSentenceFlag(false);
                    }}
                    // param_FnDeleteSentenceDB={param_FnDeleteSentenceDB}
                    param_FnCancel={() => {
                        setEditingSentenceFlag(false);
                    }}
                />    
            }
            
        </Flex>
        }

        {!!sentence.pause &&
            <View
                marginLeft="auto"
                // onClick={(e) => callParent_fn_setLineNumberToShowInNotes(idx)}
            >
                announcements
            </View>
        }

        {!!sentence.announcements &&
            <View
                marginLeft="auto"
                // onClick={(e) => callParent_fn_setLineNumberToShowInNotes(idx)}
            >
                announcements
            </View>
        }

        {!!sentence?.summary?.content &&
            <OneSummaryView
                param_item={sentence.summary}
                param_minimized={minimizedSummary}
                // param_lockedEditFlag={param_lockedEditFlag} // keep it udefined
                param_autoFocus={noteAutoFocusFlag}
                param_FnUpdateItemDB={param_FnUpdateSummaryDB}
                param_FnDeleteItemDB={param_FnDeleteSummaryDB}
            />    
        }

        {!!sentence.notes &&
            <OneNoteView
                param_note={sentence.notes}
                param_minimized={minimizedNotes}
                // param_lockedEditFlag={param_lockedEditFlag} // keep it udefined
                param_autoFocus={noteAutoFocusFlag}
                param_FnUpdateNoteDB={param_FnUpdateNoteDB}
                param_FnDeleteNoteDB={param_FnDeleteNoteDB}
            />    
        }


        {/* Generate Summary and Add Notes floating buttons */}
        {/* { (!sentence.summary || !sentence.notes) && */}
        { !sentence.notes &&
            <Flex as="div"
                direction="column"
                maxWidth="fit-content"
                margin="0.5rem 3rem 0.5rem auto"
                borderRadius="3px"
                border="3px solid var(--amplify-colors-black)"
                boxShadow="3px 3px 3px 3px var(--amplify-colors-neutral-60)"
                display={!displayAddSummaryOrNotesFlag ? "none" : "flex"}
            >
                <Flex as="div"
                    minHeight="1rem"
                    justifyContent="right"
                    backgroundColor="lightblue"
                ><MdOutlineClose /></Flex>
                <Flex
                    direction="row"
                    right="10px"
                    fontWeight={"bold"}
                >
                    {/* {!sentence.summary &&
                        <View as="div"
                            margin="0 0.5rem 1rem 1rem"
                            className="cls-all-text cls-summary"
                            onClick={(event) => { event.stopPropagation(); onGenerateSummaryClick()} }
                        >
                        Generate Summary
                        </View>
                    } */}

                    {/* {!sentence.notes && */}
                        <View as="div"
                            margin="0 1rem 1rem 0.5rem"
                            className="cls-show-hide-switch cls-notes"
                            onClick={(event) => { event.stopPropagation(); onAddNoteClick()} }
                        >
                        Add Note
                        </View>
                    {/* } */}
                </Flex>
            </Flex>
        }


    </div>


    );
  }
  export default SentenceItemContainer;