// components/OneCaptureExpanderContainer.js
import { useState, useRef, useEffect } from "react";

import { View,
  Divider,
  Heading, 
  Flex,
  CheckboxField,
  Button,
} from '@aws-amplify/ui-react';

import { 
BiSearchAlt, 
  BiCaretRight, 
  BiCaretDown,
} from "react-icons/bi";

import { 
  BsFillLightningFill,
} from "react-icons/bs";


import DocumentExpanderContainer from "./DocumentExpanderContainer";

import * as FetchDataUtils from '../lib/fetchDataUtils';
import * as LoggingUtils from '../lib/loggingUtils';


function ClassExpanderContainer({ 
  param_selectionModeFlag,
  param_selectedDocumentsIds,
  param_selectedDocumentsData,
  param_showOnlySelectedDocumentsFlag,

  param_searchText,

  param_fnOnChangedSelections,

  param_class,
  param_availableClassesList,

  param_FnInsertDocument,
  param_FnUpdateDocument,
  param_FnDeleteDocument,
 }) {

  const [expandedFlag, setExpandedFlag] = useState(false);
  const [documents, setDocuments] = useState([]);

  const [selectionModeFlag, setSelectionModeFlag] = useState(false);
  const [isSelectedFlag, setIsSelectedFlag] = useState(false);

  const selectedDocumentsIdsRef = useRef([]);
  const [selectedDocumentsIds, setSelectedDocumentsIds] = useState([]);

  // const [showOnlySelectedDocumentsFlag, setShowOnlySelectedDocumentsFlag] = useState(false);


  // let init = useRef(true);
  // useEffect(() => {
  //   if (init.current){
  //     init.current = false;
  //   }
  // }, []);


  // useEffect(() => {
  //   if (!param_class?.items?.length){
  //     return;
  //   }
  //   setDocuments(param_class.items);

  // }, [param_class]);



  useEffect(() => {
    if (param_selectionModeFlag != null && param_selectionModeFlag !== selectionModeFlag){
      setSelectionModeFlag(param_selectionModeFlag);
    }
  }, [param_selectionModeFlag]);




  useEffect(() => {
    if (!param_class?.items?.length){
      return;
    }

    const _documents = param_class.items;
    setDocuments(param_class.items);

    // console.log("_documents", _documents);



    if (param_selectedDocumentsIds == null){
      setIsSelectedFlag(false);
      selectedDocumentsIdsRef.current = [];
      setSelectedDocumentsIds(selectedDocumentsIdsRef.current);
      return;
    }

    // param is an empty array -> entire class is selected
    if(param_selectedDocumentsIds?.length === 0){
      setIsSelectedFlag(true);

      selectedDocumentsIdsRef.current = [];
      _documents.map((doc, idx) => {
        if (!selectedDocumentsIdsRef.current.includes(doc.id)){
          selectedDocumentsIdsRef.current.push(doc.id);
        }
      });
      setSelectedDocumentsIds(selectedDocumentsIdsRef.current);
      return;
    }



    // param is an array -> individual documents in class are selected
    let isAnyDocumentDeselected = false;
    _documents.map((doc, idx) => {
      if( !param_selectedDocumentsIds.includes(doc.id) ){
        isAnyDocumentDeselected = true;

        if (selectedDocumentsIdsRef.current.includes(doc.id)){
          selectedDocumentsIdsRef.current = 
                selectedDocumentsIdsRef.current.filter(elem => elem !== doc.id);
        }
      } else {
        if (!selectedDocumentsIdsRef.current.includes(doc.id)){
          selectedDocumentsIdsRef.current.push(doc.id);
        }
      }
    });

    if (isAnyDocumentDeselected){
      setIsSelectedFlag(null);
    } else {
      // all individual documents are selected
      setIsSelectedFlag(true);
    }
  
    setSelectedDocumentsIds(selectedDocumentsIdsRef.current);
    // console.log("ClassExpanderContainer: useEffect: param_class, param_selectedDocumentsIds:", param_class, param_selectedDocumentsIds);

  }, [param_class, param_selectedDocumentsIds]);





  async function fnDeleteDocument(doc){
    if (window.confirm('Delete Document ?')){

      const ret = await FetchDataUtils.deleteData("captures", doc, window.isEncrypted);
      if('error' in ret){
        LoggingUtils.log(ret);
        return;
      }
      setDocuments((documents) => (
        documents.filter((d) => d.id !== doc.id)
      ));
      param_FnDeleteDocument(doc);
    }
  }


  // signalToParent_ChangedSelections( array )
  //  empty array -> all selected
  //  null -> no selections
  function signalToParent_ChangedSelections(selectionArray){
    param_fnOnChangedSelections(selectionArray);
  }


  function fnOnChildChangeSelectedFlag(documentId, isChildSelectedFlag){
    // console.log("fnOnChildChangeSelectedFlag:", idx, isChildSelectedFlag );
    
    // add if absent
    if(isChildSelectedFlag && !selectedDocumentsIdsRef.current.includes(documentId)){
      selectedDocumentsIdsRef.current.push(documentId);
    }
    // remove if present
    if(!isChildSelectedFlag && selectedDocumentsIdsRef.current.includes(documentId)){
      while(true){
        const index = selectedDocumentsIdsRef.current.indexOf(documentId);
        if (index === -1) break;
        selectedDocumentsIdsRef.current.splice(index, 1); // Remove array element
      }
    }

    if(selectedDocumentsIdsRef.current.length === documents.length){
      setIsSelectedFlag(true);
      setSelectedDocumentsIds(selectedDocumentsIdsRef.current);
      signalToParent_ChangedSelections([]); // empty array -> all selected
      return;
    }

    if(selectedDocumentsIdsRef.current.length === 0){
      setIsSelectedFlag(false);
      setSelectedDocumentsIds(selectedDocumentsIdsRef.current);
      signalToParent_ChangedSelections(null); // null -> no selections
      return;
    }

    setSelectedDocumentsIds(selectedDocumentsIdsRef.current);
    setIsSelectedFlag(null); // set to Indeterminate
    signalToParent_ChangedSelections(selectedDocumentsIdsRef.current);
  }



  function selectEntireClass(selectionFlag){
    // console.log("ClassExpanderContainer: onClick class selected: selectionFlag:", selectionFlag);
    setIsSelectedFlag(selectionFlag);

    // documentsSelectedFlagsRef.current.fill(selectionFlag);
    // setDocumentsSelectedFlags([...documentsSelectedFlagsRef.current]);
    // console.log("documentsSelectedFlags.current:", documentsSelectedFlags.current );
    
    selectedDocumentsIdsRef.current = [];

    if(selectionFlag){
      documents.map((doc, idx) => {
        selectedDocumentsIdsRef.current.push(doc.id);
      });

      setSelectedDocumentsIds(selectedDocumentsIdsRef.current);
      signalToParent_ChangedSelections([]); // empty array -> all selected
    
    } else {
      setSelectedDocumentsIds(selectedDocumentsIdsRef.current);
      signalToParent_ChangedSelections(null); // null -> no selections   
    }
  }




  // if showing only selected - skip unselected class alltogether
  if (!!selectionModeFlag && !!param_showOnlySelectedDocumentsFlag && isSelectedFlag === false)
    return;


  return (
    <View  
      as="div"
      borderRadius="6px"
      border="1px solid var(--amplify-colors-black)"
      boxShadow="3px 3px 5px 6px var(--amplify-colors-neutral-60)"
      maxWidth="100%"
      margin="10px 0 10px 10px"
      padding=".1rem"
      // width="90%"
    >
      <Flex direction="row"
        // style={{textIndent: '30%'}}
        width='100%'
        fontSize='1.5rem'
        fontWeight='bold' 
        backgroundColor="lightblue"
        
      >
            <Flex 
                direction="row"
              width="-webkit-fill-available"
              onClick={() => {setExpandedFlag(!expandedFlag)}}
            >
              <View as="div"
                width="30%"
                display="inline-flex"
              >

              { !expandedFlag &&
                <BiCaretRight />
              }
              { !!expandedFlag &&
                <BiCaretDown />
              }
              </View>

              <View as="div"
                width="10%" color={"black"}
                >
                    { !!selectedDocumentsIds?.length &&
                        <Flex
                            gap="0.3rem"
                            fontWeight="bold"
                            onClick={() => {
                            }}
                        >
                            <BiSearchAlt fontSize="1.5rem" />
                            [{selectedDocumentsIds?.length}]
                        </Flex>
                    }
                </View>

              {param_class.className===""?"Undefined":param_class.className }

              <View as="span"
                marginLeft="1rem"
                fontSize="1rem"
                style={{verticalAlign:'text-top'}}
              >
                [{param_class.items.length}]
              </View>
            </Flex>

        

              {!selectionModeFlag && 
                  <View as="table"
                    className="cls-show-hide-switch cls-text-center cls-vertical-align-center cls-background-greenyellow-dim"
                    minWidth="3rem"
                    padding="0px"
                    fontWeight="normal"
                    onClick={() => {
                      selectEntireClass(true);
                    }}
                  >
                  <tbody><tr><td>
                        <BsFillLightningFill />
                        Q.Ai
                  </td></tr></tbody>
                  </View>
              }

              { !!selectionModeFlag &&
                <CheckboxField
                  value={true}
                  isIndeterminate={isSelectedFlag === null}
                  fontSize="2rem"
                  checked={!!isSelectedFlag}
                  onClick={(e) => {
                    const val = e.target.checked;
                    selectEntireClass(val);
                  }}
                />
              }
       
      </Flex>
      
      <View as="div"
      >
        {!!expandedFlag && documents.map((doc, idx) => {

          return (
            <DocumentExpanderContainer 
              key={doc.id}

              param_selectionModeFlag={selectionModeFlag}
              param_isParentSelectedFlag={isSelectedFlag}
              param_isSelectedFlag={selectedDocumentsIds.includes(doc.id)}

              param_selectedDocumentData={param_selectedDocumentsData != null ? param_selectedDocumentsData[doc.id] : null}

              param_searchText={param_searchText}
              
              param_showOnlySelectedDocumentsFlag={param_showOnlySelectedDocumentsFlag}

              param_fnOnChildChangeSelectedFlag={(isChildSelectedFlag) => {
                fnOnChildChangeSelectedFlag(doc.id, isChildSelectedFlag);
              }}

              param_oneCapture={doc}
              param_availableClassesList={param_availableClassesList}
              
              // param_FnReloadCapturesList={() => param_FnReloadCapturesList()}
              param_FnInsertDocument={param_FnInsertDocument}
              param_FnUpdateDocument={param_FnUpdateDocument}
              param_FnDeleteDocument={fnDeleteDocument}
            />
          )
          })
        }
      </View>

      <Divider orientation="horizontal" />
    </View>
 );
}

export default ClassExpanderContainer;
