/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPrivateLectures = /* GraphQL */ `
  query GetPrivateLectures($id: ID!) {
    getPrivateLectures(id: $id) {
      id
      class
      timestamp
      recording
      transcript
      comments
      announcements
      outline
      title
      captured_seconds
      captured_words
      sentences_list
      notes
      clean_sentences_list
      summary
      flash_cards
      createdAt
      updatedAt
      owner
      purchasesAndUsagesId
    }
  }
`;
export const listPrivateLectures = /* GraphQL */ `
  query ListPrivateLectures(
    $filter: ModelPrivateLecturesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivateLectures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        class
        timestamp
        recording
        transcript
        comments
        announcements
        outline
        title
        captured_seconds
        captured_words
        sentences_list
        notes
        clean_sentences_list
        summary
        flash_cards
        createdAt
        updatedAt
        owner
        purchasesAndUsagesId
      }
      nextToken
    }
  }
`;
export const getInvoices = /* GraphQL */ `
  query GetInvoices($id: ID!) {
    getInvoices(id: $id) {
      id
      timestamp
      customer_id
      status
      invoice_data
      checkout_session
      payment_intent
      charge
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        customer_id
        status
        invoice_data
        checkout_session
        payment_intent
        charge
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPurchasesAndUsages = /* GraphQL */ `
  query GetPurchasesAndUsages($id: ID!) {
    getPurchasesAndUsages(id: $id) {
      id
      createdAt
      timestamp
      newSubscriptionExpiration
      newMinutesLeft
      oldSubscriptionExpiration
      oldMinutesLeft
      invoiceId
      captureId
      updatedAt
      owner
    }
  }
`;
export const listPurchasesAndUsages = /* GraphQL */ `
  query ListPurchasesAndUsages(
    $filter: ModelPurchasesAndUsagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchasesAndUsages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        timestamp
        newSubscriptionExpiration
        newMinutesLeft
        oldSubscriptionExpiration
        oldMinutesLeft
        invoiceId
        captureId
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUserAccounts = /* GraphQL */ `
  query GetUserAccounts($id: ID!) {
    getUserAccounts(id: $id) {
      id
      timestamp
      owner
      email
      phone
      name
      subscriptionExpiration
      minutesLeft
      lastLogin
      lastPurchase
      lastCapture
      createdAt
      updatedAt
    }
  }
`;
export const listUserAccounts = /* GraphQL */ `
  query ListUserAccounts(
    $filter: ModelUserAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        owner
        email
        phone
        name
        subscriptionExpiration
        minutesLeft
        lastLogin
        lastPurchase
        lastCapture
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventLogs = /* GraphQL */ `
  query GetEventLogs($id: ID!) {
    getEventLogs(id: $id) {
      id
      createdAt
      updatedAt
      owner
      level
      source
      message
    }
  }
`;
export const listEventLogs = /* GraphQL */ `
  query ListEventLogs(
    $filter: ModelEventLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        owner
        level
        source
        message
      }
      nextToken
    }
  }
`;
export const capturesByOwnerByCreatedAt = /* GraphQL */ `
  query CapturesByOwnerByCreatedAt(
    $owner: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPrivateLecturesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    capturesByOwnerByCreatedAt(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        class
        timestamp
        recording
        transcript
        comments
        announcements
        outline
        title
        captured_seconds
        captured_words
        sentences_list
        notes
        clean_sentences_list
        summary
        flash_cards
        createdAt
        updatedAt
        owner
        purchasesAndUsagesId
      }
      nextToken
    }
  }
`;
export const invoicesByOwnerByCreatedAt = /* GraphQL */ `
  query InvoicesByOwnerByCreatedAt(
    $owner: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByOwnerByCreatedAt(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        customer_id
        status
        invoice_data
        checkout_session
        payment_intent
        charge
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const purchasesAndUsagesByOwnerByTimestamp = /* GraphQL */ `
  query PurchasesAndUsagesByOwnerByTimestamp(
    $owner: String!
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPurchasesAndUsagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    purchasesAndUsagesByOwnerByTimestamp(
      owner: $owner
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        timestamp
        newSubscriptionExpiration
        newMinutesLeft
        oldSubscriptionExpiration
        oldMinutesLeft
        invoiceId
        captureId
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const purchasesAndUsagesByOwnerByCreatedAt = /* GraphQL */ `
  query PurchasesAndUsagesByOwnerByCreatedAt(
    $owner: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPurchasesAndUsagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    purchasesAndUsagesByOwnerByCreatedAt(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        timestamp
        newSubscriptionExpiration
        newMinutesLeft
        oldSubscriptionExpiration
        oldMinutesLeft
        invoiceId
        captureId
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const userAccountsByOwnerByTimestamp = /* GraphQL */ `
  query UserAccountsByOwnerByTimestamp(
    $owner: String!
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAccountsByOwnerByTimestamp(
      owner: $owner
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        owner
        email
        phone
        name
        subscriptionExpiration
        minutesLeft
        lastLogin
        lastPurchase
        lastCapture
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userAccountsByOwnerByCreatedAt = /* GraphQL */ `
  query UserAccountsByOwnerByCreatedAt(
    $owner: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAccountsByOwnerByCreatedAt(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        owner
        email
        phone
        name
        subscriptionExpiration
        minutesLeft
        lastLogin
        lastPurchase
        lastCapture
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userAccountsByEmailByTimestamp = /* GraphQL */ `
  query UserAccountsByEmailByTimestamp(
    $email: String!
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAccountsByEmailByTimestamp(
      email: $email
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        owner
        email
        phone
        name
        subscriptionExpiration
        minutesLeft
        lastLogin
        lastPurchase
        lastCapture
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userAccountsByEmailByCreatedAt = /* GraphQL */ `
  query UserAccountsByEmailByCreatedAt(
    $email: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAccountsByEmailByCreatedAt(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        owner
        email
        phone
        name
        subscriptionExpiration
        minutesLeft
        lastLogin
        lastPurchase
        lastCapture
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userAccountsBySubscriptionExpirationByTimestamp = /* GraphQL */ `
  query UserAccountsBySubscriptionExpirationByTimestamp(
    $subscriptionExpiration: String!
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAccountsBySubscriptionExpirationByTimestamp(
      subscriptionExpiration: $subscriptionExpiration
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        owner
        email
        phone
        name
        subscriptionExpiration
        minutesLeft
        lastLogin
        lastPurchase
        lastCapture
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userAccountsByMinutesLeftByTimestamp = /* GraphQL */ `
  query UserAccountsByMinutesLeftByTimestamp(
    $minutesLeft: Int!
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAccountsByMinutesLeftByTimestamp(
      minutesLeft: $minutesLeft
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        owner
        email
        phone
        name
        subscriptionExpiration
        minutesLeft
        lastLogin
        lastPurchase
        lastCapture
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventLogsByOwnerByCreatedAt = /* GraphQL */ `
  query EventLogsByOwnerByCreatedAt(
    $owner: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EventLogsByOwnerByCreatedAt(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        level
        source
        message
      }
      nextToken
    }
  }
`;
export const eventLogsByOwnerByLevelByCreatedAt = /* GraphQL */ `
  query EventLogsByOwnerByLevelByCreatedAt(
    $owner: String!
    $levelCreatedAt: ModelEventLogsByOwnerByLevelByCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EventLogsByOwnerByLevelByCreatedAt(
      owner: $owner
      levelCreatedAt: $levelCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        level
        source
        message
      }
      nextToken
    }
  }
`;
export const eventLogsByLevelByCreatedAt = /* GraphQL */ `
  query EventLogsByLevelByCreatedAt(
    $level: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EventLogsByLevelByCreatedAt(
      level: $level
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        level
        source
        message
      }
      nextToken
    }
  }
`;
