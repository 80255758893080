
import { Auth } from 'aws-amplify';
// Example:
// import * as FetchDataUtils from '../lib/fetchDataUtils';
import * as LoggingUtils from './loggingUtils';

import {AES} from "jscrypto/es6/AES"; // Recommended
import {Utf8} from "jscrypto/es6/Utf8"; // Recommended
const encSecretKey = "Jeca secret key 222";



// Example:
// let items = await FetchDataUtils.getData("captures", window.isEncrypted);
// console.log("ListLectures: fetchItems: API captures:", items);
export async function getData( apiUrl, encryptFlag ){
  const res = await fetchData( apiUrl, "GET", null, encryptFlag);
  return res;
}

export async function postData( apiUrl, bodyJson, encryptFlag ){
  const res = await fetchData( apiUrl, "POST", bodyJson, encryptFlag);
  return res;
}

export async function deleteData( apiUrl, bodyJson, encryptFlag ){
  const res = await fetchData( apiUrl, "DELETE", bodyJson, encryptFlag);
  return res;
}

export async function fetchData( apiUrl, httpMethodName, bodyObj, _encryptFlag){
  let encryptFlag = true;

  if (_encryptFlag == null){
    if('isEncrypted' in window){
      encryptFlag = window.isEncrypted;
    }else{
      encryptFlag = false;
    }
  }
  
  // if (_encryptFlag !== false) encryptFlag = true; // default is encrypted

  while(apiUrl.charAt(0) === '/') // remove preceding slashes if any
  {
    apiUrl = apiUrl.substring(1);
  }

  let baseURL = "/";
  if (process.env.NODE_ENV === "development"){
    baseURL = "http://localhost:8000/";
    // baseURL = "https://studyslaps.com/";
  }
  const currentSession = await Auth.currentSession();
  const accessToken = currentSession.getAccessToken();
  const jwt = accessToken.getJwtToken();
  const fetchOptions = {
    headers: {
      'accesstoken': jwt,
      // 'Content-Type': 'application/json',
      'Content-Type': 'text/plain',
    },
    // referrerPolicy: "no-referrer", 
    //"strict-origin-when-cross-origin", // no-referrer-when-downgrade, no-referrer, origin, same-origin...
  }

  // if (process.env.NODE_ENV === "development"){
  //   fetchOptions.headers.mode = 'no-cors'; // never do this: means “Block my frontend JavaScript code from seeing contents of the response body and headers under all circumstances.” 
  // }

  // add method to fetchOptions
  if (!httpMethodName){
    if(!!bodyObj){
      fetchOptions.method = "POST";
    }else{
      fetchOptions.method = "GET";
    }
  }else{
    fetchOptions.method = httpMethodName.toUpperCase();
  }

  // add body if any - cannot be added to "GET" and/or "HEAD"
  let body = null;
  if (!!bodyObj){
    if(fetchOptions.method === "GET" || fetchOptions.method === "HEAD"){

      // console.log("fetchDataUtils: fetch(): EROR: GET and/or HEAD cannot have a body");
      delete fetchOptions.body;
    }else{

      try{
        body = JSON.stringify(bodyObj);
      }catch(ignore){
        body = bodyObj;
      }
      fetchOptions.body = body;
    }
  }

  // console.log("fetchDataUtils: getData(): fetch ["+apiUrl+"] fetchOptions: ", fetchOptions);
  if(!!encryptFlag){

    // ENCRYPTED
    // encrypted message content
    const messageObj = {
      url: apiUrl,
      options: fetchOptions,
    };
    const messageJson = JSON.stringify(messageObj);
    var ciphertext = AES.encrypt(messageJson, encSecretKey).toString();

    // prepare fetch to enc API
    const encApiUrl = "idx/page";
    const encFetchOptions = {
      method: "POST",
      headers: {
        'accesstoken': jwt,
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify({ idx: ciphertext }),
    }

    // call enc API
    // console.log("fetchDataUtils: fetchData(): fetch ["+baseURL + encApiUrl+"] encFetchOptions: ", encFetchOptions);
    let encResponseObj = await fetch( baseURL + encApiUrl, encFetchOptions);
    // console.log("fetchDataUtils: fetchData(): fetch encResponseObj: ", encResponseObj);

    if (!encResponseObj.ok) {
      const message = `An error has occured: ${encResponseObj.status}`;
      console.log("fetchDataUtils: encrypted fetch(): EROR: ", message);
      const responseText = await response.text();
      console.log("fetchDataUtils: encrypted fetch(): EROR Response: ", responseText);
      return null;
    }
    const encResponse = await encResponseObj.json();
    // console.log("fetchDataUtils: fetchData(): encResponse: ", encResponse);

    // decrypt response
    var bytes  = AES.decrypt(encResponse.idx, encSecretKey);
    // console.log("fetchDataUtils: fetchData(): bytes: ", bytes);
    const responseText = bytes.toString(Utf8);
    // console.log("fetchDataUtils: fetchData(): responseText: ", responseText);


    let response;
    try{
      response = JSON.parse(responseText);
      // console.log("fetchDataUtils: fetchData(): response: ", response);
    }catch(error){
      response = responseText;
    }

    // console.log("fetchDataUtils: fetchData(): response: ", response);
    return response;


  }else{ // PLAIN
    const responseObj = await fetch( baseURL + apiUrl, fetchOptions);

    if (!responseObj.ok) {
      // {"status":400,
      //  "error":
      //    {"message":"Detected an error in the prompt. Please try again with a different prompt.",
      //     "type":"invalid_request_error",
      //     "param":"prompt",
      //     "code":null
      //   }
      // }
      const message = `An error has occured: ${responseObj.status}`;
      console.log("fetchDataUtils: getData(): EROR: ", message);
      const responseText = await responseObj.text();
      console.log("fetchDataUtils: getData(): EROR Response: ", responseText);
      // return null;
    }
    const responseText = await responseObj.text();

    let response;
    try{
      response = JSON.parse(responseText);
    }catch(error){
      response = responseText;
    }

    return response;
  }




  
}

