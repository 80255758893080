
import * as audioUtils from '../lib/audioUtils';

//
//  Helper class: RTEngineWhisper
//
//  variable in the main code: "transcriptorEngine"
//
class RTEngineWhisper {
	constructor() {	
		this._curAudioLength = 0;
		this._curSilenceLength = 0;
		this._silenceThreshold = 0.15;

    	this._rawAudioArray = [];
		this._pcmEncodedBuffer = new Buffer.from([]);
		// this._awsEventStreamMarshaller = new marshaller.EventStreamMarshaller(util_utf8_node.toUtf8, util_utf8_node.fromUtf8);
	}

	/**
	 * @param {any} newSampleRate
	 */
	set sampleRate(newSampleRate){
		this._sampleRate = newSampleRate;
	}

	calculateTranscriptionWebsocketUrl() {
		let protocol = (window.location.protocol === 'https:')? "wss:" : "ws:";
		let url = protocol + "//" + window.location.hostname + "/t";
		if (process.env.NODE_ENV === "development"){
			// url = "ws://localhost:8443/";
			url = "ws://localhost:8000/";
		}
		return url;
	}



	// return NULL if not ready for sending
	appendEncoded(rawAudioChunk) {
		if (!rawAudioChunk){
			rawAudioChunk = [];
		}

		// 
		//   Calc. _curSilenceLength  - how long was silence so far
		// 
		// console.log("rawAudioChunk: ",rawAudioChunk);
		// loop backwards until loud noise
		let i = rawAudioChunk.length;
		let cnt = 0;
		while(i > 0){
			cnt++;
			i--;
			if(rawAudioChunk[i] > this._silenceThreshold){ // broke the silence
        		// console.log("broke the silence with value:", i, rawAudioChunk[i]);
				this._curSilenceLength = cnt / this._sampleRate;
				break;
			}
		}
		if(i === 0){ // chunk silence all the way
			this._curSilenceLength += rawAudioChunk.length / this._sampleRate;
		}
		// console.log("audio:", this._curAudioLength, "silence:", this._curSilenceLength);

		// this is for analysis
		if( !!rawAudioChunk ){
			this._rawAudioArray = this._rawAudioArray.concat(...rawAudioChunk);
		}
    
		// convert the raw audio bytes to PCM - ArrayBuffer(2048) obj
		const chunkPcmEncoded = !rawAudioChunk ? [] : audioUtils.pcmEncode(rawAudioChunk);
		const chunkPcmEncodedBuffer = Buffer.from(chunkPcmEncoded);
		
		// append to current PCM Buffer
		const tmpBuffer = Buffer.concat([this._pcmEncodedBuffer, chunkPcmEncodedBuffer]);
    // console.log("RecordTranscriptor: tmpBuffer is type:", typeof tmpBuffer);

		this._pcmEncodedBuffer = tmpBuffer;

		//
		// Note: _curAudioLength - in the future may be longer than total buffer
		//			possibly to save time by cutting out silences
		this._curAudioLength += rawAudioChunk.length / this._sampleRate; 

  }
    
  isReadyToSend() {
    // console.log("RecordTranscriptor: isReadyToSend(): _curAudioLength:", this._curAudioLength, " _curSilenceLength:", this._curSilenceLength);
    let readyToSend = false;
		//
		// after 10-15 sec. start checking for suitable silence to send buffer for transcription
    if((this._curAudioLength > 3) && (this._curSilenceLength > 0.5)){ //silence is 1sec -> send Buffer
			readyToSend = true;
		}
		if((this._curAudioLength > 5) && (this._curSilenceLength > 0.3)){ //silence is 1sec -> send Buffer
			readyToSend = true;
		}
		if((this._curAudioLength > 8) && (this._curSilenceLength > 0.2)){ //silence is 0.5 sec -> send Buffer
			readyToSend = true;
		}
		if((this._curAudioLength > 10) && (this._curSilenceLength > 0.1)){ //silence is 0.5 sec -> send Buffer
			readyToSend = true;
		}
		if(this._curAudioLength > 12){ 
			readyToSend = true;
		}
		// if(this._curAudioLength > 1){ 
		// 	readyToSend = true;
		// }
        if(readyToSend){
            console.log("Ready to Send: RecordTranscriptor: isReadyToSend(): _curAudioLength:", this._curAudioLength, " _curSilenceLength:", this._curSilenceLength);
        }
    return readyToSend;
  }

	// handleReturnedTranscript(message) { // param is a MessageEvent obj     
	// 	let retValue = {
	// 		isValidFlag: false,
	// 		isPartialFlag: true,
	// 		currentTranscript: "",
	// 		timeLength: 0,
	// 	};

	// 	// console.log("Received from OpenAI Whisper Transcribe Service: ", message);
	// 	retValue = {
	// 		isValidFlag: true,
	// 		isPartialFlag: false,
	// 		currentTranscript: message.data,
	// 		timeLength: 2,
	// 	};

        
	// 	return retValue;
  // }
}

export default RTEngineWhisper;