// components/ListLectures.js
import { useState, useEffect, useRef } from "react";

import { ButtonGroup, Flex, withAuthenticator } from '@aws-amplify/ui-react';
import {
    Menu, MenuItem, MenuButton,
    View, Button,
    Link, TextField,
} from '@aws-amplify/ui-react';
// import { Auth } from 'aws-amplify';

// import { useBeforeunload } from 'react-beforeunload';

// import { deletePrivateLectures } from '../graphql/mutations';
import * as DataUtils from '../lib/graphqlDataUtils';
import * as Utils from '../lib/utils';

import CaptureLecture from "./CaptureLecture";

import {
    BiCaretRight,
    BiCaretDown,
    BiLinkExternal,
    BiSearchAlt,
} from "react-icons/bi";

import {
    BsFillCheckSquareFill,
    BsRecordFill,
    BsFileEarmarkFont,
} from "react-icons/bs";

import {
    GiPauseButton,
    GiPlainSquare
} from "react-icons/gi";


import './listLectures.css';
// import OneCaptureExpanderContainer from "./CaptureExpanderContainer";
import BillingView from "./BillingView";

// import * as DemoUtils from '../lib/demoUtils';
import EnterDocument from "./EnterDocument";
import Admin from "../admin/Admin";

import * as FetchDataUtils from '../lib/fetchDataUtils';
import * as LoggingUtils from '../lib/loggingUtils';

// import ClassExpanderContainer from "./ClassExpanderContainer";

import ListClasses from "./ListClasses";
import SelectedDocumentsTags from "./SelectedDocumentsTags";
import QueryAi from "./QueryAi";


import SearchDocuments from "./SearchDocuments";


function Main({ signOut, user }) {

    const [showLoadingSpinnerFlag, setShowLoadingSpinnerFlag] = useState(true);

    // const [items, setItems] = useState([]);
    const [selectionModeFlag, setSelectionModeFlag] = useState(false);
    const [selectedClassesAndDocuments, setSelectedClassesAndDocuments] = useState({});
    // const [unselectedClassesAndDocuments, setUnselectedClassesAndDocuments] = useState({});

    const [selectedCount, setSelectedCount] = useState({ classes: 0, documents: 0 });

    const [availableClassesList, setAvailableClassesList] = useState([]);

    const [showingCaptureLectureFlag, setShowingCaptureLectureFlag] = useState(false);
    const [showingEnterDocumentFlag, setShowingEnterDocumentFlag] = useState(false);
    // const [showingSearchInputFlag, setShowingSearchInputFlag] = useState(false);
    const [showingQueryAiFlag, setShowingQueryAiFlag] = useState(false);

    const [showingSearchFlag, setShowingSearchFlag] = useState(false);

    // const inputSearchTextRef = useRef();
    const [searchText, setSearchText] = useState(null);


    const [subscriptionExpiration, setSubscriptionExpiration] = useState(0);
    const [minutesLeft, setMinutesLeft] = useState(0);

    const capturingInProgressFlagRef = useRef(false);

    const [documentToInsert, setDocumentToInsert] = useState(null);
    const [documentToUpdate, setDocumentToUpdate] = useState(null);
    const [documentToDelete, setDocumentToDelete] = useState(null);

    const [availableTags, setAvailableTags] = useState({});

    const [showOnlySelectedDocumentsFlag, setShowOnlySelectedDocumentsFlag] = useState(false);



    let init = useRef(true);
    useEffect(() => {
        if (!init.current) return;
        init.current = false;

        window.varUser = user;
        // window.varShowQAiButtonsFlag = true;


        async function serialExe() {

            if (window.location.hostname.includes("business-glossary")) {
                window.isForBusiness = true;
            }
            if (window.location.hostname.includes("ipindepth")) {
                window.isForBusiness = true;
            }
            if (window.location.hostname.includes("studyslaps")) {
                window.isForBusiness = false;
            }

            window.isDevelopment = (process.env.NODE_ENV === "development");
            window.isEncrypted = (process.env.NODE_ENV !== "development");


            // get subscription expiration and number of capturing minutes left
            let _userAccount = await FetchDataUtils.getData('userAccount/userAccount');
            if ('error' in _userAccount) {
                LoggingUtils.log(_userAccount);
                _userAccount = null;
            }
            // console.log("_userAccount from service:", _userAccount);

            if (!_userAccount?.subscriptionExpiration) {
                // backup fetch
                await fetchLastPurchaseAndUsage();
            } else {

                setSubscriptionExpiration(_userAccount.subscriptionExpiration);
                setMinutesLeft(_userAccount.minutesLeft);
            }


            // console.log(window.location);
            if (window.location.hash.indexOf("Billing") > -1) {

            } else if ((window.location.hash.indexOf("Admin") > -1)) {

            } else {

                if (window.location.hostname.includes("quick.")) {
                    setShowingCaptureLectureFlag(true);
                }
                if (window.location.hash.indexOf("quick") > -1) {
                    setShowingCaptureLectureFlag(true);
                }

                // DataUtils.insertEventLogDB({
                //   level: "login",
                //   message: {hostname: window.location.hostname},
                // });


                let _classesWithDocumentsInfo = await FetchDataUtils.getData("captures/classes");
                setShowLoadingSpinnerFlag(false);
                if ('error' in _classesWithDocumentsInfo) {
                    // LoggingUtils.log(_availableClassesList);
                    _classesWithDocumentsInfo = null;
                }
                window.varClassesWithDocumentsInfo = _classesWithDocumentsInfo;
                //   console.log("window.varClassesWithDocumentsInfo:", window.varClassesWithDocumentsInfo);



                let _classesWithSpeakersList = {};
                let _classesWithSpellingsList = {};
                if (!!_classesWithDocumentsInfo?.length) {

                    _classesWithDocumentsInfo.map((c) => {
                        const _classSpeakersList = [];
                        const _classSpellingsList = [];

                        const _documents = c.items;
                        _documents.map((d) => {
                            // convert 'speakers_list' from JSON
                            let _speakersList = d.speakers_list;
                            if (_speakersList != null) {
                                if (Utils.isString(_speakersList)) {
                                    try {
                                        _speakersList = JSON.parse(_speakersList);
                                    } catch (error) {
                                        _speakersList = [];
                                    }
                                }
                                _speakersList.map((s) => {
                                    if (!_classSpeakersList.includes(s)) {
                                        _classSpeakersList.push(s);
                                    }
                                })
                            }


                            // TODO Spellings
                            let _spellingsList = d.spelling;
                            if (_spellingsList != null) {
                                if (Utils.isString(_spellingsList) && _spellingsList.length > 0) {
                                    try {
                                        _spellingsList = JSON.parse(_spellingsList);
                                    } catch (error) {
                                        _spellingsList = _spellingsList.split(",").map(function (item) {
                                            return item.trim();
                                        });
                                    }
                                }
                                _spellingsList.map((s) => {
                                    if (!_classSpellingsList.includes(s)) {
                                        _classSpellingsList.push(s);
                                    }
                                })
                            }


                        })
                        const _sortedClassSpeakersList = _classSpeakersList.sort();
                        _classesWithSpeakersList[c.className] = _sortedClassSpeakersList;


                        const _sortedClassSpellingsList = _classSpellingsList.sort();
                        _classesWithSpellingsList[c.className] = _sortedClassSpellingsList;
                    });
                }
                window.varClassesWithSpeakersList = _classesWithSpeakersList;
                //   console.log("window.varClassesWithSpeakersList:", window.varClassesWithSpeakersList);

                window.varClassesWithSpellingsList = _classesWithSpellingsList;
                //   console.log("window.varClassesWithSpellingsList:", window.varClassesWithSpellingsList);


                let _availableClassesList = await FetchDataUtils.getData("captures/class-names");
                if ('error' in _availableClassesList) {
                    // LoggingUtils.log(_availableClassesList);
                    _availableClassesList = null;
                }
                if (!!_availableClassesList?.length) {
                    _availableClassesList.sort();
                    setAvailableClassesList(_availableClassesList);
                }
                window.varAvailableClassesList = _availableClassesList;
                //   console.log("window.varAvailableClassesList:", window.varAvailableClassesList);



                // SET GLOBAL 'window.varAvailableSpeakersArray'
                let _availableSpeakersArray = await FetchDataUtils.getData("speakers");
                if ('error' in _availableSpeakersArray) {
                    // LoggingUtils.log(_availableSpeakersList);
                    _availableSpeakersArray = [];
                }
                window.varAvailableSpeakersArray = _availableSpeakersArray;
                //   console.log("window.varAvailableSpeakersArray:", window.varAvailableSpeakersArray);

                // EXTRACT ONLY Speakers Names -> array of speaker names
                // SET GLOBAL 'window.varParticipantsList'
                let _availableParticipantsList = window.varAvailableSpeakersArray.map(item => item.speaker_name);
                // sort
                if (!!_availableParticipantsList?.length) {
                    _availableParticipantsList.sort();
                }
                window.varParticipantsList = _availableParticipantsList;
                // console.log("window.varParticipantsList:", window.varParticipantsList);



                let _availableTagsRecords = await FetchDataUtils.getData("documentSelections");
                if ('error' in _availableTagsRecords) {
                    LoggingUtils.log(_availableTagsRecords);
                    _availableTagsRecords = null;
                }

                // console.log("Main.js: useEffect: init: FetchDataUtils documentSelections: _availableTagsRecords:", _availableTagsRecords);
                if (!!_availableTagsRecords?.length && _availableTagsRecords.length > 0) {
                    let tags = _availableTagsRecords[_availableTagsRecords.length - 1]?.tags;
                    if (Utils.isString(tags)) {
                        tags = JSON.parse(tags);
                    }
                    setAvailableTags(tags);
                }



                // const items = await fetchItems();

                // // generate Demo if missing
                // const demoTitle = DemoUtils.demoCapture.title;
                // const found = items.find(element => ((!!element?.title) && (element.title.indexOf(demoTitle) >= 0)) );
                // if (!found){
                //   const encryptFlag = true;
                //   await DemoUtils.generateDemoCapture(encryptFlag);
                //   fetchItems();
                // }
            }

        }
        serialExe();



        const preventUnload = (event) => {
            // console.log(event);
            if (capturingInProgressFlagRef.current) {
                const message = 'Sure you want to leave?'; // NOTE: This message isn't used in modern browsers, but is required
                event.preventDefault();
                // NEXT COMMENT LINE MUST BE THERE
                // eslint-disable-next-line no-param-reassign
                event.returnValue = message;
            }
        };
        window.addEventListener('beforeunload', preventUnload);
        // return () => {
        //   window.removeEventListener('beforeunload', preventUnload);
        // };

    }, []);


    // useEffect(() => {
    //   console.log("TRACKING SELECTIONS Main.js: useEffect: selectedClassesAndDocuments:", selectedClassesAndDocuments);
    // }, [selectedClassesAndDocuments]);

    // useEffect(() => {
    //   console.log("TRACKING showOnlySelectedDocumentsFlag Main.js: useEffect: showOnlySelectedDocumentsFlag:", showOnlySelectedDocumentsFlag);
    // }, [showOnlySelectedDocumentsFlag]);


    // backup if failed 'userAccounts'
    async function fetchLastPurchaseAndUsage() {
        var _lastPurchaseAndUsage = await DataUtils.fetchPurchasesAndUsagesDB();
        if (!_lastPurchaseAndUsage) {

            // create 1-month subscription with 100 minutes
            let now = new Date();
            const newSubscriptionExpiration = new Date(now.setMonth(now.getMonth() + 1));
            const _purchaseAndUsage = {
                newSubscriptionExpiration: newSubscriptionExpiration,
                newMinutesLeft: 100,
            };
            _lastPurchaseAndUsage = await DataUtils.insertPurchaseAndUsageDB(_purchaseAndUsage);
        }

        setSubscriptionExpiration(_lastPurchaseAndUsage.newSubscriptionExpiration);
        setMinutesLeft(_lastPurchaseAndUsage.newMinutesLeft);
    }




    async function onPurchase(purschasedSubscriptions, purchasedMinutes) {
        // TODO: fetch Minutes and Subscription from services API
        // console.log("Main.js: onPurchase: TODO reload minutesLeft and subscriptionExpiration from services API");
    }



    function onStartCapturing() {
        // capturing in progress
        capturingInProgressFlagRef.current = true;
    }


    async function onEndCapturing(newCapture) {

        capturingInProgressFlagRef.current = false;

        setShowingCaptureLectureFlag(false);
        setShowingEnterDocumentFlag(false);


        if (!!newCapture) { // skip if newCapture is NULL if there was 0 seconds of Capturing
            // setItems(items => [...items, 
            //   newCapture
            // ]);
            // fetchItems();

            // reduce 'minutesLeft'
            if (newCapture.captured_seconds > 0) { // 0 used seconds in case Entered Text

                const capturedMinutes = Math.ceil(newCapture.captured_seconds / 60);
                const newMinutesLeft = minutesLeft - capturedMinutes;
                const newPurchaseAndUsage = await DataUtils.insertPurchaseAndUsageDB({
                    newSubscriptionExpiration: subscriptionExpiration,
                    newMinutesLeft: newMinutesLeft,
                });
                // console.log("ListLectures: onEndCapturing:  newPurchaseAndUsage:",newPurchaseAndUsage);
                setMinutesLeft(newPurchaseAndUsage.newMinutesLeft);
            }

            setDocumentToInsert(newCapture);
        }
    }





    //
    //
    ////  TESTING API
    //
    //
    async function testing() {


    }







    if (window.location.hash.indexOf("Billing") > -1) {
        return (
            <>
                <Flex
                    direction="row"
                    justifyContent="flex-end"
                    margin="0 0 1rem 0"
                >
                    <Menu
                        alignSelf="right"
                        menuAlign="end"
                        trigger={
                            <MenuButton variation="primary" size="small">
                                Account {user.attributes.email}
                            </MenuButton>
                        }
                    >
                    </Menu>
                </Flex>

                <View as="div"
                    position="fixed"
                    left="2%"
                    top="3rem"
                    width="93%"
                    backgroundColor="white"
                    borderRadius="3px"
                    border="3px solid var(--amplify-colors-black)"
                    boxShadow="8px 7px 5px 7px grey"
                    className="cls-zindex-10"
                >

                    <Flex
                        direction="row"
                        right="10px"
                        margin="1rem"
                    >
                        <BillingView
                            param_userAttributes={user.attributes}
                            param_userEmail={user.attributes.email}
                            param_minutesLeft={minutesLeft}
                            param_subscriptionExpiration={subscriptionExpiration}
                            param_FnOnPurchase={onPurchase}
                        />
                    </Flex>
                </View>
            </>
        );

    } else if (window.location.hash.indexOf("Admin") > -1) {
        return (
            <Admin

            />
        )
    } else {
        return (
            <>

                <Flex
                    direction="row"
                    justifyContent="flex-end"
                    margin="0 0 1rem 0"
                >
                    <Menu
                        alignSelf="right"
                        menuAlign="end"
                        trigger={
                            <MenuButton variation="primary" size="small">
                                Account {user.attributes.email}
                            </MenuButton>
                        }
                    >
                        <View as="div"
                            margin="0.2rem 0.5rem 0 0.6rem"
                            fontSize="0.9rem"
                            color="darkblue"
                        >
                            Capture minutes: {minutesLeft}
                        </View>
                        <View as="div"
                            margin="0.2rem 0.5rem 0 0.6rem"
                            fontSize="0.9rem"
                            color="darkblue"
                        >
                            Subscription until: {Utils.dateTimeStringToFormattedDate(subscriptionExpiration)}
                        </View>

                        {(process.env.NODE_ENV === "development") &&
                            <MenuItem>
                                <Link
                                    href={window.location.href + "#Billing"}
                                    color="black"
                                    isExternal={true}
                                >
                                    Billing
                                    <View as="span" marginLeft="4px">
                                        <BiLinkExternal />
                                    </View>
                                </Link>
                            </MenuItem>
                        }
                        <MenuItem>
                            Billing
                            <View as="span" marginLeft="4px">
                                <BiLinkExternal />
                            </View>
                        </MenuItem>

                        <MenuItem onClick={() => signOut()}>
                            Sign Out
                        </MenuItem>
                        {(process.env.NODE_ENV === "development") &&
                            <MenuItem onClick={() => testing()}>
                                Execute ListLectures::testing function
                            </MenuItem>
                        }
                        {(user.username === "2d0bdf6f-cbd7-48e5-8f80-5352f088f2de") &&
                            <MenuItem>
                                <Link
                                    href={window.location.href + "#Admin"}
                                    color="black"
                                    isExternal={true}
                                >
                                    Admin
                                    <View as="span" marginLeft="4px">
                                        <BiLinkExternal />
                                    </View>
                                </Link>
                            </MenuItem>
                        }
                    </Menu>
                </Flex>




                <View as="table"
                    margin="1rem 0 0 1rem"
                    fontSize="1.4rem"
                    fontWeight={`${!showingEnterDocumentFlag ? 'normal' : 'bold'}`}
                    className={`${!showingEnterDocumentFlag ? 'cls-background-greenyellow-dim' : 'cls-background-greenyellow'} cls-show-hide-switch cls-text-center cls-align-self-start`}
                    minWidth="15rem"
                    onClick={() => { setShowingEnterDocumentFlag(!showingEnterDocumentFlag) }}
                >
                    <tbody><tr><td>
                        <BsFileEarmarkFont />
                        {/* <BsRecordFill color="red" fontSize="2.5rem" /> */}
                        <View as="span" marginLeft="1rem">
                            Insert Text Document
                        </View>
                    </td></tr></tbody>
                </View>

                {(window.isForBusiness || window.isDevelopment) &&
                    !!showingEnterDocumentFlag &&
                    <View
                        as="div"
                        borderRadius="6px"
                        border="1px solid var(--amplify-colors-red)"
                        boxShadow="3px 3px 5px 6px var(--amplify-colors-orange-60)"
                        maxWidth="100%"
                        margin="2% 5% 5% 5%"
                        padding="1% 3%"
                        width="90%"
                    >
                        <EnterDocument
                            // param_availableClassesList={availableClassesList}
                            // param_FnOnStartCapturing={onStartCapturing}
                            param_FnOnEndCapturing={onEndCapturing}
                        />
                    </View>
                }





                <View as="table"
                    margin="1rem 0 0 1rem"
                    fontSize="1.4rem"
                    fontWeight={`${!showingCaptureLectureFlag ? 'normal' : 'bold'}`}
                    className={`${!showingCaptureLectureFlag ? 'cls-background-greenyellow-dim' : 'cls-background-greenyellow'} cls-show-hide-switch cls-text-center cls-align-self-start`}
                    minWidth="15rem"
                    onClick={() => { setShowingCaptureLectureFlag(!showingCaptureLectureFlag) }}
                >
                    <tbody><tr><td>
                        <GiPlainSquare />
                        {/* <BsRecordFill color="red" fontSize="2.5rem" /> */}
                        <View as="span" marginLeft="1rem">
                            Capture
                        </View>
                    </td></tr></tbody>
                </View>

                {!!showingCaptureLectureFlag &&
                    <View
                        as="div"
                        borderRadius="6px"
                        border="1px solid var(--amplify-colors-red)"
                        boxShadow="3px 3px 5px 6px var(--amplify-colors-orange-60)"
                        maxWidth="100%"
                        margin="2% 5% 5% 5%"
                        padding="1% 3%"
                        width="90%"
                    >
                        <CaptureLecture
                            param_availableClassesList={[]}
                            param_FnOnStartCapturing={onStartCapturing}
                            param_FnOnEndCapturing={onEndCapturing}
                        />
                    </View>
                }












                <View as="table"
                    margin="1rem 0 0 1rem"
                    fontSize="1.4rem"
                    fontWeight={`${!showingSearchFlag ? 'normal' : 'bold'}`}
                    className={`${!showingSearchFlag ? 'cls-background-greenyellow-dim' : 'cls-background-red-dim'} cls-show-hide-switch cls-text-center cls-align-self-start`} minWidth="15rem"
                    onClick={() => {
                        // Click will 'Close' Query Ai - show all
                        if (!!showingSearchFlag && !showingQueryAiFlag) {
                            setSelectionModeFlag(false);
                            setShowOnlySelectedDocumentsFlag(false);
                        }
                        // Click will 'Open' Query Ai - show filtered 'short' list
                        if (!selectionModeFlag) {
                            setSelectionModeFlag(true);
                            setShowOnlySelectedDocumentsFlag(false);
                        }
                        setShowingSearchFlag(!showingSearchFlag);
                    }}
                >
                    <tbody><tr>
                        {!showingSearchFlag &&
                            <td>
                                <BiSearchAlt fontSize="1.5rem" />
                                Search
                            </td>
                        }
                        {!!showingSearchFlag &&
                            <td>
                                <BiSearchAlt fontSize="1.5rem" />
                                Exit Search
                            </td>
                        }
                    </tr></tbody>
                </View>


                {!!showingSearchFlag &&
                    <SearchDocuments
                        param_selectedClassesAndDocuments={selectedClassesAndDocuments}
                        param_fnSelectClassesAndDocuments={(inputSelection) => { setSelectedClassesAndDocuments(inputSelection) }}
                        param_selectedCount={selectedCount}
                        param_fnSetSearchText={(_searchText) => setSearchText(_searchText) }
                    />
                }

                <SelectedDocumentsTags
                    param_selectionModeFlag={selectionModeFlag}
                    param_availableTags={availableTags}
                    param_selectedClassesAndDocuments={selectedClassesAndDocuments}
                    param_fnOnChangedSelectedClassesAndDocuments={(_selectedClassesAndDocuments) => {
                        console.log("Main.js: fnOnChangedSelections: (coming from SelectedDocumentsTags.js) changed selectedClassesAndDocuments:", _selectedClassesAndDocuments);
                        setSelectedClassesAndDocuments(_selectedClassesAndDocuments);
                    }}
                />

                {!!selectionModeFlag &&
                    <Flex
                        direction="row"
                        // marginLeft="auto"
                        marginTop="1rem"
                    // justifyContent="flex-start"
                    // alignItems="flex-end"
                    >

                        <Button
                            marginLeft="auto"
                            height="2rem"
                            variation={showOnlySelectedDocumentsFlag ? "default" : "primary"}
                            onClick={() => {
                                setShowOnlySelectedDocumentsFlag(false);
                            }}
                        >
                            {!showOnlySelectedDocumentsFlag ? "" : "Show"} All Documents
                        </Button>
                        <Button
                            height="2rem"
                            variation={showOnlySelectedDocumentsFlag ? "primary" : "default"}

                            onClick={() => {
                                setShowOnlySelectedDocumentsFlag(true);
                            }}
                        >
                            {showOnlySelectedDocumentsFlag ? "" : "Show Only"} Selected Documents
                            {/* <View
                          marginLeft="0.5rem"
                        >
                          <BsFillCheckSquareFill />
                        </View> */}

                        </Button>
                    </Flex>
                }



                {!showingQueryAiFlag &&
                    <View as="table"
                        margin="1rem 0 0 1rem"
                        fontSize="1.4rem"
                        fontWeight={`${!showingQueryAiFlag ? 'normal' : 'bold'}`}
                        className={`${!showingQueryAiFlag ? 'cls-background-greenyellow-dim' : 'cls-background-red-dim'} cls-show-hide-switch cls-text-center cls-align-self-start`}
                        minWidth="15rem"
                        onClick={() => {
                            // Click will 'Close' Query Ai - show all
                            if (!!selectionModeFlag && !showingSearchFlag) {
                                setSelectionModeFlag(false);
                                setShowOnlySelectedDocumentsFlag(false);
                            }
                            // Click will 'Open' Query Ai - show filtered 'short' list
                            if (!selectionModeFlag) {
                                setSelectionModeFlag(true);
                                setShowOnlySelectedDocumentsFlag(false);
                            }
                            setShowingQueryAiFlag(!showingQueryAiFlag);
                        }}
                    >
                        <tbody><tr>
                            {!showingQueryAiFlag &&
                                <td>
                                    <BiCaretRight />
                                    Ask Ai
                                </td>
                            }
                            {!!showingQueryAiFlag &&
                                <td>
                                    <BiCaretDown />
                                    Exit Ask Ai
                                </td>
                            }
                        </tr></tbody>
                    </View>
                }

                <View
                    className="cls-flex-horizontal"
                    maxHeight="60rem"
                >
                    {!!showingQueryAiFlag &&
                        <View
                            className="cls-flex-horizontal-child"
                        >
                            <QueryAi
                                // param_selectionModeFlag={selectionModeFlag}
                                param_selections={selectedClassesAndDocuments}
                                param_FnExitQueryAi={() => {
                                    // Click will 'Close' Query Ai - show all
                                    if (!!selectionModeFlag && !showingSearchFlag) {
                                        setSelectionModeFlag(false);
                                        setShowOnlySelectedDocumentsFlag(false);
                                    }
                                    setShowingQueryAiFlag(false);
                                }}
                            />
                        </View>
                    }


                    <View
                        className="cls-flex-horizontal-child cls-overflow-Y-scroll"
                        maxHeight="-webkit-fill-available"
                    >
                        {!!showLoadingSpinnerFlag &&
                            <View className="cls-text-center">
                                <img height="20px" src="load.gif" className="loading" />
                            </View>
                        }
                        <ListClasses
                            param_selectionModeFlag={selectionModeFlag}
                            param_selections={selectedClassesAndDocuments}
                            param_showOnlySelectedDocumentsFlag={showOnlySelectedDocumentsFlag}

                            // not sure what this is for ?!
                            // param_searchText={!!inputSearchTextRef.current?.value ? inputSearchTextRef.current.value : null}
                            param_searchText={searchText}

                            param_fnOnChangedSelections={(_selections) => {
                                console.log("Main.js: fnOnChangedSelections: (coming from ListClasses.js) changed selectedClassesAndDocuments:", _selections);
                                setSelectedClassesAndDocuments(_selections);

                                // const _selectedCount = calculateSelectedCount(window.varSortedClassesWithDocumentsInfo
                                // setSelectedCount();
                                let _selectedCount = { classes: 0, documents: 0 };
                                for (const [className, docIdsArray] of Object.entries(_selections)) {
                                    _selectedCount.classes++;
                                    if (docIdsArray.length > 0) {
                                        _selectedCount.documents += docIdsArray.length;
                                    } else {
                                        const _c = window.varSortedClassesWithDocumentsInfo.find((c) => c.className === className);
                                        _selectedCount.documents += _c.items.length;
                                    }
                                }
                                // console.log(_selectedCount);
                                setSelectedCount(_selectedCount);

                                if (!selectionModeFlag) {
                                    if (!!Object?.keys(_selections)?.length > 0) {
                                        setSelectionModeFlag(true);
                                        setShowOnlySelectedDocumentsFlag(true);
                                    }
                                }
                            }}

                            param_availableClassesList={availableClassesList}
                            param_DocumentToInsert={documentToInsert}
                            param_DocumentToUpdate={documentToUpdate}
                            param_DocumentToDelete={documentToDelete}
                        />
                    </View>
                </View>




            </>
        );
    }
}

export default withAuthenticator(Main);
