import React, { useState, useRef, useEffect } from 'react';


function AudioPlayer({ audioFile, onAudioLoad }) {
    const audioRef = useRef(null);
  
    useEffect(() => {
      if (audioFile) {
        const url = URL.createObjectURL(audioFile);
        audioRef.current.src = url;
        onAudioLoad(audioRef.current); // Provide the audio element for stream creation
        return () => URL.revokeObjectURL(url);
      }
    }, [audioFile]);
  
    return (
      <div>
        <audio ref={audioRef} controls />
      </div>
    );
  }

  export default AudioPlayer;
  